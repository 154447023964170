import axios from 'axios'
import store from '../../store'
import qs from 'qs'
import router from '../../router'
import { i18n } from '@/i18n-setup'

const httpClient = axios.create({
  baseURL: '/api',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  },
  paramsSerializer: params => {
    const sanitized = Object.fromEntries(Object.entries(params).filter(e => {
      if (typeof e[1] === 'string') {
        return e[1] !== '' && e[1].trim() !== ''
      } else {
        return true
      }
    }))
    return qs.stringify(sanitized, {
      arrayFormat: 'repeat',
      skipNulls: true
    })
  }
})
httpClient.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = i18n.locale
  return config
})
httpClient.interceptors.response.use(
  function (response) {
    store.commit('global/setErrorRequestVisible', { status: false })
    if (['post', 'put', 'delete'].includes(response.config.method) &&
      response.config.url.indexOf('constructor/') > -1) {
      store.commit('global/setHasUnpublishedData', true)
    }
    return response.data
  },
  function (error) {
    return new Promise((resolve, reject) => {
      if (error.response && error.response.status === 401 && error.config &&
        !error.config.__isRetryRequest && !error.config.__isInitialRequest &&
        router.currentRoute.meta.role !== 'none' &&
        error.config.url !== '/account/change-password' && error.response.data.type !== '/problems/credentials-expired') {
        store.dispatch('account/logout')
      } else if (error?.response?.data?.message && error.response.status === 500) {
        store.commit('global/setErrorRequestVisible', {
          status: true,
          type: error.response.status,
          msg: error.response.data.message
        })
      } else if (!error.response) {
        // eslint-disable-next-line no-console
        console.error(error.toString())
        store.commit('global/setErrorRequestVisible', { status: true })
      } else if (error.response && error.response.message) {
        store.commit('setBackError', { flag: true, msg: error.response.message })
      }
      reject(error)
    })
  })

export default httpClient
