<template>
  <div
    class="cbr-combobox-container"
    :class="[
      { 'cbr-title': title },
      { 'cbr-error': errorState },
      { 'input-menu-up' : !required && topMenu.top },
      { 'select-menu-up' : topMenu.top},
      size
    ]"
  >
    <div v-if="title" class="title-container">
      <CbrHint
        v-if="hint"
        :title="hintTitle"
        :description="hintDescription"
      >
        <CbrIcon class="hint-icon"> $information</CbrIcon>
      </CbrHint>
      <span>{{ title }}
        <span class="title-container__required" v-if="required">*</span>
      </span>
    </div>
    <div v-if="required" class="required">
      <CbrIcon size="5">$required</CbrIcon>
    </div>
    <CbrHint top no-icon :hide-hint="!isSelectionHintShown" :disabled="!select || multiple" :title="select ? getItemText(select) : null">
    <v-combobox
      v-model="valueModel"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :clearable="clearable"
      :multiple="multiple"
      :search-input.sync="search"
      :label="label"
      :placeholder="getPlaceholder"
      :disabled="disabled"
      :error-messages="errorMessages"
      :hide-details="hideDetails"
      :class="[
          { 'search': select ? '' : searchIcon },
          { 'cbr-combobox--select-only': selectOnly },
          id
        ]"
      :style="{ width: width ? width : 'auto' }"
      :menu-props="{
        contentClass,
        'max-width': menuMaxWidth || width,
        ...bottomMenu,
        ...topMenu
      }"
      :id="id"
      :loading="loading"
      :item-disabled="itemDisabled"
      @change="onChange($event)"
      @blur="$emit('blur', $event)"
      @update:search-input="$emit('update:search-input', $event)"
      @click:clear="clear"
      @click.native="adjustMenuProps"
      class="cbr-combobox"
      deform-input
      ref="cbrCombobox"
      :attach="attached && id ? `.cbr-combobox.${id}` : false"
    >
      <template v-if="slots.item" v-slot:item="data">
        <slot name="item" v-bind="data"/>
      </template>
      <template v-else-if="multiple" v-slot:item="data">
        <v-list-item
          class="multiple"
          :class="[`${id}`, { 'multiple-active': data.item.isSelected }]"
        >
          <CbrCheckBox
            :checked="data.item.isSelected || selectedItemFn(data.item)"
            :label="data.item.text"
            @change="handleCheckboxClick($event, data.item)"
          >
          </CbrCheckBox>
          <v-list-item-content class="ml-4" @click="handleListItemClick($event, data.item)">
            <v-list-item-title v-text="getItemText(data.item)"/>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else-if="prependItemIconFn" v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" class="list-item-prepend-icon">
          <v-list-item-icon>
            <CbrIcon>{{ prependItemIconFn(item) }}</CbrIcon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="getItemText(item)"/>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else v-slot:item="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs">
          <CbrHint
              custom-activator
              bottom
              no-icon
              :title="getItemText(item)"
          >
            <template #default="{ on }">
              <v-list-item-content v-on="on" :class="{'cbr-list-img-content': image}">
                <div v-if="item.image" class="cbr-list-img-content__img">
                  <img
                    :height="imgSize"
                    :width="imgSize"
                    :src="item.image"
                  />
                </div>
                <v-list-item-title v-text="getItemText(item)" :class="item.class"/>
              </v-list-item-content>
            </template>
          </CbrHint>
        </v-list-item>
      </template>
      <template v-if="multiple && !search && !noSelectAll" v-slot:prepend-item>
        <v-list-item>
          <CbrCheckBox
            :label="i18n.t('combobox_txt_select_all')"
            :checked="selectAllChecked"
            @change="selectAll"
            all
          ></CbrCheckBox>
        </v-list-item>
      </template>
      <template v-else-if="image" v-slot:selection="{ item }">
        <CbrHint
          custom-activator
          bottom
          no-icon
          :title="getItemText(item)"
        >
          <div class="cbr-combobox-img-selection">
            <img class="cbr-combobox-img-selection__img"
              :src="item.image"
              :height="imgSize"
              :width="imgSize"
            />
            <div class="cbr-combobox-img-selection__name">{{ item.name }}</div>
          </div>
        </CbrHint>
      </template>
      <template v-else-if="multiple && noChips" v-slot:selection/>
      <template v-else-if="multiple && !noChips && !allChips" v-slot:selection>
        <v-chip
          v-if="massSelect.length === 1"
          class="v-chip-styled v-chip-collapsed v-chip-cut-corners"
        >{{ valueModel[0][itemText] }}
        </v-chip>
        <v-chip v-else
          class="v-chip-styled v-chip-collapsed v-chip-cut-corners"
        >
          <CbrHint bottom noIcon>
            <span class="fill-width">
              {{ valueModel.length }} {{ localizedItem }}
            </span>
            <template v-slot:description>
              <div class="tooltip-container">
                <div class="tooltip-container__title">Выбрано:</div>
                <v-list-item
                    class="tooltip-container__item"
                    v-for="(item, index) in valueModel"
                    :key="index"
                    style="color:#FFFFFF !important;"
                >
                  {{ getItemText(item) }}
                </v-list-item>
              </div>
            </template>
          </CbrHint>
        </v-chip>
      </template>
      <template v-else-if="multiple && !noChips && allChips" v-slot:selection="{ attrs, item, parent, selected }">
        <div v-if="item === Object(item)" class="v-chip-cut-corners pa-0">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            class="v-chip-styled d-block px-2"
            :color="`${item.color} lighten-3`"
            :class="chipStyleFn(item)"
            :input-value="selected"
            label
            @click="$emit('clickOnChip', [$event, item, parent])"
          >
            <span class="pr-2" style="cursor: pointer">
              {{ item.name }}
            </span>
            <CbrIcon class="multiple-chip-icon"
                    @click="$emit('removeChip', [$event, item, parent])"
            >close_modal
            </CbrIcon>
          </v-chip>
        </div>
      </template>
      <template v-else-if="slots.selection" v-slot:selection="valueModel">
        <slot name="selection" v-bind="valueModel"/>
      </template>
      <template v-else-if="prependItemIconFn" v-slot:selection="{ item, on, attrs }">
        <v-list-item v-on="on" v-bind="attrs" class="list-item-prepend-icon">
          <v-list-item-icon>
            <CbrIcon class="mr-1">{{ prependItemIconFn(item) }}</CbrIcon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="getItemText(item)"/>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-if="noDataText">{{ noDataText }}</v-list-item-title>
            <v-list-item-title v-else>
              {{ i18n.t('combobox_txt_no_data') }} "{{ search }}"
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="!select && searchIcon" v-slot:prepend>
        <CbrIcon>mdi-magnify</CbrIcon>
      </template>
      <template v-slot:append>
        <div class="menu-icon-container">
          <CbrIcon v-if="reset && !disabled" class="reset-icon" @click="resetValue">
            $inputReset
          </CbrIcon>
          <CbrButton
            v-if="appendAddButton"
            class="add-icon"
            icon="$cbrPlus"
            icon-size="14px"
            size="small"
            border
            @click="addValue"
          />
          <CbrHint
            v-if="errorMessages?.length && hideDetails"
            :description="errorMessages.join(', ')"
            no-title
            error
            bottom
            content-class="move-left"
          >
            <CbrIcon class="error-icon mr-2"> mdi-alert-circle</CbrIcon>
          </CbrHint>
          <CbrHint
            v-if="error && errorHint"
            :title="errorHint.title"
            :sub-title="errorHint.subTitle"
            :description="errorHint.description"
            error
            bottom
            content-class="move-left"
          >
            <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
          </CbrHint>
          <CbrHint
            v-if="select && (itemInfoHintTitleValue || select[itemInfoHintTitleProp]) && select[itemInfoHintDescriptionProp]"
            :title="itemInfoHintTitleValue || select[itemInfoHintTitleProp]"
            :subTitle="select[itemInfoHintSubTitleProp]"
            :description="select[itemInfoHintDescriptionProp]"
            bottom
            content-class="move-left"
          >
            <CbrIcon class="item-info-hint-icon mr-1"> $information</CbrIcon>
          </CbrHint>
          <CbrIcon v-if="!hideMenuOpenIcon" class="menu-icon" small>$menuOpen</CbrIcon>
        </div>
      </template>
      <template v-slot:append-item>
        <div style="height: 8px" v-intersect="infiniteScroll"></div>
      </template>
    </v-combobox>
    </CbrHint>
  </div>
</template>

<script setup>
import { ref, watch, computed, nextTick, useSlots } from 'vue'
import { i18n } from '../../i18n-setup'

const props = defineProps({
  image: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    required: true
  },
  selectOnly: {
    type: Boolean,
    default: false,
  },
  listItemsOnly: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false
  },
  appendAddButton: {
    type: Boolean,
    default: false
  },
  hideMenuOpenIcon: {
    type: Boolean,
    default: false
  },

  // Props for multiple
  multiple: {
    type: Boolean,
    default: false
  },
  // true - для отсутствия отображения выбранных сущностей
  noChips: {
    type: Boolean,
    default: false,
  },
  // true - для отображения всех выбранных сущностей, false - для одного чипа с кол-вом выбранных сущностей
  allChips: {
    type: Boolean,
    default: false,
  },
  // true - чтобы убрать чекбокс "Выбрать все"
  noSelectAll: {
    type: Boolean,
    default: false
  },

  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  },
  searchIcon: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: 'medium'
  },
  title: {
    type: String,
    default: ''
  },
  itemText: {
    type: [String, Function],
    default: 'name'
  },
  itemValue: {
    type: String,
    default: 'value'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  itemsCount: {
    type: Number,
    default: undefined
  },
  itemDisabled: {
    type: Function,
    default: item => false
  },
  chipStyleFn: {
    type: Function,
    default: (item) => ''
  },
  selectedItemFn: {
    type: Function,
    default: (item) => false
  },
  prependItemIconFn: {
    type: Function,
  },
  attached: Boolean,
  width: String,
  menuMaxWidth: String,
  preselect: [Object, String, Array],
  reset: Boolean,
  errorMessages: Array,
  hideDetails: Boolean,
  idError: String,
  id: String,
  hint: Boolean,
  hintTitle: String,
  hintDescription: String,
  itemInfoHintTitleValue: String,
  itemInfoHintTitleProp: String,
  itemInfoHintSubTitleProp: String,
  itemInfoHintDescriptionProp: String,
  noDataText: String,
  loading: Boolean,
  error: {
    type: Boolean,
    default: false
  },
  errorHint: {
    type: Object,
    default: null
  },
  innerExpansionPanel: Boolean
})
const emit = defineEmits(['update', 'updateMultiple', 'change', 'input', 'clear', 'add', 'nextPage'])

const select = ref(props.preselect)
const search = ref('')
const massSelect = ref(props.preselect || [])
const slots = useSlots()

watch(select, (newValue) => {
  emit('update', newValue)
}, { deep: true, immediate: true })

watch(() => props.preselect, newPreselect => {
  if (props.multiple) {
    massSelect.value = newPreselect
  } else {
    select.value = newPreselect
  }
}, { deep: true })

const onChange = (e) => {
  emit('change', e)
}

const clear = () => {
  if (props.multiple) {
    massSelect.value.forEach(item => item.isSelected = false)
    emit('updateMultiple', [])
  }
  emit('clear')
}
const cbrCombobox = ref({})

const valueModel = computed({
  get: () => {
    return props.multiple ? massSelect.value : select.value
  },
  set: (value) => {
    if (props.multiple) {
      massSelect.value = props.listItemsOnly ? value.filter(item => item instanceof Object) : value
      emit('updateMultiple', massSelect.value)
    } else {
      select.value = props.items.includes(value) ? value : null
      emit('input', select.value)
    }
  }
})

const imgSize = computed(() => {
  if (props.size === 'small') return '16'
  if (props.size === 'large') return '20'
  return '18'
})

const resetValue = () => {
  valueModel.value = props.preselect
  if (props.multiple) {
    updateCheckboxValues()
  }
}

const updateCheckboxValues = () => {
  nextTick(() => {
    const items = Array.from(document.getElementsByClassName(`multiple v-list-item ${props.id}`))
    if (!items.length) return
    const checkedIndexes = []
    props.items.forEach(({ value }, index) => {
      if (valueModel.value.map(item => item.value).includes(value)) {
        checkedIndexes.push(index)
      }
    })
    items.forEach((div, index) => {
      div.querySelector('input[type="checkbox"]').checked = checkedIndexes.includes(index)
    })
  })
}

const addValue = () => {
  emit('add', search.value)
}

const errorState = computed(() => props.error || props.errorHint ||
  (props.errorMessages && props.errorMessages.length > 0))

const contentClass = computed(() => {
  let classes = `menu-${props.size}`
  if (errorState.value) classes += ' menu-error'
  return classes
})

// multiple
const selectAllChecked = ref(massSelect.value && massSelect.value.length === props.items.length)
const selectAll = (e) => {
  if (e.target.checked) {
    selectAllChecked.value = true
    massSelect.value = [...props.items]
    props.items.forEach(item => item.isSelected = true)
  } else {
    selectAllChecked.value = false
    massSelect.value = []
    props.items.forEach(item => item.isSelected = false)
  }
  emit('update', select.value)
  emit('updateMultiple', massSelect.value)
}
const handleListItemClick = (e, item) => {
  const cbx = e.target.closest('.v-list-item').getElementsByTagName('input')[0]
  cbx.checked = !cbx.checked
  handleCheckboxChange(cbx.checked, item)
}
const handleCheckboxClick = (e, item) => {
  handleCheckboxChange(e.target.checked, item)
}
const handleCheckboxChange = (checked, item) => {
  if (checked) {
    massSelect.value.push(item)
    item.isSelected = true
  } else {
    // TODO отрефакторить
    // Потенциально баг, когда у 2 и более эдементом selectedItem[props.itemText] вычислятся в одинаковую стрингу
    // Фиксится добавлением уникального id элементу или привязкой к индексу
    massSelect.value = massSelect.value.filter(selectedItem => selectedItem[props.itemText] !== item[props.itemText])
    item.isSelected = false
  }
  if (massSelect.value.length === props.items.length) {
    selectAllChecked.value = true
  } else if (!massSelect.value.length) {
    selectAllChecked.value = false
  }
  emit('change', item)
  emit('updateMultiple', massSelect.value)
}

const localizedItem = computed(() => {
  if (!massSelect.value) {
    return ''
  }
  if (i18n.locale === 'en') {
    return i18n.t('combobox_txt_multiple_5_and_more_items')
  } else {
    return massSelect.value.length >= 2 && massSelect.value.length <= 4 ? i18n.t('combobox_txt_multiple_2_4_items') : i18n.t('combobox_txt_multiple_5_and_more_items')
  }
})

const infiniteScroll = ([{ isIntersecting, target }]) => {
  if (isIntersecting && props.itemsCount && props.itemsCount >= props.items.length) {
    emit('nextPage', select.value ? '' : search.value)
  }
}

const isSelectionHintShown = ref(false)
watch(() => cbrCombobox.value?.isMenuActive, (isMenuActive) => {
  isSelectionHintShown.value = !isMenuActive
})

watch(() => props.selectOnly, (value) => {
  if (value) {
    nextTick(() => {
      const inputElement = cbrCombobox.value.$el.querySelector('input')
      inputElement.setAttribute('readonly', true)
    })
  }
}, {
  immediate: true
})

// Сценарий использования - отмена выбора организации. Без этого локальное значение не равно preselect
function forceValueUpdate () {
  if (props.multiple) {
    massSelect.value = props.preselect
  } else {
    select.value = props.preselect
  }
}

function getItemText (item) {
  if (typeof props.itemText === 'function') {
    return props.itemText(item)
  }
  if (typeof item === 'string') {
    return item
  }
  return item[props.itemText]
}

let bottomMenu = ref(null)
let topMenu = ref(false)

const adjustMenuProps = () => {
  nextTick(() => {
    let comboboxRect = cbrCombobox.value.$el.getBoundingClientRect()
    let spaceBelow, heightMenu

    let menu = cbrCombobox.value.$el.children[1]
    if (!menu) return
    let styleMenu = window.getComputedStyle(menu)
    heightMenu = parseInt(styleMenu.getPropertyValue('max-height'), 10)

    if (props.innerExpansionPanel) {
      let parentRect = cbrCombobox.value.$el.closest('.cbr-expansion-panel-base__content').getBoundingClientRect()
      spaceBelow = parentRect.bottom - comboboxRect.bottom
    } else {
      let viewportHeight = window.innerHeight
      spaceBelow = viewportHeight - comboboxRect.bottom
      let spaceAbove = comboboxRect.top

      if (spaceBelow < heightMenu && spaceAbove > spaceBelow) {
        bottomMenu.value = { bottom: false }
        topMenu.value = { top: true }
        return
      }
    }

    if (spaceBelow < heightMenu) {
      menu.style.maxHeight = `${spaceBelow}px`
    } else {
      bottomMenu.value = { bottom: true }
      topMenu.value = { top: false }
    }
  })
}

const getPlaceholder = computed(() => {
  return valueModel.value ? null : props.placeholder
})

defineExpose({
  forceValueUpdate
})
</script>

<style lang="scss" scoped>
$checked-item: linear-gradient(to right, rgba($base-color, 0.15), rgba($base-color, 0));
$backgroundColor: rgba($base-color-dark, 0.35);

.cbr-combobox-container {
  display: flex;
  flex-direction: column;
  position: relative;
  color: $base-color;
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  font-size: 18px;
  line-height: 1em;

  &__required {
    color: $base-color-error-text;
  }
}

::v-deep .v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input {
  align-self: center;
}

.cbr-combobox {
  margin-top: 0;

  ::v-deep .v-select__slot {
    display: flex !important;
    align-items: center;

    input {
      padding: 0 0 0 12px;
      color: $base-color-light;
      caret-color: $base-color-light;

      &::placeholder {
        font-family: $primary-font-family;
        color: rgba($base-color-light, 0.3);
      }
    }
  }

  ::v-deep .v-select__selection {
    color: $base-color-text !important;
  }

  ::v-deep .v-input__slot::before {
    display: none;
  }

  ::v-deep .v-input__slot::after {
    display: none;
  }

  ::v-deep .mdi-close::before {
    font-size: 1em;
    color: $base-color-muted;
  }

  ::v-deep .item-info-hint-icon {
    font-size: inherit;
    height: 1.1em;
    color: $base-color-muted;
    cursor: default;
  }

  ::v-deep .v-text-field__details {
    margin-top: 5px;
    padding-left: 13px;

    .error--text {
      color: $AlarmTextColor !important;
    }
  }
}

::v-deep .v-text-field .v-input__append-inner {
  margin: 0;
  padding: 0;
  align-self: center;

  .v-icon {
    margin-right: 0.5em;
  }
}

.search {
  ::v-deep .v-input__prepend-outer {
    position: absolute;
    left: 10px;
    transform: translateY(20%);

    i {
      color: $base-color;
    }
  }

  ::v-deep .v-input__control .v-select__slot input {
    padding-left: 30px;
  }
}

.menu-icon-container {
  display: flex;
  align-items: center;

  .reset-icon {
    height: 0.7em;
    cursor: pointer;

    path {
      fill: rgba($base-color-warning, 0.6) !important;
    }
  }

  .error-icon {
    font-size: 1.1em;
    color: $base-color-error-text;
    cursor: default;
  }

  .menu-icon {
    cursor: default;

    ::v-deep path {
      fill: $base-color;
    }
  }
}

.required {
  position: absolute;
  top: -11px;
}

.multiple-active {
  background: $checked-item;
}

.v-chip-collapsed:first-child {
  display: flex;
  max-width: calc(100% - 28px);
  ::v-deep .v-chip__content {
    display: block;
    @include text-ellipsis;
  }
}

.v-chip-cut-corners {
  @include cut-corners(5px, true, 5px, false, 2px, rgba($base-color, 0.8));
  height: 26px;
}
.v-chip-styled {
  color: $base-color-dark;
  background: $base-color !important;
  border-radius: 0px !important;
  font-size: 16px !important;
}

.v-chip-collapsed {
  display: none;
}

.v-text-field {
  padding-top: 0px;
}

// tooltip
.v-tooltip__content {
  color: $base-color;
  background: $base-color-dark;
  opacity: 1 !important;
  width: 260px;
  border: 1px solid $base-color-disabled;
  padding: 13px 15px;
}

.tooltip-container__title {
  color: $base-color;
  font-size: 16px;
  font-weight: 400;
}

.tooltip-container__item {
  color: $base-color-light !important;
  background: $checked-item;
  margin-top: 3px;
  border-left: 2px solid $base-color;
  min-height: 24px !important;
}

.hint-icon {
  margin-right: 0.2em;
  min-width: fit-content;

  ::v-deep path {
    fill: $base-color;
    opacity: 0.5;
  }

  &:hover ::v-deep path {
    opacity: 1;
  }
}

.small {
  .v-chip-styled {
    height: 18px;
    border: none;
    padding: 0 2px;
  }

  ::v-deep .v-input__control {
    .v-select__slot {
      height: 24px;
    }
  }

  .hint-icon {
    font-size: 15px;
    height: 15px !important;
  }

  .v-chip-styled {
    font-size: 14px !important;
  }

  .title-container ~ .required {
    top: 8px !important;
  }

  .title-container {
    font-size: 16px;
  }
}

.medium {
  ::v-deep .v-input__control {
    .v-select__slot {
      min-height: 36px;
      font-size: 18px;
    }
  }

  .hint-icon {
    font-size: 18px;
    height: 18px !important;
  }
}

.large {
  ::v-deep .v-input__control {
    .v-select__slot {
      min-height: 48px;
      font-size: 20px;
    }
  }

  .title-container ~ .required {
    top: 17px !important;
  }

  .title-container {
    font-size: 24px;
  }

  .hint-icon {
    font-size: 20px;
    height: 20px !important;
  }
}

.cbr-title {
  .title-container ~ .required {
    top: 12px;
  }
}

.cbr-error {
  color: $base-color-error-text !important;

  ::v-deep .v-input__control {
    .v-select__slot {
      @include cut-corners(8px, true, 8px, false, 2px, $base-color-error-text);
      padding: 0 4px 0 0;
    }
  }

  ::v-deep svg path {
    fill: $base-color-error-text;
  }
}

::v-deep .v-input__control {
  .v-select__slot {
    background-color: $backgroundColor;
    @include cut-corners(8px, true, 8px, false, 2px, $border-muted);
    padding: 0 4px 0 0;
  }
}

::v-deep .v-input--is-disabled .v-input__control {
  .v-select__slot {
    @include cut-corners(8px, true, 8px, false, 2px, $border-disabled);
    padding: 0 4px 0 0;
  }
}

::v-deep .v-select--is-menu-active {
  .v-select__slot {
    background-color: $backgroundColor;
    @include cut-corners(8px, true, 0px, false, 2px, $base-color);
    padding: 0 4px 0 0;
  }
}

.list-item-prepend-icon ::v-deep .v-list-item__icon {
  align-items: center;
  align-self: center;
  margin: 0;
}

::v-deep .v-input__slot {
  margin-bottom: 0;

  .v-select__slot {
    input {
      color: $base-color-text;
    }
  }
}

.v-list-item__title {
  color: $base-color-text;
}

::v-deep .v-select--is-multi {
  .v-select__slot {
    padding: 2px 4px 2px 2px;
  }
}

.multiple-chip-icon {
  width: 21px;
  height: 21px;
  justify-content: start;
}

.add-icon {
  margin-right: 8px;

  ::v-deep .v-icon {
    margin-right: 0 !important;
  }
}

.cbr-combobox-img-selection {
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: $base-color-text;
  &__img {
    margin-right: 6px;
  }
  &__name {
    @include text-ellipsis;
    max-width: 140px;
  }
}

.cbr-list-img-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  &__img {
    display: flex;
    align-items: center;
    max-width:20px;
    margin-right: 5px;
    & + .v-list-item__title {
      max-width: calc(100% - 25px);
    }
  }
}
.input-menu-up ::v-deep .v-select--is-menu-active {
  .v-select__slot {
    @include cut-corners(0px, true, 0px, false, 2px, $base-color);
  }
}
.select-menu-up ::v-deep .v-autocomplete__content {
  margin-top: 2px;
}
.select-menu-up ::v-deep .v-menu__content {
  @include cut-corners(7px, true, 0px, false, 2px, $base-color);
  position: absolute !important;
  display: block;
}
</style>
