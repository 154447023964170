import moment from 'moment'
import httpClient from './http'
import { i18n } from '@/i18n-setup'
import _ from 'lodash'

const END_POINT = '/event'

export const CTF = 'ctf'
export const RESISTANCE = 'resistance'
export const DEFENCE = 'defence'
export const stateTimestampFormat = 'YYYY-MM-DDTHH:mm:ss.000\\Z'
export const statePrefix = 'Event_'
const debounceBusEventFunction = _.debounce((callback, msg) => callback(msg), Math.round(2000 * Math.random() + 200))
export default {
  debounceBusCallback (callback, msg) {
    debounceBusEventFunction(callback, msg)
  },
  getEvents (options) {
    const { page = 0, size = 10, template, name, startDate, endDate, sort, order } = options
    return httpClient.get(`${END_POINT}`, {
      params: {
        page,
        size,
        template,
        name,
        startDate,
        endDate,
        sort,
        order
      }
    })
  },
  reloadVM (idEvent, vmName, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType.toLowerCase()}/${idEvent}/reboot${vmName ? `/${vmName}` : ''}`)
  },
  rebootVm (vmName) {
    return httpClient.get(`${END_POINT}/rebootVm/${vmName}`)
  },
  getActiveEvents () {
    return httpClient.get('/scoreBoard/events')
  },
  createEvent (event, gameType = DEFENCE) {
    return httpClient.post(`${END_POINT}/${gameType}`, event)
  },
  getUsersForEvents (eventIds) {
    const params = { eventIds }
    return httpClient.get(`${END_POINT}/users`, { params })
  },
  updateEvent (id, event, gameType = DEFENCE) {
    return httpClient.put(END_POINT + `/${gameType}/` + id, event)
  },
  startInfrastructure (id, gameType = DEFENCE) {
    if (gameType === DEFENCE) {
      return httpClient.get(`${END_POINT}/${gameType}/${id}/startInfrastructure`)
    } else {
      return httpClient.post(`${END_POINT}/${gameType}/${id}/prepare`)
    }
  },
  getEventArbitration (eventId, gameType) {
    return httpClient.get(`${END_POINT}/${gameType}/arbitration/${eventId}`)
  },
  saveEventArbitration (eventId, gameType, data) {
    return httpClient.put(`${END_POINT}/${gameType}/override/${eventId}`, data)
  },
  getEventServices (eventId) {
    return httpClient.get(`${END_POINT}/resistance/${eventId}/services`)
  },
  launchChecker (eventId) {
    return httpClient.get(`${END_POINT}/resistance/${eventId}/services/check`)
  },
  getUserUnfinishedEventsCount (userId) {
    return httpClient.get(`/tools/userUnfinishedEventsCount/${userId}`)
  },
  getOrganizationUnfinishedEvents (orgId) {
    return httpClient.get(`/tools/organizationUnfinishedEvents/${orgId}`)
  },
  getOrganisationUsersWithUnfinishedEvents (orgId) {
    return httpClient.get(`/tools/organizationUserUnfinishedEvents/${orgId}`)
  },
  getConsoleToken (id, userId, isGuacamole, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/console/${gameType}/${id}/${userId}${isGuacamole ? '?usingGuacamole=true' : ''}`)
  },
  getScoring (id) {
    return httpClient.get(`${END_POINT}/${id}/score/current`)
  },
  getOnlineUsers (id) {
    return httpClient.get(`${END_POINT}/${id}/online`)
  },
  getLogHistory (id) {
    return httpClient.get(`${END_POINT}/${id}/log/history`)
  },
  getNetworkConfig (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/network/map`)
  },
  getGameState (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/network/state`)
  },
  getReport (id) {
    const uri = `/api${END_POINT}/${id}/analyticalReport`
    window.open(uri, '_blank')
  },
  getEventMaterials (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/media/list?isEvent=true`)
  },
  getAttributes (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/attributes`)
  },
  getCurrentGoal (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/phaseDescription`)
  },
  getHint (id, vectorId, phaseId, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/hint/${vectorId}/${phaseId}`)
  },
  getUserToVmMapping (id, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${id}/getUserVMsMapping`)
  },
  getEventMaterialsZipAsBlob (id, gameType = DEFENCE) {
    const lang = i18n.locale
    return httpClient.get(`${END_POINT}/${gameType}/${id}/media?locale=${lang}`, {
      responseType: 'blob'
    })
  },
  hasNetworkMap (id, gameType = DEFENCE) {
    return httpClient.head(`${END_POINT}/${gameType}/${id}/network/image?locale=${i18n.locale}`)
  },
  hasNetworkMap3D (id, gameType = DEFENCE) {
    return httpClient.head(`${END_POINT}/${gameType}/${id}/network/3dmap`)
  },
  getNetworkMapAsBlob (id, gameType = DEFENCE) {
    const lang = i18n.locale
    return httpClient.get(`${END_POINT}/${gameType}/${id}/network/image?locale=${lang}`, {
      responseType: 'blob'
    })
  },
  getEventMaterialsFileAsBlob (id, name, gameType = DEFENCE) {
    const lang = i18n.locale
    return httpClient.get(`${END_POINT}/${gameType}/${id}/media/file/${name}?locale=${lang}`, {
      responseType: 'blob'
    })
  },
  getEventMaterialsFile (id, name, gameType = DEFENCE) {
    const lang = i18n.locale
    const uri = `/api${END_POINT}/${gameType}/${id}/media/file/${name}?locale=${lang}`
    window.open(uri, '_blank')
  },
  getEventNoiseAndAttackBots (eventId, gameType = DEFENCE) {
    return httpClient.get(`${END_POINT}/${gameType}/${eventId}/noiseBotAndAttacks`)
  },
  startNoiseBot (eventId, handlerId) {
    return httpClient.get(`${END_POINT}/${eventId}/startNoiseBot/${handlerId}`)
  },
  parseMaterials (materials) {
    return materials.map(material => {
      return {
        name: material.filename,
        size: material.readableFileSize
      }
    })
  },
  getEvent (id, gameType) {
    return httpClient.get(END_POINT + `/${gameType || DEFENCE}/` + id)
  },
  async manualStartEvent (id, gameType = DEFENCE) {
    if (gameType === DEFENCE) {
      return httpClient.post(`${END_POINT}/${gameType}/${id}/startEvent`)
    }
    return httpClient.post(`${END_POINT}/${gameType}/${id}/start`)
  },
  manualPauseEvent (id, gameType = DEFENCE) {
    if (gameType === DEFENCE) {
      return httpClient.post(`${END_POINT}/${gameType}/${id}/pauseEvent`)
    }
    return httpClient.post(`${END_POINT}/${gameType}/${id}/pause`)
  },
  manualStopEvent (id, gameType = DEFENCE) {
    if (gameType === DEFENCE) {
      return httpClient.post(`${END_POINT}/${gameType}/${id}/stopEvent`)
    } else {
      return httpClient.post(`${END_POINT}/${gameType}/${id}/cancel`)
    }
  },
  getVmsList (infra) {
    return httpClient.get(`${END_POINT}/userVms/${infra}`)
  },
  getState (timestamp = moment(new Date()).format(stateTimestampFormat), filter) {
    const filterParameter = `?filter=${filter || ''}`
    const timestampParameter = `&since=${encodeURIComponent(timestamp)}`
    return httpClient.get(`/state${filterParameter}${timestampParameter}`)
  },
  busVm (sse, { gameType, eventId }) {
    return sse.create({
      url: `api/message/bus/vm/${gameType}/${eventId}`
    })
  },
  getStandConfig (eventId, gameType = DEFENCE) {
    return httpClient.get(`/mockup/${gameType}/${eventId}/triggers-filename`)
  },
  uploadStandConfig (eventId, gameType = DEFENCE, form, config) {
    return httpClient.put(`/mockup/${gameType}/${eventId}/triggers`, form, config)
  },
  userUnfinishedEvents (id) {
    return httpClient.get('/tools/userUnfinishedEvents/' + id)
  },
  getResultsForScoreboard ({ gameType, eventId }) {
    return httpClient.get(`${END_POINT}/${gameType}/results/${eventId}`)
  }
}
