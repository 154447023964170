export const taskRu = {
  task_card: {
    txt: {
      no_linked_tasks: 'Связанные задачи отсутствуют', // newIdLoc
      no_missions: 'Миссии отсутствуют', // newIdLoc
      no_comments: 'Комментарии отсутствуют', // newIdLoc
      add_file: 'Добавьте файл' // newIdLoc
    },
    lbl: {
      bread_crumbs_root: 'Задачи',
      number_task: 'Задача',
      assigned: 'Поручил',
      updated: 'Изменил',
      updated_month_jan: 'янв.',
      updated_month_feb: 'фев.',
      updated_month_mar: 'мар.',
      updated_month_apr: 'апр.',
      updated_month_may: 'май',
      updated_month_june: 'июнь',
      updated_month_july: 'июль',
      updated_month_aug: 'авг.',
      updated_month_sept: 'сент.',
      updated_month_oct: 'окт.',
      updated_month_nov: 'нояб.',
      updated_month_dec: 'дек.',
      updated_time: {
        min: 'мин. назад',
        hour: 'ч. назад',
        yesterday: 'вчера',
      },
      assignee_not_found: 'Адресаты не найдены',
      tags_not_found: 'Теги не найдены',
      drag_file: 'или перетащите их сюда',
      history_events_month_jan: 'янв.',
      history_events_month_feb: 'фев.',
      history_events_month_mar: 'мар.',
      history_events_month_apr: 'апр.',
      history_events_month_may: 'май',
      history_events_month_june: 'июн.',
      history_events_month_july: 'июл.',
      history_events_month_aug: 'авг.',
      history_events_month_sept: 'сент.',
      history_events_month_oct: 'окт.',
      history_events_month_nov: 'нояб.',
      history_events_month_dec: 'дек.',
      history: {
        changed_status_from: 'Изменил состояние задачи с',
        changed_status_to: 'на',
        assigned: 'Назначил ',
        assigned_assignee: 'в качестве Исполнителя',
        changed_name_from: 'Изменил название задачи с',
        changed_name_to: 'на',
        changed_descr: 'Изменил описание задачи',
        added_mission: 'Добавил миссию',
        deleted_mission: 'Удалил миссию',
        added_infr: 'Добавил инфраструктуру',
        deleted_infr: 'Удалил инфраструктуру',
        added_file: 'Добавил файл',
        deleted_file: 'Удалил файл',
        changed_tags_from: 'Изменил набор тегов с',
        changed_tags_to: 'на',
        added_link: 'Добавил связь с задачей',
        deleted_link: 'Удалил связь с задачей',
      },
      write_comment: 'написал комментарий',
      comments_month: {
        jan: 'янв.',
        feb: 'фев.',
        mar: 'мар.',
        apr: 'апр.',
        may: 'май',
        june: 'июнь',
        july: 'июль',
        aug: 'авг.',
        sept: 'сент.',
        oct: 'окт.',
        nov: 'нояб.',
        dec: 'дек.',
      },
      comments_time: {
        min: 'мин. назад',
        hour: 'ч. назад ',
        yesterday: 'вчера',
      },
      mission: 'Миссия'
    },

    lst: {
      status: 'Статус:',
      status_in_progress: 'В работе',
      status_canceled: 'Отменена',
      status_completed: 'Выполнена',
      status_not_assigned_plh: 'Не назначена',
      status_assigned_plh: 'Назначена',
      priority: 'Важность:',
      priority_high: 'Высокая',
      priority_medium: 'Средняя ',
      priority_low: 'Низкая ',
      assignee: 'Исполнитель:',
      assignee_search_plh: 'Поиск',
      add_mission: 'Добавить миссию',
      add_mission_plh: 'Поиск…',
      add_infrastructure: 'Добавить инфраструктуру',
      add_infrastructure_plh: 'Поиск…',
      linked_tasks_plh: 'Поиск',
    },

    btn: {
      send: 'Отправить',
      install: 'Установить',
      installed: 'Установлена',
      select_file: 'Выберите файл',
      publish: 'Опубликовать',
      link_task: 'Привязять задачу' // newIdLoc
    },

    edit: {
      task_name: 'Задача',
      task_name_plh: 'Введите название задачи',
      description: 'Описание задачи',
      description_plh: 'Подробно опишите задачу, укажите цели, средства и сроки исполнения...',
      add_comment_plh: 'Напишите комментарий или отчет о выполнении задачи...',
      tags: 'Введите тэг', // newIdLoc
    },

    hdr: {
      files: 'Файлы',
      missions_and_infrastructures: 'Миссии',
      linked_tasks: 'Связанные задачи',
      history_events: 'История изменений',
      comments: 'Комментарии',
      add_comment: 'Написать комментарий',
      tags: 'Тэги', // newIdLoc
      management: 'Управление задачей'
    },
    delete_dialog: {
      title: 'Удаление задачи',
      question: 'Вы действительно желаете удалить задачу?',
      cancel_button: 'Отменить',
      submit_button: 'Удалить'
    },
    files_delete_dialog: {
      title: 'Удаление файла',
      question: 'Вы действительно желаете удалить файл из перечня файлов?',
      cancel_button: 'Отменить',
      submit_button: 'Удалить'
    },
    mission_delete_dialog: {
      title: 'Удаление миссии',
      question: 'Вы действительно желаете удалить миссию?',
      cancel_button: 'Нет',
      submit_button: 'Да'
    },
    popup_overwriting: {
      hdr: {
        header: 'Перезапись миссии'
      },
      txt: {
        info: 'Данная миссия уже установлена!\nЖелаете перезаписать?'
      },
      btn: {
        cancel: 'Отменить', // newIdLoc
        install: 'Установить' // newIdLoc
      }
    },
    popup_set_mission: {
      hdr: {
        header: 'Установка миссии'
      },
      txt: {
        info: 'Вы желаете установить миссию?'
      },
      btn: {
        no: 'Нет',
        yes: 'Да'
      }
    },
    popup_file_name_error: {
      hdr: {
        header: 'Загрузка файла'
      },
      txt: {
        info: 'Файл с таким именем уже прикреплен к данной задаче',
      },
      btn: {
        ok: 'ОК'
      }
    },
    popup_file_size_error: {
      hdr: {
        header: 'Загрузка файла'
      },
      txt: {
        info: 'Превышен максимальный размер файла!', // newIdLoc
      },
      btn: {
        close: 'Закрыть' // newIdLoc
      }
    }
  },

  task_list_hdr: {
    all_tasks: 'Список задач' // newIdLoc
  },
  task_list: {
    lst: {
      all_tasks: 'Все задачи',
      my_tasks: 'Мои задачи',
      created_tasks: 'Созданные мной',
      priority: {
        high: 'Высокая',
        medium: 'Средняя',
        low: 'Низкая',
      },
      status: {
        unassigned: 'Не назначена',
        assigned: 'Назначена',
        in_progress: 'В работе',
        canceled: 'Отменена',
        completed: 'Выполнена',
      },
      unassigned: 'Не назначен'
    },
    lbl: {
      search_not_found_1: 'Задачи не найдены. Измените критерии поиска.',
      search_not_found_2: 'Ничего не найдено! Попробуйте смягчить критерии поиска.',
      paging: 'Строк на странице:',
      paging_of: 'из',
      updated_month: {
        jan: 'янв.',
        feb: 'фев.',
        mar: 'мар.',
        apr: 'апр.',
        may: 'май',
        june: 'июнь',
        july: 'июль',
        aug: 'авг.',
        sept: 'сент.',
        oct: 'окт.',
        nov: 'нояб.',
        dec: 'дек.',
      },
      segment: 'Сегмент:'
    },
    edit: {
      search_task_plh: 'Введите поисковый запрос'
    },
    btn: {
      add_task: 'Создать задачу'
    },
    hdr_column: {
      number_task: '№',
      name_task: 'Задача',
      priority: 'Важность',
      status: 'Состояние',
      assigned_by: 'Поручил',
      assignee: 'Исполнитель',
      updated_at: 'Изменено'
    },
    txt: {
      records_found: 'Найдено записей:' // newIdLoc
    }
  },
}
