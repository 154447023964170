export default {
  eventTraining: {
    iconName: 'event/event_training',
  },
  eventExercise: {
    iconName: 'event/event_exercise',
  },
  eventAddUser: {
    iconName: 'event/event_add_user'
  },
  eventCheckTargets: {
    iconName: 'event/event_check_targets'
  },
  eventTypeDefence: {
    iconName: 'event/event_type_defence'
  },
  eventTypeCtf: {
    iconName: 'event/event_type_ctf'
  },
  eventTypeResistance: {
    iconName: 'event/event_type_resistance'
  },
}
