<template>
  <v-app ref="appTemplateRef" class="app">
    <template v-if="isLoggedIn">
      <Toolbar
        class="toolbar pa-0 elevation-10"
        :class="{ hidden: !isShowToolbar }"
        v-show="!$route.meta.hideNavigation"
      />
      <UserProfile />
      <UserEditForm />
      <ToolbarChevron
        id="toolbarChevron"
        :class="{ 'open-chevron': toolbarOpened, dashboard: isDashboardPage }"
        v-if="isShowChevron"
        :is-up="toolbarOpened"
        @click="toolbarOpened = !toolbarOpened"
      />
    </template>
    <v-main
      :class="{
        'dashboard': isDashboardPage || isConnectToVmPage,
        'main-tools': isToolsPage,
        'fullscreen': (isDashboardPage || isConnectToVmPage || isRunEventPage) && !toolbarOpened
      }"
    >
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <ErrorsSnackBar />
  </v-app>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router/composables'
import { updateLocale } from 'moment'
import { i18n } from '@/i18n-setup'
import mainService from '@/services/main.service'
import ErrorsSnackBar from '@/components/error/ErrorsSnackBar'
import ToolbarChevron from '@/components/ToolbarChevron'
import Toolbar from '@/components/Toolbar'
import UserProfile from '@/components/user/UserProfile'
import UserEditForm from '@/components/user/edit/UserCreateEditForm'

const store = useStore()
const route = useRoute()

const toolbarOpened = ref(false)

const isUser = computed(() => store.getters['account/isUser'])
const isLoggedIn = computed(() => store.getters['account/isLoggedIn'])

const windowOnunloadHandlers = computed(() => store.getters['global/windowOnunloadHandlers'])

const isRunEventPage = computed(() => {
  return route.name === 'runEvent'
})

const isConnectToVmPage = computed(() => {
  return route.name === 'connectToVm'
})

const isDashboardPage = computed(() => {
  return route.name === 'dashboard'
})
const isToolsPage = computed(() => {
  return route.name === 'Tools'
})

const isShowToolbar = computed(() => {
  return (isRunEventPage.value && !isUser.value) || (!isRunEventPage.value && !isConnectToVmPage.value && !isDashboardPage.value) || toolbarOpened.value
})
const isShowChevron = computed(() => {
  return (isUser.value && isRunEventPage.value) || isConnectToVmPage.value || isDashboardPage.value
})

watch(() => !isUser.value || !isRunEventPage.value, (val) => {
  toolbarOpened.value = val
})

watch(() => isConnectToVmPage.value, (val) => {
  toolbarOpened.value = !val
})

watch(() => isDashboardPage.value, (val) => {
  toolbarOpened.value = val
})
const appTemplateRef = ref()
const initMutationObserver = () => {
  const rootNode = appTemplateRef.value.$el
  const config = { attributes: false, childList: true, subtree: false }

  const callback = (mutationList) => {
    for (const mutation of mutationList) {
      if (wasNewDropdownMenuAdded(mutation)) {
        setTimeout(() => {
          mutation.addedNodes[0].style.maxWidth = `${mutation.addedNodes[0].offsetWidth}px`
        }, 100)
      }
    }
  }

  const observer = new MutationObserver(callback)
  observer.observe(rootNode, config)
}

const wasNewDropdownMenuAdded = (mutation) => {
  const menuClass = 'v-autocomplete__content'
  return mutation.type === 'childList' &&
    mutation.addedNodes.length === 1 &&
    mutation.addedNodes[0].classList.contains(menuClass)
}

onMounted(() => {
  window.onunload = () => {
    Object.values(windowOnunloadHandlers).forEach(cb => cb())
  }

  try {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      if (registrations.length === 0) {
        navigator.serviceWorker.register('./service-worker.js')
          .then((registration) => {
            registration.update()
          })
      }
    })
  } catch (e) {}
  updateLocale(i18n.locale, i18n.t('moment_locale'))
  initMutationObserver()
})

mainService.getVersion().then(version => {
  document.title = `${i18n.t('user_authorization_lbl_cybermir')} v.${version}`
  store.commit('global/setVersion', version)
})
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/design.scss";

html {
  max-height: 100vh;
  overflow-y: hidden !important;
  font-size: $root-em !important;
  line-height: $line-height-normal !important;
  .v-application {
    font-family: $primary-font-family;
    background: url('./assets/images/cbr-background.jpg') !important;
    background-size: cover !important;
  }
}

@media only screen and (max-width: 550px) {
  .app-bar, #app {
    min-width: 550px;
    width: 550px !important;
    overflow-x: scroll;
  }
}
.dashboard {
  height: calc(100vh - 156px);
}
.main-tools {
  padding-top: 0 !important;
  margin-top: 64px;
  overflow: hidden;
}
.fullscreen {
  padding-top: 0 !important;
  height: 100vh;
}
*:focus {
  outline: none;
}

.app {
  overflow: hidden;
}
.dashboard-app-bar .v-toolbar__content {
  height: 64px !important;
}
</style>
