<template>
  <div id="options-panel" class="options" :class="isRightPanelFixed ? 'locked' : null">
    <div class="options-title">
      <div v-if="countOfSelected === 1 || isScene" class="d-flex align-center">
        <CbrIcon class="mr-2">{{ objectIcon }}</CbrIcon>
        <span>{{ objectType }}</span>
      </div>
      <v-spacer v-else />
      <CbrHint
        :description="$t(isRightPanelFixed ? 'panel_objects_btn_unlock_panel' : 'panel_objects_btn_lock_panel')"
        bottom
        no-icon
      >
        <div @click="lockPanel">
          <CbrSwitch
            :value="isRightPanelFixed"
            icon-on="mdi-pin-outline"
            icon-off="mdi-pin-off-outline"
            fade
          />
        </div>
      </CbrHint>
    </div>
    <div class="options-content">
      <div v-if="countOfSelected > 1" class="options-content__info">
        {{ $t('properties_panel_object_lbl_info_properties') }}
      </div>
      <template v-else>
        <!-- Название -->
        <div v-if="isEssence" class="options-content__item">
          <label>{{$t('properties_panel_object_edit_label')}}</label>
          <CbrTextField
            v-model="selected.label"
            :disabled="selected.isBlocked || baseObj"
            :placeholder="selected.name"
            hide-details
            small
          />
        </div>
        <!-- ID / Блокировка -->
        <div v-if="!isScene" class="options-content__item d-flex justify-space-between">
          <span>
            <mark>ID:</mark> {{ selected.id }}
          </span>
          <CbrHint
            :description="$t(selected.isBlocked ? 'properties_panel_object_btn_blocked' : 'properties_panel_object_btn_unblocked')"
            left
            no-icon
          >
            <CbrIcon @click="selected.isBlocked ? tools.UnBlockObjects() : tools.BlockObjects()" class="pointer">
              {{ selected.isBlocked ? '$toolsLocked' : '$toolsUnlocked' }}
            </CbrIcon>
          </CbrHint>
        </div>
        <hr v-if="!isScene" class="options-content__line" />
        <!-- Триггеры -->
        <div v-if="!isEssence" class="options-content__item d-flex">
          <CbrButton
            :text="`${$t('properties_panel_object_btn_set_trigges')} (${selected.triggers.length})`"
            icon="$toolsLibPlus"
            cbr-icon
            size="small"
            class="w100"
            @click="triggersDialogShown"
          />
        </div>
        <hr v-if="!isEssence" class="options-content__line" />
        <!-- Текст. Размер шрифта, цвет текста -->
        <div v-if="isScene || isText" class="options-content__item">
          <label>
            {{ $t(isScene ? 'properties_panel_visualization_edit_notification' : 'panel_objects_hdr_text') }}
          </label>
          <CbrTextField
            v-if="isScene"
            v-model="selected.Notification"
            :disabled="baseObj"
            :clearable="false"
            hide-details
            small
          />
          <CbrTextField
            v-if="isText"
            v-model="selected.message"
            :disabled="baseObj"
            :clearable="false"
            hide-details
            small
          />
        </div>
        <div v-if="isScene" class="options-content__item d-flex justify-space-between">
          <label>{{ $t('trigger_scene_lbl_notification_color') }}</label>
          <ColorPicker v-model="selected.NotificationColor" :disabled="baseObj" />
        </div>
        <div v-if="isText" class="options-content__item">
          <label>{{ $t('properties_panel_object_lbl_font_size') }}</label>
          <div class="d-flex justify-space-between">
            <CbrTextField
              v-if="isText"
              v-model="selected.textSize"
              :disabled="baseObj"
              :clearable="false"
              style="width:108px"
              type="number"
              :min="1"
              hide-details
              small
            />
            <ColorPicker v-model="selected.color" :disabled="baseObj" />
          </div>
        </div>
        <hr v-if="isScene || isText" class="options-content__line" />
        <!-- Координаты объекта/текста -->
        <div v-if="isEssence || isText" class="options-content__item">
          <div class="d-flex">
            <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
            <CbrTextField
              v-model="selected.x"
              :disabled="selected.isBlocked || baseObj"
              :clearable="false"
              type="number"
              hide-details
              small
            />
          </div>
          <div class="d-flex mt-3">
            <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
            <CbrTextField
              v-model="selected.y"
              :disabled="selected.isBlocked || baseObj"
              :clearable="false"
              type="number"
              hide-details
              small
            />
          </div>
        </div>
        <!-- Поворот/наклон текста -->
        <div v-if="isText" class="options-content__item d-flex justify-space-between">
          <div class="d-flex">
            <CbrIcon class="mr-1">$toolsRotateAngle</CbrIcon>
            <CbrTextField
              v-model="selected.rotateDegree"
              :disabled="selected.isBlocked || baseObj"
              :clearable="false"
              style="width:81px"
              type="number"
              hide-details
              small
            />
          </div>
          <div class="d-flex">
            <CbrIcon class="mr-1">$toolsSkewAngle</CbrIcon>
            <CbrTextField
              v-model="selected.skewDegree"
              :disabled="selected.isBlocked || baseObj"
              :clearable="false"
              style="width:81px"
              type="number"
              hide-details
              small
            />
          </div>
        </div>
        <!-- Передний/задний план объекта -->
        <div v-if="isEssence" class="options-content__item d-flex" style="height:40px">
          <CbrHint bottom no-icon :description="$t('properties_panel_object_btn_bring_to_back')">
            <CbrButton
              icon="$toolsToBack"
              size="small"
              border
              @click="bringToBack(selected.id)"
            />
          </CbrHint>
          <CbrHint bottom no-icon :description="$t('properties_panel_object_btn_bring_to_front')">
            <CbrButton
              icon="$toolsToFront"
              size="small"
              class="ml-3"
              border
              @click="bringToFront(selected.id)"
            />
          </CbrHint>
        </div>
        <hr v-if="isEssence" class="options-content__line" />
        <!-- Координаты подложки -->
        <div v-if="isPlane" class="options-content__item">
          <div class="options-content__item-coords">
            <div>
              <CbrIcon class="mb-2">$toolsCornerLeftTop</CbrIcon>
              <div class="d-flex">
                <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                <CbrTextField
                  v-model="selected.a.x"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
              <div class="d-flex mt-1">
                <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                <CbrTextField
                  v-model="selected.a.y"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
            </div>
            <div>
              <CbrIcon class="mb-2">$toolsCornerRightTop</CbrIcon>
              <div class="d-flex">
                <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                <CbrTextField
                  v-model="selected.d.x"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
              <div class="d-flex mt-1">
                <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                <CbrTextField
                  v-model="selected.d.y"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
            </div>
            <div>
              <CbrIcon class="mb-2">$toolsCornerLeftBottom</CbrIcon>
              <div class="d-flex">
                <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                <CbrTextField
                  v-model="selected.b.x"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
              <div class="d-flex mt-1">
                <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                <CbrTextField
                  v-model="selected.b.y"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
            </div>
            <div>
              <CbrIcon class="mb-2">$toolsCornerRightBottom</CbrIcon>
              <div class="d-flex">
                <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                <CbrTextField
                  v-model="selected.c.x"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
              <div class="d-flex mt-1">
                <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                <CbrTextField
                  v-model="selected.c.y"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  type="number"
                  style="width:76px"
                  hide-details
                  small
                />
              </div>
            </div>
          </div>
        </div>
        <hr v-if="isPlane" class="options-content__line" />
        <!-- Цвет подложки -->
        <div v-if="isPlane" class="options-content__item d-flex justify-space-between">
          <label>{{ $t('trigger_background_lbl_background_color') }}</label>
          <ColorPicker v-model="selected.bgColor" :disabled="baseObj" />
        </div>
        <hr v-if="isPlane" class="options-content__line" />
        <div v-if="isPlane" class="options-content__item d-flex justify-space-between">
          <label>{{ $t('trigger_background_lbl_stroke_color') }}</label>
          <ColorPicker v-model="selected.lineColor" :disabled="baseObj" />
        </div>
        <!-- Свойства линии -->
        <div v-if="isLine" class="options-content__expansion">
          <div class="options-content__expansion-header" @click="linePropertiesIsOpen = !linePropertiesIsOpen">
            <CbrIcon>{{ linePropertiesIsOpen ? '$toolsArrowOpened' : '$toolsArrowClosed' }}</CbrIcon>
            <label class="pointer">{{ $t('properties_panel_object_lbl_line_properties') }}</label>
          </div>
          <div v-show="linePropertiesIsOpen" class="options-content__expansion-content">
            <div>
              <label>{{ $t('properties_panel_object_edit_start') }}</label>
              <div class="options-content__item" v-if="isLineConnector">
                <div class="d-flex mt-2">
                  <span class="mx-2">Start id</span>
                  <CbrTextField
                    v-model="selected.startID"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    style="width:108px"
                    hide-details
                    small
                    @input="setStartID"
                  />
                </div>
              </div>
              <div class="options-content__item" v-else>
                <div class="d-flex mt-2">
                  <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                  <CbrTextField
                    v-model="lineEndPoints[0].x"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    type="number"
                    hide-details
                    small
                  />
                </div>
                <div class="d-flex mt-3">
                  <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                  <CbrTextField
                    v-model="lineEndPoints[0].y"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    type="number"
                    hide-details
                    small
                  />
                </div>
              </div>
            </div>
            <div>
              <label>{{ $t('properties_panel_object_edit_end') }}</label>
              <div class="options-content__item" v-if="isLineConnector">
                <div class="d-flex mt-2">
                  <span class="mx-2">End id</span>
                  <CbrTextField
                    v-model="selected.endID"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    style="width:108px"
                    hide-details
                    small
                    @input="setEndID"
                  />
                </div>
              </div>
              <div class="options-content__item" v-else>
                <div class="d-flex mt-2">
                  <CbrIcon class="mr-1">$toolsCoordX</CbrIcon>
                  <CbrTextField
                    v-model="lineEndPoints[1].x"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    type="number"
                    hide-details
                    small
                  />
                </div>
                <div class="d-flex mt-3">
                  <CbrIcon class="mr-1">$toolsCoordY</CbrIcon>
                  <CbrTextField
                    v-model="lineEndPoints[1].y"
                    :disabled="selected.isBlocked || baseObj"
                    :clearable="false"
                    type="number"
                    hide-details
                    small
                  />
                </div>
              </div>
            </div>
            <hr class="options-content__line" />
            <div>
              <label>{{ $t('properties_panel_object_edit_line_width') }}</label>
              <div class="options-content__item d-flex justify-space-between">
                <CbrTextField
                  v-model="selected.lineWidth"
                  :disabled="selected.isBlocked || baseObj"
                  :clearable="false"
                  :min="1"
                  style="width:108px"
                  type="number"
                  hide-details
                  small
                />
                <ColorPicker v-model="selected.color" :disabled="baseObj" />
              </div>
            </div>
          </div>
        </div>
        <!-- Состояние объекта, триггеры -->
        <div v-if="isEssence" class="options-content__item">
          <label>{{ $t('properties_panel_object_lbl_state') }}</label>
          <CbrCombobox
            :value="selectedState"
            :preselect="selectedState"
            :items="states"
            :disabled="baseObj"
            hide-details
            select-only
            size="small"
            @change="selected.state = $event.value"
          />
        </div>
        <div v-if="isEssence" class="options-content__item">
          <CbrButton
            :text="`${$t('properties_panel_object_btn_set_trigges')} (${selected.triggers.length})`"
            icon="$toolsLibPlus"
            cbr-icon
            size="small"
            class="w100"
            @click="triggersDialogShown"
          />
        </div>
        <hr v-if="isEssence" class="options-content__line" />
        <!-- Эффекты -->
        <div v-if="isEssence || isLine || isScene" class="options-content__item">
          <label>{{ $t('properties_panel_properties_scene_lbl_effects') }}</label>
          <CbrCombobox
            :value="selectedEffects"
            :items="effects"
            :preselect="selectedEffects"
            :selected-item-fn="selectedEffectFn"
            :id="selected.id"
            :disabled="baseObj"
            hide-details
            select-only
            multiple
            no-select-all
            size="small"
            @change="changeEffect"
          />
        </div>
        <hr v-if="isEssence || isLine" class="options-content__line" />
        <!-- Контекстные иконки -->
        <div v-if="isEssence" class="options-content__item">
          <label>{{ $t('properties_panel_object_lbl_icons') }}</label>
          <CbrCombobox
            :value="selectedIcon"
            :items="icons"
            :preselect="selected.icons.length ? { name: selected.icons[0].name, image: selected.icons[0].url } : null"
            :id="selected.id"
            :disabled="baseObj"
            hide-details
            select-only
            clearable
            image
            size="small"
            @change="changeIcon"
            @clear="deleteIcon"
          />
        </div>
        <hr v-if="isEssence" class="options-content__line" />
        <!-- GIF-эффекты -->
        <div v-if="isEssence && gifEffects.length" class="options-content__item">
          <label>{{ $t('properties_panel_object_lbl_gif_effects') }}</label>
          <CbrCombobox
            :value="selectedGifEffects"
            :items="gifEffects"
            :preselect="selectedGifEffects"
            :id="selected.id"
            :disabled="baseObj"
            hide-details
            select-only
            multiple
            no-select-all
            size="small"
            @change="changeGifEffect"
          />
        </div>
        <!-- Направление анимации -->
        <div v-if="isLine" class="options-content__item">
          <label>{{ $t('properties_panel_object_btn_animate_direction') }}</label>
          <CbrCheckBox
            :checked="!selected.animationDirectionFromLeft"
            :label="$t('properties_panel_object_btn_animate_direction_reverse')"
            :disabled="baseObj"
            @change="changeAnimateDirectionFromLeft(!$event.target.checked)"
          />
        </div>
        <!-- Изменение центра -->
        <div v-if="isEssence" class="options-content__expansion">
          <div class="options-content__expansion-header" @click="changeCenter = !changeCenter">
            <CbrIcon>{{ changeCenter ? '$toolsArrowOpened' : '$toolsArrowClosed' }}</CbrIcon>
            <label class="pointer">{{ $t('properties_panel_object_lbl_change_center') }}</label>
          </div>
          <div v-show="changeCenter" class="options-content__expansion-content">
            <div class="options-set-center">
              <div class="options-set-center__container">
                <img
                  ref="centerImg"
                  class="change-center"
                  :src="selected.img.src"
                  @click="changeCenterCoords"
                />
                <img
                  class="center-point"
                  src="@/assets/icons/svg/tools/centerpoint.svg"
                  alt=""
                  :style="{ top: centerPointTop + 'px', left: centerPointLeft + 'px' }"
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="options-footer" v-if="baseObj">
      <span>{{ $t('properties_panel_object_lbl_test_mode') }}</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useStore } from '@/store'
import { i18n } from '@/i18n-setup'
import { constructor } from '@/plugins/map/constructor/player'
import ColorPicker from '@/components/ColorPicker'

const store = useStore()

const isRightPanelFixed = ref(false)
const changeCenter = ref(false)
const imgScale = ref(1)
const centerPointTop = ref(0)
const centerPointLeft = ref(0)
const centerImg = ref()
const linePropertiesIsOpen = ref(true)

isRightPanelFixed.value = JSON.parse(localStorage.getItem('tools-config'))?.isRightPanelFixed || false

const tools = computed(() => store.getters.tools)
const selected = computed(() => store.getters.selected)
const countOfSelected = computed(() => store.getters.countOfSelected)
const isEssence = computed(() => selected.value.designation === 'essence')
const isLine = computed(() => selected.value.name === 'line')
const isPlane = computed(() => selected.value.name === 'plane')
const isText = computed(() => selected.value.name === 'text')
const isScene = computed(() => selected.value.id === 'scene')
const icons = computed(() => store.getters.icons)

const objectType = computed(() => {
  if (isEssence.value) return i18n.t('panel_objects_hdr_essence')
  if (isLine.value) return i18n.t('panel_objects_hdr_line')
  if (isPlane.value) return i18n.t('panel_objects_hdr_plane')
  if (isScene.value) return i18n.t('panel_objects_hdr_scene')
  if (isText.value) return i18n.t('panel_objects_hdr_text')
  return ''
})
const objectIcon = computed(() => {
  if (isEssence.value) return '$toolsTypeEssence'
  if (isLine.value) return '$toolsTypeLine'
  if (isPlane.value) return '$toolsTypePlane'
  if (isText.value) return '$toolsTypeText'
  return ''
})

const statesName = computed(() => store.getters.statesName)
const states = computed(() => store.getters.states.map((state) => {
  return {
    value: state,
    name: i18n.t(store.getters.statesName[state])
  }
}))
const selectedState = computed(() => {
  return selected.value.state
    ? { value: selected.value.state, name: i18n.t(statesName.value[selected.value.state]) }
    : null
})
const effectsName = computed(() => {
  if (isScene.value) return store.getters.sceneEffectsName
  if (isLine.value) return store.getters.lineEffectsName
  return store.getters.effectsName
})
const effects = computed(() => {
  let items = store.getters.effects
  if (isScene.value) items = store.getters.sceneEffects
  if (isLine.value) items = store.getters.lineEffects
  return items.map(item => {
    return {
      value: item,
      name: i18n.t(effectsName.value[item])
    }
  })
})
const selectedEffects = computed(() => {
  return selected.value.effect
    ?.filter(effect => {
      return isEssence.value ? effects.value.map(item => item.value).includes(effect) : true
    })
    .map((effect) => {
      return { value: effect, name: i18n.t(effectsName.value[effect]) }
    })
})
const gifEffects = computed(() => store.getters.gifEffectsData
  ?.filter(item => item.componentName === selected.value.name)
  .map(item => {
    return { ...item, isSelected: selected.value.effect.includes(item.name) }
  }))
const selectedGifEffects = computed(() => {
  return selected.value.effect
    ?.filter(effect => gifEffects.value.map(item => item.name).includes(effect))
    .map(effect => {
      return { value: effect, name: effect }
    })
})
const selectedEffectFn = (item) => selected.value.effect.includes(item.value)
const changeEffect = (e) => {
  addEffect(e.value)
}
const changeGifEffect = (e) => {
  addEffect(e.name)
}

const selectedIcon = computed(() => selected.value.icons.map(icon => {
  return { name: icon.name, image: icon.url }
})[0])

const changeIcon = (icon) => icon
  ? tools.value.AddIcon(constructor, selected.value, { ...icon, id: selected.value.id })
  : selected.value.icons = []

const deleteIcon = (icon) => {
  selected.value.icons = []
}

const isLineConnector = computed(() => (selected.value.startID && selected.value.startID !== true) || selected.value.endID)
const lineEndPoints = computed(() => {
  if (!selected.value.graphList.length) {
    store.commit('selected', constructor.scene)
    return [{}, {}]
  }
  return selected.value.graphList
    ?.filter(graph => graph[1].length === 1)
    .map(graph => selected.value.points[graph[0]])
})
const baseObj = computed(() => store.getters.switchBaseObj)

const lockPanel = () => {
  isRightPanelFixed.value = !isRightPanelFixed.value
  localStorage.setItem(
    'tools-config',
    JSON.stringify(
      Object.assign(JSON.parse(localStorage.getItem('tools-config')) || {}, {
        isRightPanelFixed: isRightPanelFixed.value
      })
    )
  )
}
const setStartID = (val) => {
  const targetEssence = constructor.essence.find(({ id }) => id === val)
  if (targetEssence) {
    selected.value.startID = targetEssence.id
    selected.value.startX = targetEssence.x + targetEssence.centerPointOffsetX
    selected.value.startY = targetEssence.y + targetEssence.centerPointOffsetY
  }
}
const setEndID = (val) => {
  const targetEssence = constructor.essence.find(({ id }) => id === val)
  if (targetEssence) {
    selected.value.endID = targetEssence.id
    selected.value.endX = targetEssence.x + targetEssence.centerPointOffsetX
    selected.value.endY = targetEssence.y + targetEssence.centerPointOffsetY
  }
}
const bringToFront = (id) => tools.value.BringToFront(id)
const bringToBack = (id) => tools.value.BringToBack(id)

const addEffect = (effect) => {
  if (selected.value.effect.find(_effect => _effect === effect)) {
    tools.value.DeleteEffect(selected.value, effect)
  } else {
    tools.value.AddEffect(selected.value, effect)
  }
}
const setCenterCoords = () => {
  nextTick(() => {
    calcImgScale()
    const img = centerImg.value
    centerPointTop.value =
      (selected.value.centerPointOffsetY + selected.value.offsetY) * imgScale.value - img.clientHeight / 2
    centerPointLeft.value =
      (selected.value.centerPointOffsetX + selected.value.offsetX) * imgScale.value - img.clientWidth / 2
    if (selected.value.centerPointOffsetY === 0 && selected.value.offsetY === 0) {
      centerPointTop.value = 0
    }
    if (selected.value.centerPointOffsetX === 0 && selected.value.offsetX === 0) {
      centerPointLeft.value = 0
    }
  })
}
const changeCenterCoords = (e) => {
  calcImgScale()
  centerPointTop.value = e.offsetY - e.target.clientHeight / 2
  centerPointLeft.value = e.offsetX - e.target.clientWidth / 2
  const centerPointOffsetX = Math.round(e.offsetX / imgScale.value - selected.value.offsetX)
  const centerPointOffsetY = Math.round(e.offsetY / imgScale.value - selected.value.offsetY)
  tools.value.ChangeCenter(selected.value.id, centerPointOffsetX, centerPointOffsetY)
}
const calcImgScale = () => {
  const img = centerImg.value
  imgScale.value = Math.min(
    img.clientWidth / img.naturalWidth,
    img.clientHeight / img.naturalHeight
  )
}
const changeAnimateDirectionFromLeft = (val) => store.commit('changeAnimationDirection', val)
const triggersDialogShown = () => store.commit('setTriggersDialogShown', true)

watch(() => changeCenter.value, (val) => {
  if (val) setCenterCoords()
})
watch(() => selected.value, (obj, objOld) => {
  if (obj.img && changeCenter.value && countOfSelected.value === 1) setCenterCoords()
  if (objOld.temp) tools.value.BringToBack(objOld.id, { initialIndex: objOld.temp.initialIndex })
})
</script>

<style lang="scss" scoped>
.options {
  @include cut-corners(7px, true, 7px, false);
  transition: all ease 0.3s;
  overflow: hidden;
  position: absolute;
  backdrop-filter: blur(12.5px);
  height: calc(100vh - 94px);
  width: 286px;
  right: -212px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 16px 0;
  padding-right: 8px;
  z-index: 99;
  &-title {
    @include cut-corners(7px, true, 5px, false);
    // TODO replace with var
    background-color: rgba(#D9D9D9, 0.15);
    color: $base-color-text;
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px 0 12px;
    height: 36px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: calc(100% - 36px);
    padding: 16px 0 8px;
    gap: 12px;
    background: rgba($base-color-light, 0.05);
    backdrop-filter: blur(12.5px);
    &__item {
      padding: 0 16px;
      color: $base-color-text;
      mark {
        background: none;
        color: $base-color;
      }
      &-coords {
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 50%;
          text-align: center;
          &:nth-child(1) {
            padding: 6px 6px 12px 0;
            border-right: 1px solid rgba($base-color-light, 0.1);
          }
          &:nth-child(2) {
            padding: 6px 0 12px 6px;
          }
          &:nth-child(3) {
            padding: 12px 6px 6px 0;
            border-right: 1px solid rgba($base-color-light, 0.1);
            border-top: 1px solid rgba($base-color-light, 0.1);
          }
          &:nth-child(4) {
            padding: 12px 0 6px 6px;
            border-top: 1px solid rgba($base-color-light, 0.1);
          }
          > div {
            display: flex;
            gap: 3px;
          }
        }
      }
    }
    &__expansion {
      display: flex;
      flex-direction: column;
      padding: 8px 0px 16px;
      gap: 12px;
      background: rgba($base-black, 0.1);
      border: 1px solid rgba($base-black, 0.1);
      &-header {
        display: flex;
        padding: 0px 16px;
        gap: 6px;
        cursor: pointer;
        color: $base-color-light;
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        label {
          padding: 0 16px;
          color: $base-color-text;
        }
      }
    }
    &__line {
      border: 1px solid rgba($base-color-light, 0.15);
      border-width: 1px 0 0 0;
    }
    &__info {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $base-color-text;
      padding: 0 16px;
      padding-top: 48px;
    }
  }
  &-footer {
    color: $base-color-warning;
    position: absolute;
    bottom: 16px;
    width: 100%;
    text-align: center;
  }
  &.locked {
    right: 0;
  }
  &:hover {
    right: 0;
  }
  &-set-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 186px;

    &__container {
      position: relative;
      img {
        cursor: crosshair;
        max-width: 186px;
        max-height: 186px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .change-center {
        max-width: 246px;
        background-color: rgba($base-black, 0.1);
      }
      .center-point {
        position: absolute;
        z-index: 2;
      }
    }
  }
}
</style>
