import { commonRu, momentLocale } from './common/common.ru'
import { eulaRu } from '@/locales/eula/eula.ru'
import { taskRu } from './task/task.ru'
import { mapRu } from './map/map.ru'
import { authRu } from '@/locales/auth/auth.ru'
import { eventRu } from '@/locales/event/event.ru'
import { scoreboardRu } from '@/locales/scoreboard/scoreboard.ru'

export default {
  ...commonRu,
  ...taskRu,
  ...mapRu,
  ...authRu,
  ...eventRu,
  ...scoreboardRu,

  eula: eulaRu,
  moment_locale: momentLocale,

  tech_map_hdr_select_tech: 'Выберите технику',
  tech_map_btn_accept: 'Подтвердить',
  tech_map_btn_cancel: 'Отменить',
  tech_map_edit_filter_plh: 'Введите ключевые слова (например, скан порт юзер эксплойт)…',
  tech_card_hint_tactic: 'Тактика / группа техник',
  tech_card_lbl_targets: 'Цели:',
  tech_card_lbl_phase: 'Фаза (killchain):',
  tech_card_lbl_desc: 'Описание:',
  tech_card_lbl_tags: 'Теги:',
  tech_card_lbl_iocs: 'Индикаторы компрометации:',
  login_form_incorrect_email: 'Введен некорректный E-mail',
  user_card_lbl_error_photo: 'Ошибка при загрузке изображения',
  user_card_popup_edit_lbl_error_photo: 'Ошибка при загрузке изображения',
  user_panel_popup_create_user_lbl_error_photo: 'Ошибка при загрузке изображения',
  user_card_lbl_error_upload_smaller_photo: 'Выберите изображение меньше 256кб',
  user_card_popup_edit_lbl_error_upload_smaller_photo: 'Выберите изображение меньше 256кб',
  user_panel_popup_create_user_lbl_error_upload_smaller_photo: 'Выберите изображение меньше 256кб',
  user_card_lbl_error_photo_type: 'Выбран некорректный формат изображения',
  user_panel_lbl_number_users: 'Пользователей:',
  authentication_change_pwd_lbl_more_characters: '10 и более символов',
  event_card_hdr_column_email: 'E-mail',
  event_creation_editing_popup_observes_hdr_column_email: 'E-mail',
  bulk_user_uploading_popup_grid_users_hdr_column_email: 'E-mail',
  org_panel_popup_admins_hdr_column_email: 'E-MAIL',
  auth_popup_password_change_edit_email: 'Email',
  user_authorization_lst_choose_language_eng: 'English ',
  scoring_popup_send_report_error_btn_confirm: 'OK',
  event_card_hdr_column_assesment_hint: 'Оценить',
  event_card_hdr_column_VM: 'VM',
  event_creation_editing_popup_participants_hdr_column_vm: 'VM',
  event_creation_editing_popup_participants_lbl_assigned_vm: 'Назначена VM:',
  event_creation_editing_popup_participants_lbl_participate: 'Участвуют',
  event_creation_editing_popup_participants_lbl_ready: 'Готовы:',
  event_creation_editing_popup_participants_lbl_new: 'Новые:',
  event_creation_editing_popup_participants_lbl_infra: 'Слот',
  event_creation_editing_popup_participants_lbl_error_add_users: 'Участников больше, чем доступно VM',
  event_creation_editing_hdr_process_automation: 'Автоматизация процессов',
  event_creation_editing_btn_auto_publ_grade: 'Автопубликация оценки',
  event_creation_editing_btn_completion_status_of_technician: 'Статус заполнения техник',
  event_creation_editing_btn_report_prefilling: 'Предзаполнение отчета',
  event_creation_type_training: 'Учение / обучение',
  event_creation_type_training_title: 'Практические и контрольные занятия по обнаружению, расследованию и защите от КА. Система автоматически атакует инфраструктуру по выбранному сценарию.',
  event_creation_type_flag: 'Захват флага',
  event_creation_type_flag_title: 'Соревнование, в ходе которого команды должны проникнуть в инфраструктуру противников и разместить флаг, при этом защищая свою инфраструктуру от проникновения.',
  event_creation_type_opposition: 'Противостояние',
  event_creation_type_opposition_title: 'Команды должны защищать свою инфраструктуру и атаковать инфраструктуру противников с целью вызвать отказ в обслуживании критических сервисов',
  event_creation_ctf_team_title: 'Команда',
  navigation_panel_popup_menu_btn_admin_panel: 'Панель администратора',
  user_card_lbl_user_role_admin: 'Администратор',
  user_panel_lst_role_admin: 'Администратор',
  org_panel_lst_activity_active: 'Активные',
  user_panel_lst_activity_active: 'Активные ',
  event_creation_editing_btn_attacks: 'Атаки',
  scoring_scoreboard_hdr_column_scores: 'Баллы',
  scoring_scoreboard_hdr_column_company: 'Организация',
  event_card_hdr_column_scores: 'Баллы',
  org_panel_popup_unfinish_ev_user_btn_block: 'Блокировать',
  event_card_lbl_event_status_in_progress: 'В процессе!',
  auth_popup_password_change_txt_get_instruction: 'Укажите адрес электронной почты, на который будет выслана инструкция по восстановлению доступа к системе:',
  user_panel_edit_enter_user_name_plh: 'Введите имя пользователя',
  event_creation_editing_edit_event_name: 'Введите название мероприятия',
  missions_list_edit_search_mission_plh: 'Введите название миссии или ее идентификатор',
  org_panel_edit_enter_org_plh: 'Введите название организации',
  event_creation_editing_edit_event_goals_plh: 'Введите цели и задачи мероприятия',
  event_screen_popup_phase_task_lbl_vector: 'Вектор ',
  event_screen_popup_hint_lbl_vector: 'Вектор ',
  scoring_popup_settings_tgl_display_places_on_plh: 'Вкл',
  event_creation_editing_btn_attacks_on_plh: 'Вкл',
  event_creation_editing_btn_noises_on_plh: 'Вкл',
  event_creation_editing_btn_event_completion_on_plh: 'Вкл',
  event_creation_editing_btn_scoring_on_plh: 'Вкл',
  event_creation_editing_btn_auto_publ_grade_on_plh: 'Вкл',
  event_card_lbl_demo_stand: 'Стенд',
  event_card_txt_demo_stand_plh: 'Конфигурация не загружена',
  event_card_txt_demo_stand_err: 'Неверный формат файла!',
  event_creation_editing_lbl_upload_file_stand_plh: 'Загрузите файл',
  event_creation_editing_btn_upload_file_stand: 'Загрузить...',
  org_panel_popup_create_org_chk_internal_org: 'Внутренняя организация',
  org_panel_popup_edit_org_chk_internal_org: 'Внутренняя организация',
  user_authorization_btn_enter: 'Войти',
  scoring_participant_report_edit_recovery_desc: 'Восстановление',
  auth_popup_password_change_lbl_password_recovery: 'Восстановление пароля',
  scoring_participant_report_crumbs_all_events: 'Все мероприятия',
  event_creation_editing_crumbs_all_events: 'Все мероприятия',
  scoring_report_evaluation_crumbs_all_events: 'Все мероприятия ',
  event_card_crumbs_all_events: 'Все мероприятия ',
  event_card_log_crumbs_all_events: 'Все мерпориятия',
  event_creation_editing_crumbs_all_missions: 'Все миссии',
  event_creation_editing_crumbs_missions_list: 'Список миссий',
  mission_card_crumbs_all_missions: 'Все миссии',
  missions_list_hdr_all_missions: 'Все миссии',
  missions_list_tabs_publicated: 'Опубликованные',
  missions_list_tabs_in_progress: 'В разработке',
  missions_list_remove_popup_title: 'Удаление миссии',
  missions_list_remove_popup_first_line: 'Вы действительно хотите удалить миссию?',
  missions_list_copy_title: 'Копия',
  user_panel_lst_org_all_plh: 'Все организации',
  user_panel_lst_role_all_plh: 'Все роли',
  event_popup_event_end_txt_end: 'Вы действительно желаете завершить\nмероприятие?',
  event_card_popup_reboot_txt_question_descr: 'Операция займет некоторое время, для продолжения работы обновите страницу спустя 1-2 минуты',
  scoring_popup_del_tech_txt_ask_del: 'Вы уверены, что хотите удалить отчет по данной технике?',
  scoring_popup_add_tech_lst_tech_type_plh: 'Выберите атакующую технику',
  scoring_popup_settings_lst_select_event_plh: 'Выберите мероприятия',
  scoring_popup_settings_name_not_found: 'Без названия',
  scoring_popup_settings_status_not_found: 'Статус неизвестен',
  scoring_popup_settings_time_not_found: 'время не указано',
  scoring_popup_settings_date_not_found: 'дата не указана',
  event_creation_editing_lst_organization_plh: 'Выберите организацию',
  bulk_user_uploading_lst_error_select_organization: 'Выберите организацию',
  scoring_popup_add_tech_lst_attack_type_plh: 'Выберите тип атакующей техники',
  bulk_user_uploading_btn_select_file: 'Выберите файл .XLSX',
  user_authorization_lst_choose_language: 'Выберите язык:',
  scoring_popup_settings_tgl_display_places_off_plh: 'Выкл',
  event_creation_editing_btn_attacks_off_plh: 'Выкл',
  event_creation_editing_btn_noises_off_plh: 'Выкл',
  event_creation_editing_btn_event_completion_off_plh: 'Выкл',
  event_creation_editing_btn_scoring_off_plh: 'Выкл',
  event_creation_editing_btn_auto_publ_grade_off_plh: 'Выкл',
  event_screen_popup_history_chk_high: 'Высокий ',
  auth_popup_password_change_edit_next: 'Далее',
  event_card_log_edit_date_time: 'Дата и время начала',
  event_creation_editing_edit_event_date_time: 'Дата и время начала',
  user_card_hdr_column_start_date: 'Дата начала',
  org_panel_popup_unfinish_event_hdr_column_start_date: 'Дата начала',
  org_panel_popup_unfinish_ev_user_hdr_column_start_date: 'Дата начала',
  user_card_hdr_column_end_date: 'Дата окончания',
  org_panel_hdr_column_org_type: 'Тип',
  org_panel_hdr_column_creation_date: 'Дата создания',
  user_panel_hdr_column_creation_date: 'Дата создания',
  event_creation_editing_finished: 'Мероприятие было завершено либо отменено',
  authentication_change_pwd_lbl_create_safe_password: 'Для защиты ваших данных необходимо придумать безопасный пароль',
  event_creation_editing_lbl_no_scoring: 'Скоринг невозможен.',
  event_creation_editing_lbl_no_report: 'Для мероприятия отсутствует эталонный отчет. Скоринг невозможен.',
  event_creation_editing_btn_teams_add: 'Добавить команду',
  event_creation_editing_popup_observes_hdr_add_observes: 'Управление наблюдателями',
  scoring_participant_report_btn_add_tech: 'Добавить технику',
  event_creation_editing_popup_participants_hdr_add_participants: 'Управление участниками',
  event_card_hdr_column_position: 'Должность',
  event_creation_editing_popup_observes_hdr_column_position: 'Должность',
  bulk_user_uploading_popup_grid_users_hdr_column_position: 'Должность',
  user_card_popup_edit_edit_position: 'Должность',
  user_panel_hdr_column_position: 'Должность',
  user_panel_popup_create_user_edit_position_plh: 'Должность',
  bulk_user_uploading_popup_users_lbl_error_line_duplication_hint: 'Дублирует строку:',
  user_authorization_btn_forgot_password: 'Забыли пароль?',
  event_creation_editing_btn_event_completion: 'Завершение мероприятия',
  bulk_user_uploading_popup_users_btn_upload: 'Загрузить',
  user_panel_btn_upload: 'Загрузить',
  user_card_lbl_upload_photo: 'Загрузить фото',
  user_card_popup_edit_btn_upload_photo: 'Загрузить фото',
  user_panel_popup_create_user_btn_upload_photo_plh: 'Загрузить фото',
  user_panel_popup_confirm_del_hdr_user_del: 'Удаление пользователя',
  user_panel_popup_confirm_del_lbl_msg: 'Вы действительно желаете удалить пользователя?',
  user_panel_popup_del_blocked_lbl_msg: 'Пользователь участвует в мероприятии и не может быть удален!',
  bulk_user_uploading_btn_upload: 'Загрузить',
  bulk_user_uploading_hdr_uploading_users: 'Загрузка пользователей',
  bulk_user_uploading_popup_created_users_hdr_uploading_users: 'Загрузка пользователей',
  mission_card_lst_task: 'Задача',
  event_screen_popup_hint_btn_close: 'Закрыть ',
  event_creation_editing_popup_participants_lbl_error_search_no_content: 'Записей с похожим названием не обнаружено',
  event_creation_editing_edit_error_event_time_false_data: 'Заполните обязательные данные и выберите тип мероприятия',
  event_creation_editing_lbl_complete_fields: 'Заполните обязательные данные и выставьте настройки мероприятия',
  event_creation_editing_lbl_fill_list_participants: 'Заполните список участников',
  event_card_btn_start: 'Запустить',
  events_list_btn_start: 'Запустить',
  scoring_participant_report_edit_protection_desc: 'Защита',
  event_creation_editing_lbl_protection: 'Защита',
  events_list_lbl_protection: 'Защита',
  events_list_lbl_battle: 'Битва',
  missions_list_lbl_protection: 'Защита',
  event_screen_popup_particip_hdr_defenders: 'Защитники ',
  authentication_change_pwd_lbl_punctuation_marks: 'Знаки пунктуации ',
  scoring_report_evaluation_tag_tech_status_changed: 'Изменена',
  scoring_report_evaluation_tag_tech_status_under_evaluation: 'На оценке',
  scoring_report_evaluation_tag_tech_status_rated: 'Оценена',
  scoring_report_evaluation_tag_ioc_status_changed: 'Изменен',
  event_card_btn_change: 'Изменить данные',
  event_card_btn_edit: 'Редактировать',
  event_creation_editing_popup_edit_notsuccessfully_hdr_edit: 'РЕДАКТИРОВАНИЕ МЕРОПРИЯТИЯ',
  event_creation_editing_popup_edit_notsuccessfully_hdr_event_1: 'Мероприятие изменило свой статус. Изменения не сохранены.',
  event_creation_editing_popup_edit_notsuccessfully_hdr_event_2: 'Обновите страницу для продолжения',
  event_creation_editing_popup_edit_notsuccessfully_btn_refresh: 'Обновить страницу',
  bulk_user_uploading_lbl_drag_file: 'или перетащите его сюда',
  bulk_user_uploading_popup_users_lbl_drag_updated_file: 'Или перетащите новый файл сюда',
  bulk_user_uploading_popup_users_txt_error_delete_erroneous_lines: 'Имеются дубликаты строк, либо строки с ошибками.\nСтроки с ошибками будут удалены при загрузке.',
  org_panel_popup_unfinish_event_hdr_events: 'Имеются незавершенные мероприятия:',
  bulk_user_uploading_popup_users_lbl_error_blank_fields_hint: 'Имеются незаполненные поля:',
  bulk_user_uploading_popup_grid_users_hdr_column_name: 'Имя',
  event_screen_edit_login_plh: 'Имя пользователя',
  user_card_popup_edit_edit_name: 'Имя',
  user_panel_popup_create_user_edit_name_plh: 'Имя',
  event_card_link_crib: 'Шпаргалка',
  event_card_popup_crib_hdr: 'Шпаргалка',
  event_card_popup_crib_event: 'Мероприятие',
  event_card_link_info: 'Информация',
  event_card_link_info_subtitle: 'О миссии',
  event_screen_popup_history_hdr_event_history: 'История событий',
  mission_card_lbl_mission_conditions: 'Исходные условия',
  map_close_dialog_title: 'Закрытие карты',
  map_close_dialog_question: 'Вы действительно желаете закрыть карту?',
  create_config_hdr_create_config: 'Новая визуализация',
  create_config_lbl_unsaved_files: 'При создании новой схемы визуализации, текущая будет очищена.\nВы действительно желаете очистить текущую схему?', // newIdLoc
  create_config_btn_clear: 'Очистить', // newIdLoc
  create_config_btn_cancel: 'Отменить', // newIdLoc
  event_card_link_map: 'Инфраструктура',
  mission_card_link_map: '2D Карта',
  mission_card_link_map_3d: '3D Карта',
  event_screen_popup_particip_hdr_team: 'Команда',
  scoring_report_empty_report: 'Отчёт пуст',
  scoring_report_evaluation_edit_teacher_comment_plh: 'Комментарий',
  scoring_participant_report_edit_comment: 'Комментарий преподавателю',
  scoring_report_evaluation_edit_teacher_comment: 'Комментарий преподавателя',
  scoring_report_evaluation_edit_participant_comment: 'Комментарий участника',
  scoring_report_evaluation_edit_participant_comment_plh: 'Нет комментария',
  user_authorization_edit_login_plh: 'Логин/Email',
  user_card_popup_edit_edit_login: 'Логин/Email',
  user_panel_popup_create_user_edit_login_plh: 'Логин/E-mail',
  user_panel_lst_activity_all_plh: 'Все статусы',
  mission_card_lbl_max_participants: 'макс.',
  event_card_popup_materials_hdr_manuals: 'Материалы',
  mission_card_link_manuals: 'Материалы',
  event_screen_popup_manuals_hdr_manuals: 'Материалы ',
  event_card_link_manuals: 'Материалы',
  event_card_manuals_zip: 'Материалы.zip',
  user_card_hdr_column_event: 'Мероприятие',
  org_panel_popup_unfinish_event_hdr_column_event: 'Мероприятие',
  org_panel_popup_unfinish_ev_user_hdr_column_event: 'Мероприятие',
  event_popup_event_ended_lbl_notification: 'Мероприятие завершено!',
  event_card_lbl_event_creator: 'Мероприятие создал',
  scoring_popup_settings_lst_select_event: 'Мероприятие/команда',
  navigation_panel_popup_menu_btn_lk: 'Личный кабинет',
  navigation_panel_popup_menu_btn_events: 'Мероприятия',
  events_list_lbl_duration_min: 'мин.',
  mission_card_lbl_duration_min: 'мин.',
  missions_list_lbl_duration_min: 'мин.',
  round: 'нет раундов | {n} раунд | {n} раунда | {n} раундов',
  navigation_panel_popup_menu_btn_missions: 'Миссии',
  navigation_panel_popup_menu_btn_tasks: 'Задачи',
  user_card_hdr_column_mission: 'Миссия',
  bulk_user_uploading_lst_default_tz_plh: 'Москва, Сочи(GMT+03:00)',
  event_card_hdr_observes: 'Наблюдатели',
  event_creation_editing_lbl_observes: 'Наблюдатели',
  event_creation_editing_popup_observes_lbl_number_observes: 'Наблюдатели',
  user_card_lbl_user_role_observer: 'Наблюдатель',
  user_card_lbl_user_role_auditor: 'Аудитор',
  user_card_lbl_user_role_infrastructure_architect: 'Архитектор инфраструктур',
  user_card_lbl_user_role_researcher: 'Исследователь',
  user_card_lbl_user_role_screenwriter_methodologist: 'Сценарист-методолог',
  event_creation_editing_lbl_event_name: 'Название мероприятия',
  event_editing_popup_assignment_VM_txt_reload_page: 'Назначена новая виртуальная машина. Обновите страницу.',
  event_editing_popup_assignment_VM_hdr_new_VM: 'Назначение виртуальной машины',
  scoring_popup_settings_hdr_scoreboard_settings: 'Настройка таблицы результатов',
  event_card_edit_start: 'Начнется через',
  scoring_report_evaluation_edit_protection_plh: 'Не заполнено',
  scoring_report_evaluation_edit_recovery_plh: 'Не заполнено',
  user_panel_popup_create_user_lbl_error_surname_empty: 'Не заполнено обязательно ',
  user_panel_popup_create_user_lbl_error_name_empty: 'Не заполнено обязательно ',
  user_panel_popup_create_user_lbl_error_patronymic_empty: 'Не заполнено обязательно ',
  user_panel_popup_create_user_lbl_error_login_empty: 'Не заполнено обязательно ',
  user_panel_popup_create_user_lbl_error_org_empty: 'Не заполнено обязательно ',
  user_panel_popup_create_user_lbl_error_role_empty: 'Не заполнено обязательно ',
  event_creation_editing_lbl_error_event_name_empty: 'Не заполнено обязательное поле',
  event_creation_editing_lbl_error_event_goals_empty: 'Не заполнено обязательное поле',
  event_creation_editing_lbl_error_event_participants_empty: 'Необходимо добавить хотя бы одного участника',
  event_creation_editing_lbl_upload_file_stand_error: 'Загрузите файл конфигурации стенда, либо выключите стенд!',
  event_creation_editing_lbl_slot_not_ready: 'Слот не готов! Выберите другой слот!',
  event_creation_editing_lbl_reserve: 'Резервные VM участников',
  org_panel_popup_create_org_lbl_error_blank_field: 'Не заполнено обязательное поле',
  org_panel_popup_edit_org_lbl_error_blank_field: 'Не заполнено обязательное поле',
  event_creation_editing_popup_participants_hdr_column_vm_not_assigned: 'Не назначена',
  org_panel_lst_activity_not_filter_plh: 'Не фильтровать по активности ',
  org_panel_lst_activity_inactive: 'Неактивные',
  user_panel_lst_activity_inactive: 'Неактивные ',
  bulk_user_uploading_popup_users_lbl_error_wrong_format_hint: 'Неверный формат поля',
  user_authorization_lbl_error_login_wrong: 'Необходимо ввести Логин или Email',
  user_authorization_lbl_error_password_wrong: 'Необходимо ввести пароль',
  org_panel_popup_create_org_txt_deleted_data_lost: 'Несохраненные данные будут утеряны. Вы действительно хотите закрыть окно?',
  event_card_hdr_column_status_no_report: 'Нет отчета',
  event_screen_popup_history_chk_low: 'Низкий',
  scoring_report_evaluation_tag_tech_status_new: 'Новая',
  scoring_report_evaluation_tag_ioc_status_new: 'Новый',
  event_card_hdr_column_status_new: 'Новый',
  authentication_change_pwd_lbl_new_password_plh: 'Новый пароль',
  scoring_popup_add_tech_hdr_discovered: 'Обнаружена новая техника',
  org_panel_popup_admins_hdr_detected_admins: 'Обнаружены пользователи с ролью "Администратор":',
  bulk_user_uploading_popup_users_btn_update_file: 'Обновите файл',
  event_screen_link_refresh: 'Обновить',
  event_screen_lbl_reboot: 'Осуществляется перезагрузка виртуальной машины...\nДанная операция займёт 1-2 минуты.\nПожалуйста, подождите.',
  event_card_hdr_column_updated: 'Обновлено мин. назад',
  event_card_lbl_training: 'Обучение',
  event_creation_editing_btn_training: 'Обучение',
  events_list_lbl_training: 'Обучение',
  event_card_lbl_event_status_waiting: 'Ожидание',
  event_editing_popup_assignment_VM_hdr_confirm: 'ОК',
  event_popup_event_ended_btn_confirm: 'ОК',
  event_popup_event_end_btn_confirm: 'ОК',
  auth_popup_password_change_btn_confirm: 'ОК',
  authentication_change_pwd_btn_confirm: 'ОК',
  bulk_user_uploading_popup_deleted_lines_btn_confirm: 'ОК',
  bulk_user_uploading_popup_created_users_btn_confirm: 'ОК',
  org_panel_popup_unfinish_event_btn_confirm: 'ОК',
  org_panel_popup_admins_btn_confirm: 'ОК',
  org_panel_popup_create_org_btn_confirm: 'ОК',
  org_panel_popup_edit_org_btn_confirm: 'ОК',
  user_panel_popup_create_user_btn_confirm: 'ОК',
  mission_card_lbl_mission_descr: 'Описание миссии',
  scoring_participant_report_edit_protection_desc_plh: 'Опишите методы защиты от атакующей техники',
  scoring_participant_report_edit_recovery_desc_plh: 'Опишите процедуру восстановления после применения атакующей техники',
  scoring_report_evaluation_new_report_label: 'Отчет пока не сформирован, добавьте обнаруженную технику',
  scoring_report_evaluation_btn_publish: 'Опубликовать',
  scoring_report_evaluation_btn_refresh_report: 'Обновить отчет',
  scoring_report_evaluation_btn_publish_all: 'Отправить всё',
  scoring_report_evaluation_preloader_line_1: 'Идет отправка отчета.',
  scoring_report_evaluation_preloader_line_2: 'Редактирование временно недоступно',
  scoring_report_evaluation_btn_no_change: 'Нет изменений',
  scoring_report_evaluation_btn_publishing: 'Отчет публикуется...',
  scoring_report_evaluation_btn_published: 'Отчёт отправлен',
  event_creation_editing_popup_observes_lst_org_plh: 'Организации',
  org_panel_edit_error_org_not_found: 'Организации не найдены',
  scoring_scoreboard_hdr_column_org: 'Организация',
  event_creation_editing_lst_organization: 'Организация',
  event_creation_editing_popup_observes_hdr_column_organization: 'Организация',
  bulk_user_uploading_popup_grid_users_hdr_column_organization: 'Организация',
  user_panel_hdr_column_org: 'Организация',
  org_panel_popup_admins_txt_cant_block_org: 'Организация не может быть заблокирована, так как в ней имеются активные админские записи',
  org_panel_popup_unfinish_event_txt_cant_block_org: 'Организация не может быть заблокирована, так как по ней имеются незавершенные мероприятия',
  org_panel_popup_unfinish_event_txt_cant_block_org_ext: 'Организация {org} не может быть заблокирована, так как содержит пользователей, участвующих в незавершенных мероприятиях',
  bulk_user_uploading_lst_default_organization_plh: 'Организация по умолчанию',
  org_panel_popup_edit_org_lbl_org_create_success: 'Организация успешно изменена',
  org_panel_popup_create_org_lbl_org_create_success: 'Организация успешно создана',
  org_panel_hdr_column_org: 'Организация',
  user_card_popup_edit_lst_organization: 'Организация',
  org_panel_popup_create_org_edit_org_plh: 'Организация',
  org_panel_popup_edit_org_edit_org_plh: 'Организация',
  user_panel_popup_create_user_lst_org_plh: 'Организация',
  id_scoring_popup_failed_data_txt_error: 'Не удалось получить данные отчета',
  scoring_report_evaluation_lbl_participant_comm: 'Ответ участника',
  event_creation_editing_popup_observes_btn_cancel: 'Отмена',
  event_popup_event_end_btn_cancel: 'Отмена',
  event_card_lbl_finished: 'Завершено',
  event_card_lbl_canceled: 'Отменено',
  event_card_lbl_event_status_canceled: 'Отменено',
  events_list_lbl_canceled: 'Отменено',
  event_card_btn_cancel: 'Отменить',
  event_card_btn_finish: 'Завершить',
  event_card_popup_reboot_btn_cancel: 'Отменить',
  auth_popup_password_change_btn_cancel: 'Отмена',
  user_card_popup_edit_btn_cancel: 'Отменить',
  user_panel_popup_create_user_btn_cancel: 'Отменить',
  events_list_btn_cancel: 'Отменить ',
  scoring_popup_del_tech_btn_cancel: 'Отменить',
  scoring_popup_settings_btn_cancel: 'Отменить',
  org_panel_popup_unfinish_ev_user_btn_confirm: 'Отменить',
  org_panel_popup_create_org_btn_cancel: 'Отменить',
  org_panel_popup_edit_org_btn_cancel: 'Отменить',
  scoring_popup_settings_tgl_display_places: 'Отображение мест',
  scoring_participant_report_btn_send: 'Отправить отчет',
  scoring_popup_send_report_error_txt_no_sending: 'Отправка отчета не доступна. Идет подведение итогов или мероприятие завершено',
  scoring_participant_report_btn_not_send: 'Отправка недоступна',
  scoring_participant_report_btn_not_send_hint: 'Идет подведение итогов\nили мероприятие завершено',
  bulk_user_uploading_popup_grid_users_hdr_column_patronymic: 'Отчество',
  user_card_popup_edit_edit_patronymic: 'Отчество',
  user_panel_popup_create_user_edit_patronymic_plh: 'Отчество',
  event_card_link_report: 'Отчет',
  event_card_link_participant_report: 'Отчет участника',
  event_card_link_participant_report_subtitle: 'О ходе атаки',
  scoring_participant_report_hdr_breadcrumbs: 'Отчет участника ',
  event_card_hdr_column_reports: 'Отчеты',
  event_card_hdr_column_status_rated: 'Оценён',
  event_card_hdr_column_status_rating: 'На оценке',
  scoring_report_evaluation_lbl_ioc_not_matched: '* Нет совпадения!\nIoC из другой техники',
  scoring_report_evaluation_lbl_teacher_comm: 'Оценки и комментарии преподавателя',
  event_card_lbl_error: 'Ошибка',
  events_list_lbl_error: 'Ошибка',
  user_authorization_lbl_error: 'Ошибка',
  events_list_lbl_errors: 'Ошибки',
  authentication_change_pwd_lbl_error_different_passwords: 'Пароли не совпадают',
  auth_change_pwd_lbl_password_changed: 'Пароль был успешно изменен!',
  auth_change_pwd_btn_confirm: 'ОК',
  user_authorization_edit_password_plh: 'Пароль',
  user_authorization_chk_accept_eula: 'Я принимаю условия',
  user_authorization_chk_accept_eula_link: 'пользовательского соглашения',
  user_authorization_chk_accept_eula_title: 'Соглашение об условиях использования',
  eula_btn_go_to_login: 'Перейти к авторизации',
  bulk_user_uploading_popup_grid_users_hdr_column_password: 'Пароль',
  event_screen_edit_password_plh: 'Пароль',
  authentication_change_pwd_lbl_password_changed: 'Пароль был успешно изменен',
  event_card_lbl_pause_state: 'Пауза',
  event_card_lbl_pause: 'Пауза в конце раунда',
  event_card_popup_reboot_btn_confirm: 'Перезагрузить',
  event_card_popup_reboot_hdr_vm: 'Перезагрузка виртуальной машины',
  auth_popup_password_change_lbl_confirmation_letter: 'Письмо с инструкциями по восстановлению доступа отправлено на адрес',
  authentication_change_pwd_lbl_repeat_password_plh: 'Повторите пароль',
  event_card_btn_prepare: 'Подготовить',
  events_list_btn_prepare: 'Подготовить',
  event_card_lbl_event_status_preparation: 'Подготовка',
  events_list_lbl_preparation: 'Подготовка',
  event_screen_popup_hint_hdr_hint: 'Подсказка ',
  scoring_popup_settings_btn_confirm: 'Подтвердить',
  event_creation_editing_popup_observes_edit_search_observes_plh: 'Поиск наблюдателей',
  org_panel_edit_search_org: 'Поиск организации',
  missions_list_lbl_search_mission: 'Поиск миссии',
  user_panel_edit_search_user: 'Поиск пользователя',
  event_creation_editing_popup_participants_edit_search_participants_plh: 'Поиск участников',
  events_list_btn_show: 'Показать',
  event_card_log_lbl_polygon: 'Полигон',
  event_creation_editing_lbl_polygon: 'Полигон',
  missions_list_lbl_polygon: 'Полигон:',
  event_card_btn_get_report: 'Получить отчет',
  events_list_btn_get_report: 'Получить отчет',
  event_screen_popup_phase_task_btn_get_hint: 'Получить Подсказку',
  org_panel_popup_unfinish_ev_user_txt_user_cant_enter: 'Пользователи не смогут войти в систему пока организация заблокирована. Вы действительно хотите заблокировать организацию?',
  user_panel_lst_error_user_not_found: 'Пользователи не найдены. Попробуйте сбросить фильтры',
  bulk_user_uploading_popup_users_lbl_error_user_hint: 'Пользователь уже существует в Системе',
  bulk_user_uploading_popup_users_lbl_error_org_hint: 'Организация не существует в Системе',
  scoring_participant_report_edit_last_sent: 'Последний отчет отправлен',
  event_card_log_lbl_teacher: 'Преподаватель',
  events_list_lbl_teacher: 'Преподаватель',
  user_card_lbl_user_role_teacher: 'Преподаватель',
  user_panel_lst_role_teacher: 'Преподаватель ',
  event_creation_editing_lst_error_no_teacher: 'Преподаватели для данной организации еще не определены',
  scoring_participant_report_edit_comment_plh: 'При необходимости укажите дополнительные сведения об обнаруженной технике и комментарии для преподавателя',
  event_screen_lbl_error_console: 'При получении консоли произошла ошибка',
  event_card_btn_view: 'Посмотреть',
  event_card_btn_join: 'Присоединиться',
  event_card_btn_connect: 'Подключиться',
  event_card_btn_connect_hint: 'Подключиться к виртуальному рабочему столу',
  event_creation_editing_lbl_mission_time_hint: 'Продолжительность мероприятия',
  authentication_change_pwd_lbl_uppercase_letters: 'Прописные латинские буквы',
  user_card_hdr_user_profile: 'Профиль пользователя',
  user_authorization_lbl_rostelecom: 'Разработано в Ростелеком',
  event_creation_editing_popup_participants_btn_assign_vm: 'Распределить VM',
  event_creation_editing_crumbs_edit_event: 'Редактирование мероприятия',
  org_panel_popup_edit_org_hdr_create_org: 'Редактирование организации',
  user_card_popup_edit_hdr_edit_user: 'Редактирование пользователя',
  scoring_scoreboard_hdr_results: 'Результаты',
  bulk_user_uploading_popup_grid_users_hdr_column_role: 'Роль',
  user_panel_hdr_column_role: 'Роль ',
  user_card_popup_edit_lst_role: 'Роль',
  user_panel_popup_create_user_lst_role_plh: 'Роль',
  user_authorization_lst_choose_language_rus: 'Русский',
  user_card_lbl_reset_pwd: 'Сбросить пароль',
  user_card_popup_edit_edit_reset_pwd: 'Сбросить пароль',
  teacher_report_new_user_version_title: 'Отчет участника устарел!',
  teacher_report_new_user_version_content: 'Для обновления опубликуйте данную оценку.',
  teacher_report_new_user_version_exists: 'Имеются обновления по отчету',
  event_card_popup_materials_btn_download: 'Скачать все',
  event_screen_popup_manuals_btn_download_all: 'Скачать все',
  event_card_btn_download: 'Скачать оценки',
  event_card_btn_download_lms: 'Выгрузить LMS',
  event_card_btn_download_report: 'Скачать отчет',
  scoring_report_evaluation_btn_refresh: 'Обновить данные',
  scoring_report_evaluation_btn_download: 'Скачать эталон',
  event_creation_editing_link_download_report: 'Скачать эталонный отчет',
  event_creation_editing_lbl_scoring_off: 'Скоринг отключен, оценка результатов и навыков производиться не будет',
  events_list_btn_all: 'Все',
  events_list_btn_wo_error: 'Без ошибок',
  events_list_btn_w_error: 'Ошибки',
  event_card_lbl_complexity: 'Сложность',
  mission_cnstrctr_error_hint_title: 'Вся информация содержится во вкладке \'Проверка\'',
  mission_card_lbl_complexity: 'Сложность',
  mission_card_lbl_import: 'Импорт',
  mission_card_lbl_export: 'Экспорт',
  mission_card_popup_import_hdr_mission: 'Импорт миссии',
  mission_card_popup_import_txt_successful: 'Файлы конфигурации успешно импортированы.',
  mission_card_popup_import_txt_error: 'Невозможно импортировать миссию.',
  mission_card_popup_import_txt_error413: 'Ошибка! Файл слишком большой<br>Размер файла не должен превышать 50 Мбайт<br>(52 428 800 байт)',
  authentication_change_pwd_hdr_change_password: 'Смена пароля',
  authentication_change_pwd_hdr_changed_password: 'Изменение пароля',
  user_card_hdr_records: 'События',
  event_creation_editing_lbl_personal: 'Личный зачет',
  event_creation_editing_crumbs_create_event: 'Создание мероприятия',
  event_creation_editing_lbl_missions_not_found: 'Миссии не найдены',
  org_panel_popup_create_org_hdr_create_org: 'Создание организации',
  user_panel_popup_create_user_hdr_creation: 'Создание пользователя',
  bulk_user_uploading_popup_created_users_lbl_created_users: 'Создано пользователей:',
  scoring_popup_add_tech_btn_create: 'Создать',
  user_panel_btn_create: 'Создать ',
  missions_list_btn_clone_mission: 'Клонировать миссию',
  org_panel_btn_create_org: 'Создать организацию',
  org_panel_popup_create_org_btn_create: 'Создать',
  event_creation_editing_popup_observes_btn_save: 'Сохранить',
  user_card_popup_edit_edit_save: 'Сохранить',
  user_panel_popup_create_user_btn_save: 'Сохранить',
  org_panel_popup_create_org_btn_save: 'Сохранить',
  org_panel_popup_edit_org_btn_save: 'Сохранить',
  event_screen_popup_history_chk_medium: 'Средний ',
  event_card_hdr_column_status: 'Статус',
  event_card_lbl_event_status: 'Статус',
  user_card_lbl_user_status_plh: 'Статус',
  user_card_popup_edit_edit_status_plh: 'Статус',
  user_panel_popup_create_user_edit_status_plh: 'Статус',
  authentication_change_pwd_lbl_lowercase_letters: 'Строчные латинские буквы',
  scoring_scoreboard_hdr_results_table: 'Таблицы результатов',
  navigation_panel_popup_menu_btn_scoreboard: 'Табло результатов',
  navigation_panel_popup_menu_btn_dashboard: 'Дашборд',
  event_screen_hint_btn_participants_list: 'Список участников',
  event_screen_hint_btn_history_log: 'История событий',
  event_screen_hint_btn_tasks: 'Задачи этапа в миссии',
  navigation_panel_popup_menu_btn_event: 'Карточка мероприятия',
  navigation_panel_popup_menu_btn_visualization_editor: 'Редактор визуализации',
  navigation_panel_popup_menu_btn_3d_map: 'Редактор 3D карты',
  event_screen_hint_btn_materials: 'Материалы миссии',
  event_screen_hint_btn_topology: '2D Визуализация',
  event_screen_hint_btn_topology_3d: '3D Визуализация',
  event_screen_hint_btn_scoreboard: 'Табло результатов участников',
  event_screen_hint_btn_participants_report: 'Отчет участника',
  event_screen_popup_phase_task_hdr_current_task: 'Текущая задача',
  scoring_popup_add_tech_lst_select_attack_type: 'Тип атаки',
  event_creation_editing_lbl_event_type: 'Тип мероприятия',
  authentication_change_pwd_hdr_requirements: 'Требования к паролю',
  org_panel_popup_unfinish_ev_user_hdr_events: 'У пользователей имеются незавершенные мероприятия',
  scoring_popup_del_tech_hdr_delete: 'УДАЛЕНИЕ ОТЧЕТА ПО ТЕХНИКЕ',
  bulk_user_uploading_popup_deleted_lines_lbl_number_deleted: 'Удалены лишние строки и записи с ошибками: ',
  scoring_popup_del_tech_btn_del_tech: 'Удалить',
  org_panel_popup_create_org_btn_delete: 'Удалить',
  user_card_lbl_user_role_participant: 'Участник',
  user_panel_lst_role_participant: 'Участник',
  event_creation_editing_popup_participants_lbl_number_participants: 'Участники',
  event_card_hdr_participants: 'Участники защиты',
  event_card_lbl_exercises: 'Учения',
  event_creation_editing_btn_exercises: 'Учения',
  events_list_lbl_exercises: 'Учения',
  user_panel_popup_create_user_lbl_created_success: 'Учетная запись успешно создана!',
  bulk_user_uploading_popup_grid_users_hdr_column_surname: 'Фамилия',
  user_card_popup_edit_edit_surname: 'Фамилия',
  user_panel_popup_create_user_edit_surname_plh: 'Фамилия',
  org_panel_lst_all_statuses: 'Все статусы',
  org_panel_lst_filters: 'Фильтры',
  user_panel_lst_filters: 'Фильтры',
  event_card_hdr_column_full_name: 'ФИО',
  event_creation_editing_popup_observes_hdr_column_full_name: 'ФИО',
  org_panel_popup_admins_hdr_column_full_name: 'ФИО',
  org_panel_popup_unfinish_ev_user_hdr_column_full_name: 'ФИО',
  user_panel_hdr_column_full_name: 'ФИО ',
  event_creation_editing_edit_event_goals: 'Цели и задачи мероприятия',
  event_card_lbl_goals: 'Цели и задачи',
  authentication_change_pwd_lbl_lowercase_numbers: 'Цифры',
  user_card_popup_edit_lst_tz: 'Часовой пояс',
  user_panel_popup_create_user_lst_tz_plh: 'Часовой пояс',
  event_creation_editing_btn_noises: 'Шумы',
  mission_card_lst_phase: 'Этап',
  event_screen_popup_phase_task_lbl_phase: 'Этап ',
  event_screen_popup_hint_lbl_phase: 'Этап',
  user_state_OFFLINE: 'Оффлайн',
  user_state_ONLINE: 'Онлайн',
  user_state_CONNECTED: 'Подключен',
  ///
  cf_event_creation_editing_crumbs_create_event: 'Создание мероприятия по захвату флага',
  ad_event_creation_editing_crumbs_create_event: 'Создание мероприятия по противостоянию',
  cf_event_creation_editing_crumbs_edit_event: 'Редактирование мероприятия по захвату флага',
  ad_event_creation_editing_crumbs_edit_event: 'Редактирование мероприятия по противостоянию',
  cf_event_creation_editing_popup_delete_team_hdr_delete: 'Удаление команды',
  cf_event_creation_editing_popup_delete_team_txt_delete: 'Вы действительно желаете удалить введенные данные?',
  cf_event_creation_editing_lbl_targets: 'Цели (uri)',
  ad_event_creation_editing_lbl_targets: 'Защищаемые сервисы',
  cf_event_creation_editing_edit_targets_plh: 'Введите цели в виде набора строк URI <ip-хоста>/<путь к файлу>. Примеры:\n' +
    '10.{{octet}}.12.1/etc/sysctl.conf\n' +
    '11.{{octet}}.32.107/c:\\WINDOWS\\file.txt\n' +
    '192.168.17.14/tmp/hasd677823476.sig',
  ad_event_creation_editing_edit_targets_plh: 'Введите цели в виде набора строк URI сервисов <ip-хоста>:<порт>. Примеры:\n' +
    '10.{{octet}}.12.1:80\n' +
    '10.1.12.32:80\n' +
    '192.168.1.14:22\n',
  cf_event_card_lbl_flag: 'Флаг',
  cf_event_card_hdr_column_credited: 'Засчитан',
  cf_event_card_hdr_column_attacker: 'Атакующий',
  cf_event_card_hdr_column_victim: 'Жертва',
  cf_event_card_hdr_column_flag: 'Флаг установлен',
  cf_event_card_hdr_column_uri: 'Цель (URI)',
  cf_event_card_btn_publish: 'Опубликовать',
  ad_event_card_btn_publish: 'Применить корректировку',
  cf_event_card_btn_card: 'Карточка',
  cf_event_card_btn_arbitration: 'Арбитраж',
  ad_event_card_btn_services: 'Сервисы',
  cf_event_creation_editing_lbl_uncorrect_time: 'Время начала должно быть больше текущего времени',
  ad_event_creation_editing_edit_service_unavailability: 'Отсчет недоступности сервиса, мин',
  ad_event_creation_editing_edit_starting_score: 'Начальное количество баллов',
  cf_event_card_hdr_column_command: 'Команда', // newIdLoc
  cf_event_card_hdr_column_attack: 'Атака, баллы',
  cf_event_card_hdr_column_defense: 'Защита, баллы',
  cf_event_card_hdr_column_total: 'Всего, баллы',
  ad_event_card_hdr_column_command: 'Команда',
  ad_event_card_hdr_column_override: 'Корректировка, баллы',
  cf_event_card_p_hdr_your_team: 'Ваша команда',
  cf_event_card_p_hdr_targets: 'Ваши цели',
  ad_event_card_p_hdr_services: 'Ваши защищаемые сервисы:',
  cf_event_card_p_hdr_column_victim: 'Команда жертва',
  cf_event_card_p_hdr_column_uri: 'Цель (URI)',
  ad_event_card_btn_verification_cheker: 'Запустить чекер',
  ad_event_card_hdr_column_team: 'Команда',
  ad_event_card_hdr_column_service: 'Сервис',
  ad_event_card_hdr_column_state: 'Состояние',
  ad_event_card_hdr_column_verification: 'Проверка',
  cf_scoreboard_hdr_column_team: 'Команда',
  cf_scoreboard_hdr_column_attack: 'Атака',
  cf_scoreboard_hdr_column_defense: 'Защита',
  cf_scoreboard_hdr_column_total: 'Всего',
  cf_event_creation_editing_edit_team_name_plh: 'Введите название команды',

  cf_event_popup_hdr_debriefing: 'ПОДВЕДЕНИЕ ИТОГОВ',
  cf_event_popup_lbl_debriefing: 'Вы действительно хотите подвести итоги?',
  cf_event_popup_btn_cancel: 'Отмена',
  cf_event_popup_btn_debrief: 'Подвести итоги',

  cf_popup_can_hdr_cancel: 'ОТМЕНА МЕРОПРИЯТИЯ',
  cf_popup_can_lbl_cancel: 'Вы желаете отменить мероприятие?',
  cf_popup_can_btn_cancel: 'ОТМЕНА',
  cf_popup_can_btn_cancel_app: 'ПОДТВЕРДИТЬ',

  cf_popup_fin_hdr_finish: 'ЗАВЕРШЕНИЕ МЕРОПРИЯТИЯ',
  cf_popup_fin_lbl_finish: 'Вы желаете завершить мероприятие?',
  cf_popup_fin_btn_cancel: 'ОТМЕНА',
  cf_popup_fin_btn_finish: 'ЗАВЕРШИТЬ',
  ///
  mission_cnstrctr_crumbs_constructor: 'Конструктор',
  mission_cnstrctr_tab_mission: 'Миссия',
  mission_cnstrctr_tab_scenario: 'Сценарий',
  mission_cnstrctr_tab_configuration: 'Конфигурация',
  mission_cnstrctr_tab_reference: 'Эталон',
  mission_cnstrctr_tab_noises: 'Шумы',
  mission_cnstrctr_tab_verification: 'Проверка',
  mission_cnstrctr_mission_edit_name: 'Название миссии',
  mission_cnstrctr_mission_edit_name_plh: 'Введите публичное название миссии',
  mission_cnstrctr_mission_rating_complexity: 'Сложность',
  mission_cnstrctr_mission_tag_empty: 'Пусто',
  mission_cnstrctr_mission_edit_goals: 'Цели миссии',
  mission_cnstrctr_mission_edit_description: 'Описание миссии',
  mission_cnstrctr_mission_edit_comment: 'Комментарий',
  mission_cnstrctr_mission_hdr_vizualization: '2D Визуализация',
  mission_cnstrctr_mission_hdr_vizualization_3d: '3D Визуализация',
  mission_cnstrctr_mission_hdr_map: 'Карта инфраструктуры',
  mission_cnstrctr_mission_hdr_manuals: 'Материалы',
  mission_cnstrctr_mission_uploading_btn_select_file: 'Выберите файл',
  mission_cnstrctr_mission_remove_file_popup_title: 'Удаление файла',
  mission_cnstrctr_mission_file_size_warning: 'Выберите {type}файл размером не более 50 Мб',
  mission_cnstrctr_mission_remove_file_popup: 'Вы уверены, что желаете удалить файл из миссии?',
  mission_cnstrctr_mission_edit_goals_plh: 'Введите цели миссии',
  mission_cnstrctr_mission_edit_description_plh: 'Введите описание миссии',
  mission_cnstrctr_mission_edit_comment_plh: 'Напишите комментарий',
  mission_cnstrctr_mission_lbl_not_specified: 'Не задано',
  mission_cnstrctr_reference_lbl_scoring_reference: 'Справочник',
  mission_cnstrctr_reference_btn_upload: 'Загрузить справочник',
  mission_cnstrctr_reference_lbl_errors: 'Загрузите файл справочника',
  mission_cnstrctr_reference_hint_errors: 'Обнаружены ошибки',
  mission_cnstrctr_reference_btn_download_scoring_reference: 'Скачать справочник',
  mission_cnstrctr_reference_btn_delete_scoring_reference: 'Удаление справочника',
  mission_cnstrctr_reference_scoring_reference_error: 'Справочник содержит ошибки!',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_hdr_error_upload_scoring_reference: 'Ошибка загрузки справочника техник',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_lbl_error_upload_scoring_reference: 'Справочник содержит ошибки и не был загружен! Проверьте, что справочник соответствует эксплуатационной документации.',
  mission_cnstrctr_reference_popup_error_upload_scoring_reference_btn_error_upload_scoring_reference_ok: 'OK',
  mission_cnstrctr_reference_lbl_need_upload: 'Для создания эталонного отчета необходимо загрузить справочник',
  mission_cnstrctr_reference_hdr_column_name_ioc_plh: 'Выберите новый IoC...',
  mission_cnstrctr_reference_hdr_column_name_ioc: 'Индикатор (IOC)',
  mission_cnstrctr_reference_hdr_column_type_ioc: 'Тип IOC',
  mission_cnstrctr_reference_hdr_column_points: 'Очки',
  mission_cnstrctr_reference_hdr_column_hint: 'Regex / Подсказка',
  mission_cnstrctr_reference_hdr_column_skills: 'Навыки',
  mission_cnstrctr_reference_lbl_ref_loaded_first_line: 'Справочник загружен,',
  mission_cnstrctr_reference_lbl_ref_loaded_second_line: 'для продолжения работы добавьте технику',
  mission_cnstrctr_mission_remove_tech_popup_title: 'Удалить технику',
  mission_cnstrctr_mission_remove_tech_popup_txt: 'Вы уверены, что желаете удалить выбранную технику?',
  mission_cnstrctr_reference_btn_delete_scoring_reference_line: 'Удаление справочника приведет к очистке эталонного отчета. Вы уверены, что хотите удалить справочник?',
  mission_cnstrctr_reference_popup_upload_scoring_reference_hdr_upload: 'Загрузка справочника',
  mission_cnstrctv_reference_popup_upload_scoring_reference_txt_warning: 'Вы уверены, что хотите загрузить новый справочник?\nЭто приведет к удалению текущего справочника и очистке эталонного отчета.',
  mission_cnstrctr_reference_chk_skeletal: 'Скелетная',
  mission_cnstrctr_scenario_popup_delete_script_hdr_delete: 'Удаление скрипта',
  mission_cnstrctr_scenario_popup_delete_script_txt: 'Вы уверены, что хотите удалить скрипт из сценария?',
  mission_cnstrctr_scenario_hdr_phases: 'Этапы',
  mission_cnstrctr_scenario_hdr_phases_plh: 'Добавьте этап, нажав кнопку [+]',
  mission_cnstrctr_scenario_link_scripts: 'Скрипты',
  mission_cnstrctr_scenario_link_scripts_plh1: 'Нет выбранного этапа.',
  mission_cnstrctr_scenario_link_scripts_plh2: 'Скрипт добавить нельзя.',
  mission_cnstrctr_scenario_link_scripts_no_data: 'Отсутствуют данные',
  mission_cnstrctr_scenario_btn_command: 'Команда',
  mission_cnstrctr_scenario_btn_command_plh: 'Нет добавленных скриптов, добавьте скрипт',
  mission_cnstrctr_scenario_hdr_column_script: 'Скрипт',
  mission_cnstrctr_scenario_hdr_column_attacking: 'Атакующий',
  mission_cnstrctr_scenario_hdr_column_key: 'К',
  mission_cnstrctr_scenario_hdr_column_type: 'Тип',
  mission_cnstrctr_scenario_hdr_column_tags: 'Теги',
  mission_cnstrctr_scenario_hdr_script: 'Скрипт',
  mission_cnstrctr_scenario_lbl_script_plh: 'Выберите скрипт\nв центральной панели',
  mission_cnstrctr_scenario_edit_script_name: 'Название',
  mission_cnstrctr_scenario_edit_rounds: 'Длительность раунда(ов)',
  mission_cnstrctr_scenario_edit_attacking_vm: 'Атакующий (vmID)',
  mission_cnstrctr_scenario_edit_target_name: 'Имя цели',
  mission_cnstrctr_scenario_btn_key_attack: 'Ключевая атака',
  mission_cnstrctr_scenario_edit_executable_module: 'Исполняемый модуль (bin)',
  mission_cnstrctr_scenario_edit_command_line: 'Командная строка',
  mission_cnstrctr_scenario_scripts_list_field_search_plh: 'Введите ключевые слова',
  mission_cnstrctr_scenario_popup_create_phase_hdr_create: 'Создание этапа',
  mission_cnstrctr_scenario_popup_edit_phase_hdr_edit: 'Редактирование этапа',
  mission_cnstrctr_scenario_popup_clone_phase_hdr_edit: 'Клонирование этапа',
  mission_cnstrctr_scenario_popup_create_phase_edit_name: 'Название этапа',
  mission_cnstrctr_scenario_popup_create_phase_edit_name_plh: 'Название этапа отображается участникам',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds: 'Длительность этапа, раунд(ов):',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds_d: 'Длительность этапа',
  mission_cnstrctr_scenario_popup_create_phase_edit_rounds_r: 'раунд(ов)',
  mission_cnstrctr_scenario_popup_create_phase_edit_descr: 'Описание этапа',
  mission_cnstrctr_scenario_popup_create_phase_edit_descr_plh: 'Описание отображается участникам',
  mission_cnstrctr_scenario_popup_create_phase_edit_hint: 'Подсказка',
  mission_cnstrctr_scenario_popup_create_phase_edit_hint_plh: 'Подсказка доступна участникам мероприятия',
  mission_cnstrctr_scenario_popup_create_phase_edit_name_error: 'Введите уникальное название этапа',
  mission_cnstrctr_scenario_popup_create_script_edit_name_error: 'Введите уникальное название скрипта',
  mission_cnstrctr_scenario_popup_delete_phase_hdr_delete: 'Удаление этапа',
  mission_cnstrctr_scenario_popup_delete_phase_lbl_warning: 'Вы уверены, что хотите удалить этап?',
  mission_cnstrctr_scenario_script_detail_target_plh: 'Отображается в истории событий',
  mission_cnstrctr_scenario_script_detail_directory_plh: 'Укажите абсолютный путь к каталогу',
  mission_cnstrctr_scenario_script_detail_bin_plh: 'Укажите имя и путь (например: cmd.exe)',
  mission_cnstrctr_validation_check_bin: 'Проверить',
  mission_cnstrctr_validation_check_error: 'Обнаружены ошибки! Миссия не опубликована',
  mission_cnstrctr_unsaved_changes: 'Имеются неопубликованные изменения',
  mission_cnstrctr_lbl_mission_saved: 'Миссия опубликована',
  mission_cnstrctr_lbl_mission_errors: 'Ошибок',
  mission_cnstrctr_lbl_mission_warnings: 'предупреждений',
  mission_cnstrctr_verification_lbl_errors: 'Миссия содержит ошибки!',
  mission_cnstrctr_noises_hdr_code: 'YAML-код',
  mission_cnstrctr_btn_export: 'Экспорт',
  mission_cnstrctr_btn_import: 'Импорт',
  mission_cnstrctr: {
    scenario: {
      lst: {
        configuration: 'Слот'
      },
      edit: {
        timeout: 'Таймаут',
        sec: 'сек'
      },
      btn: {
        clear: 'Очистить'
      }
    }
  },
  ///
  user_creation_editing_popup_delete_txt_data_lost: 'Вы действительно желаете отменить',
  user_creation_editing_popup_delete_txt_data_lost_create: 'создание пользователя?',
  user_creation_editing_popup_delete_txt_data_lost_edit: 'редактирование данных?',
  user_creation_editing_popup_create_data_unsaved_data: 'Все введенные данные будут утеряны.',
  user_creation_editing_popup_edit_data_unsaved_data: 'Несохраненные данные будут утеряны.',
  user_creation_editing_popup_delete_btn_yes: 'Да',
  user_creation_editing_popup_delete_btn_no: 'Нет',
  org_creation_editing_popup_delete_txt_data_lost_create: 'создание организации?',
  org_creation_editing_popup_delete_txt_data_lost_edit: 'редактирование организации?',
  event_creation_editing_popup_delete_txt_data_lost_create: 'создание мероприятия?',
  missions_list_add_btn: 'Создать миссию',
  missions_list_create_mission: 'Создание миссии',
  missions_list_clone_mission: 'Клонирование миссии',
  missions_list_create_mission_name: 'Название миссии',
  missions_list_create_mission_plh: 'Введите название миссии',
  missions_list_create_mission_error_already_exist: 'Миссия с таким именем уже существует',
  records_found: 'Найдено записей',
  scripts_list_breadcrumbs_admin: 'Администрирование',
  scripts_list_breadcrumbs_lib: 'Библиотека скриптов',
  scripts_list_field_search_label: 'Поиск скриптов',
  scripts_list_field_search_plh: 'Введите ключевые слова через пробел и нажмите ввод',
  scripts_list_add_btn: 'Добавить скрипт',
  scripts_list_loading: 'Загрузка скриптов',
  scripts_list_no_data: 'Скрипты не найдены',
  scripts_list_empty_0: 'В библиотеке отсутствуют скрипты.',
  scripts_list_empty_1: 'По Вашему запросу ничего не найдено, попробуйте смягчить критерии поиска.',
  scripts_list_empty_2: 'Проверьте опечатки в ключевых словах.',
  scripts_list_hdr_column_name: 'Название',
  scripts_list_hdr_column_type: 'Тип',
  scripts_list_hdr_column_tags: 'Ключевые слова',
  scripts_list_hdr_column_update_user: 'Изменил',
  scripts_list_hdr_column_update_date: 'Изменено',
  script_popup_hdr_create: 'Добавление скрипта',
  script_popup_hdr_edit: 'Редактирование скрипта',
  script_popup_hdr_create_title: 'Скрипт успешно создан',
  script_popup_hdr_edit_title: 'Скрипт успешно изменен',
  script_popup_field_name: 'Название',
  script_popup_field_type: 'Тип',
  script_popup_field_tags: 'Ключевые слова',
  script_popup_field_bin: 'Исполняемый модуль (bin)',
  script_popup_field_command: 'Командная строка',
  script_popup_field_target: 'Целевой каталог',
  script_popup_field_description: 'Описание',
  script_popup_field_name_plh: 'Введите название скрипта (не более 50 символов)...',
  script_popup_field_tags_plh: 'Введите ключевые слова для поиска, например: Атака Windows Drupal SQLi CVE-12345-1234',
  script_popup_field_bin_plh: 'Путь и имя исполняемого модуля, выполняющего командную строку на атакующей машине',
  script_popup_field_command_plh: 'Задайте типовую командную строку, выполняющую скрипт, допускаются макросы {{}}...',
  script_popup_field_target_plh: 'Укажите каталог на целевой машине в котором будет размещен скрипт...',
  script_popup_field_description_plh: 'Введите описание скрипта, что он делает и как его использовать (в т.ч. входные параметры)',
  script_popup_field_modules: 'Модули',
  script_popup_field_upload_description: 'Добавьте исполняемые модули (файлы) в скрипт',
  script_popup_uploading_btn_select_file: 'Выберите файлы',
  script_popup_uploading_lbl_drag_file: 'или перетащите их сюда',
  script_popup_uploading_lbl_drag_file_mission: 'Перетащите сюда или',
  script_popup_btn_cancel: 'Отменить',
  script_popup_btn_save: 'Сохранить',
  script_remove_popup_title: 'Удалить скрипт',
  script_remove_popup_txt: 'Вы действительно желаете удалить\nданный скрипт из системы?',
  script_remove_popup_ok_btn: 'Удалить',
  script_remove_popup_cancel_btn: 'Отменить',
  script_remove_module_popup_title: 'Удалить модуль',
  script_remove_module_popup: 'Вы действительно желаете удалить данный модуль из системы?',
  script_types_all: 'Все',
  script_type_attack: 'Атака',
  script_type_dobrobot: 'Добробот',
  script_type_noise: 'Шум',
  script_type_command: 'Команда',
  ///
  user_panel_popup_create_user_btn_continue: 'Продолжить',
  user_panel_popup_create_user_btn_delete: 'Удалить',
  user_panel_popup_create_user_txt_unsaved_data: 'Несохраненные данные будут утеряны. Вы действительно хотите закрыть окно?',
  event_creation_editing_popup_delete_event_hdr: 'Подтверждение',
  event_creation_editing_popup_delete_event_txt_data_lost: 'Несохраненные данные будут утеряны. Вы действительно хотите закрыть окно?',
  event_creation_editing_popup_delete_event_btn_delete: 'Закрыть',
  event_creation_editing_popup_change_org_hdr_change: 'Изменение организации',
  event_creation_editing_popup_change_org_txt_reset_users: 'Выбранные участники будут сброшены.',
  event_creation_editing_popup_change_org_txt_reset_users_second: 'Вы хотите изменить организацию?',
  event_creation_editing_popup_change_org_btn_cancel: 'Отмена',
  event_creation_editing_popup_change_org_btn_change: 'Изменить',
  event_creation_editing_popup_creation_hdr_event: 'Создание мероприятия',
  event_creation_editing_popup_creation_btn_confirm: 'ОК',
  user_card_popup_edit_txt_error_correct_email: 'Введенное значение должно быть корректным email',
  user_panel_popup_create_user_lbl_error_email: 'Пользователь с таким email уже существует',
  event_card_hdr_stop_event: 'Остановка мероприятия',
  event_card_hdr_stop_event_sure: 'Вы действительно хотите остановить мероприятие?',
  event_card_hdr_cancel_event: 'Отмена мероприятия',
  event_card_hdr_cancel_event_sure: 'Вы действительно хотите отменить мероприятие?',
  event_card_btn_cancel_no: 'Нет',
  event_card_btn_cancel_yes: 'Да',
  event_card_hdr_column_reboot_vm_hint: 'Перезагрузить машину участника',
  event_creation_editing_popup_delete_event_txt_data_lost_first: 'Несохраненные данные будут утеряны.',
  event_creation_editing_popup_delete_event_txt_data_lost_next: 'Вы действительно хотите закрыть окно?',
  event_creation_editing_popup_error_edit_lbl_back_list_events: 'Вернуться к списку мероприятий',
  user_panel_lst_user_loading: 'Загрузка пользователей',
  event_creation_editing_lbl_error_fio_chrctrs: 'Введенное значение содержит недопустимый символ',
  event_creation_editing_lbl_error_event_name_chrctrs: 'Введенное значение не должно быть более 255 символов',
  event_creation_editing_lbl_error_event_goals_chrctrs: 'Введенное значение не должно быть более 3000 символов',
  event_creation_editing_lbl_error_event_vms: 'Не найдены виртуальные машины',
  event_creation_editing_popup_error_edit_hdr_event_stop: 'Мероприятие было завершено либо отменено',
  bulk_user_uploading_popup_users_lbl_error_file_format: 'Неверный формат файла (ожидается <b>XLSX</b>) или превышен макс. размер файла или в файле отсутствуют обязательные поля (см.документацию)',
  bulk_user_uploading_popup_users_org_and_time_not_selected: 'Выберите организацию и часовой пояс',
  bulk_user_uploading_popup_time_not_selected: 'Выберите часовой пояс',
  bulk_user_uploading_popup_users_org_not_selected: 'Выберите организацию',
  auth_popup_password_change_txt_get_instruction_first: 'Вам будет выслана инструкция по восстановлению доступа',
  auth_popup_password_change_txt_get_instruction_next: 'Укажите ваш адрес электронной почты',
  ///
  infra_popup_log_crumbs_administration: 'Администрирование',
  infra_popup_log_crumbs_infrastructures: 'Инфраструктуры',
  infra_popup_log_btn_download_log: 'Скачать log',
  infra_popup_deploy_hdr_deploy: 'Развертывание инфраструктуры',
  infra_popup_deploy_lbl_slot: 'Cлот',
  infra_popup_deploy_lbl_purpose: 'Назначение',
  infra_popup_deploy_lbl_purpose_plh: 'Введите назначение или цели развертывания данной инфраструктуры ',
  infra_popup_deploy_lst_playbook_infra: 'Плейбук инфраструктуры',
  infra_popup_deploy_lbl_playbook_infra_plh: 'Выберите плейбук...',
  infra_popup_deploy_hint_descr: 'Описание',
  infra_popup_deploy_hint_error: 'Ошибка!',
  infra_popup_deploy_hint_error_missed: 'Для обязательного параметра требуется задать значение',
  infra_popup_deploy_hdr_column_parameter: 'Параметр',
  infra_popup_deploy_hdr_column_value: 'Значение',
  infra_popup_deploy_lbl_choose_playbook: 'Выберите плейбук инфраструктуры...',
  infra_popup_deploy_hint_descr_parameter: 'Описание параметра плейбука',
  infra_popup_deploy_lst_playbook_vm: 'Плейбук VM участника',
  infra_popup_deploy_lst_playbook_vm_plh: 'Выберите плейбук...',
  infra_popup_deploy_edit_amount_vm: 'Кол-во VM участников',
  infra_popup_deploy_hint_error_vm: 'Ошибка!',
  infra_popup_deploy_hint_error_vm_value: 'Значение должно быть целым и лежать в диапазоне от 0 по 999',
  infra_popup_deploy_btn_cancel: 'Отмена',
  infra_popup_deploy_btn_create: 'Создать',
  infra_popup_deploy_confirm_hdr_deploy: 'Развертывание инфраструктуры',
  infra_popup_deploy_confirm_txt_deploy: 'Вы действительно желаете развернуть инфраструктуру?',
  infra_popup_deploy_confirm_btn_no: 'Нет',
  infra_popup_deploy_confirm_btn_yes: 'Да',
  infra_popup_deploy_cancel_hdr_deploy: 'Отмена развертывания инфраструктуры',
  infra_popup_deploy_cancel_txt_deploy: 'Вы действительно желаете отменить развертывание инфраструктуры?',
  infra_popup_deploy_cancel_btn_no: 'Нет',
  infra_popup_deploy_cancel_btn_yes: 'Да',
  infra_popup_view_hdr_view: 'Просмотр инфраструктуры',
  infra_popup_view_lbl_slot: 'Cлот',
  infra_popup_view_lbl_purpose: 'Назначение',
  infra_popup_view_lbl_playbook_of_infrastructure: 'Плейбук инфраструктуры',
  infra_popup_view_hint_description: 'Описание',
  infra_popup_view_hdr_column_parameter: 'Параметр',
  infra_popup_view_hdr_column_value: 'Значение',
  infra_popup_view_hint_descr: 'Описание параметра плейбука',
  infra_popup_view_lbl_playbook_vm: 'Плейбук VM участника',
  infra_popup_view_lbl_amount_vm: 'Кол-во VM участников',
  infra_popup_view_btn_close: 'Закрыть',
  infra_list_crumbs_administration: 'Администрирование',
  infra_list_crumbs_infrastructures: 'Инфраструктуры',
  infra_list_edit_lbl_search: 'Поиск слотов',
  infra_list_edit_search: 'Введите ключевые слова через пробел',
  infra_list_hdr_column_slot: 'Слот',
  infra_list_hdr_column_purpose: 'Назначение',
  infra_list_hdr_column_octet_infra: 'Октет',
  infra_list_hdr_column_state: 'Статус',
  infra_list_hdr_column_state_waiting: 'Ожидание…',
  infra_list_hdr_column_state_deploying: 'Развертывается…',
  infra_list_hdr_column_state_error_deployment: 'Ошибка! (развертывание)',
  infra_list_hdr_column_state_error_destruction: 'Ошибка! (очистка)',
  infra_list_hdr_column_state_ready: 'Готов',
  infra_list_hdr_column_state_occupied: 'Занят',
  infra_list_hdr_column_state_destruction: 'Очистка…',
  infra_list_hdr_column_state_empty: 'Свободен',
  infra_list_hdr_column_playbook: 'Плейбук',
  infra_list_hdr_column_changed_by: 'Изменил',
  infra_list_hdr_column_changed_at: 'Изменено',
  infra_list_popup_destroy_confirm_hdr_destroy: 'Очистка инфраструктуры',
  infra_list_popup_destroy_confirm_lbl_destroy: 'Вы действительно желаете очистить инфраструктуру?',
  infra_list_popup_destroy_confirm_btn_no: 'Нет',
  infra_list_popup_destroy_confirm_btn_yes: 'Да',
  infra_list_lbl_no_infras: 'В перечне отсутствуют инфраструктуры',
  infra_list_lbl_change_search: 'По вашему запросу ничего не найдено, измените критерии поиска',
  infra_list_hint_unmanageable_hdr: 'Неуправляемый слот!',
  infra_list_hint_unmanageable_desc: 'Для конфигурирования данного слота обратитесь к администраторам Системы.',
  infra_list_hint_parameters: 'Параметры',
  infra_list_hint_log: 'Лог',
  infra_list_hint_destroy: 'Очистка',
  infra_list_hint_deploy: 'Развертывание',
  infra_list_popup_list_vm_hdr_list: 'Подключение к VM',
  infra_list_popup_list_vm_txt_text: 'Выберите VM для подключения к её рабочему столу:',
  infra_list_popup_list_vm_btn_cancel: 'Отмена',
  infra_list_popup_list_vm_btn_connect: 'Подключиться',
  audit_journal_hdr_audit: 'Журнал аудита',
  audit_journal_edit_date_time: 'Дата и время',
  audit_journal_lst_severity: 'Важность',
  audit_journal_btn_apply: 'Применить',
  audit_journal_btn_download: 'Скачать события',
  audit_journal_hdr_column_date_time: 'Дата и время',
  audit_journal_hdr_column_message: 'Событие ',
  audit_journal_hint_message: 'Событие',
  audit_journal_hdr_column_severity: 'Важность',
  audit_journal_hdr_column_subject_id: 'ID субъекта ',
  audit_journal_hdr_column_subject_ip: 'IP субъекта ',
  audit_journal_lbl_paging: 'Строк на странице:',
  audit_journal_lbl_paging_out_of: 'из',
  audit_journal_lbl_error_no_results: 'По Вашему запросу ничего не найдено, измените критерии поиска',
  audit_journal_lst_severity_all: 'Все',
  audit_journal_lst_severity_low: 'Низкая',
  audit_journal_lst_severity_medium: 'Высокая',
  audit_journal_lst_severity_high: 'Критическая',
  ///
  admin_panel_hint_user_management: 'Управление пользователями',
  admin_panel_hint_organization_management: 'Управление организациями',
  admin_panel_hint_script_library: 'Библиотека скриптов',
  admin_panel_hint_infra: 'Управление инфраструктурами',
  admin_panel_hint_scoreboard: 'Таблица результатов',
  audit_panel_hint_audit_journal: 'Журнал аудита',
  event_card_tab_bar_hint_event_card: 'Карточка мероприятия',
  event_card_tab_bar_hint_log: 'Лог',
  event_card_tab_bar_hint_attack_and_noise_management: 'Управление атаками и шумами',
  ///
  dashboard_lbl_organizations: 'Организация',
  dashboard_lbl_choose_an_organization: 'Выберите организацию',
  dashboard_lst_organizations_no_found: 'Организации не найдены',
  dashboard_lst_all_organizations: 'Все организации',
  dashboard_lbl_type_event: 'Тип',
  dashboard_lbl_period: 'Период', // newIdLoc
  dashboard_lbl_select_type_event: 'Выберите тип',
  dashboard_lst_type_event_not_found: 'Типы мероприятий не найдены',
  dashboard_lst_all_types_event: 'Все типы мероприятий',
  dashboard_lst_capture_the_flag: 'Захват флага',
  dashboard_lst_researches: 'Исследования',
  dashboard_lst_training: 'Обучение',
  dashboard_lst_confrontation: 'Противостояние',
  dashboard_lst_exercises: 'Учения',
  dashboard_btn_apply: 'Применить',
  dashboard_lbl_statics_status_of_events: 'Статусы мероприятий',
  dashboard_lbl_statics_status_of_events_planned: 'Запланировано',
  dashboard_lbl_statics_status_of_events_in_process: 'В процессе',
  dashboard_lbl_statics_status_of_events_finished: 'Завершено',
  dashboard_lbl_finish_events_of_types: 'Завершенные мероприятия',
  dashboard_lbl_statics_finish_events_of_types_capture_the_flag: 'Захват флага',
  dashboard_lbl_statics_finish_events_of_types_researches: 'Исследования',
  dashboard_lbl_statics_finish_events_of_types_training: 'Обучение',
  dashboard_lbl_statics_finish_events_of_types_confrontation: 'Противостояние',
  dashboard_lbl_statics_finish_events_of_types_exercises: 'Учения',
  dashboard_lbl_statics_members: 'Участники',
  dashboard_lbl_statics_events: 'Мероприятия',
  dashboard_lbl_statics_events_event: 'Мероприятие',
  dashboard_lbl_statics_events_start: 'Начало',
  dashboard_lbl_statics_events_members: 'Участников, чел',
  dashboard_lbl_statics_events_status: 'Статус',
  dashboard_lbl_statics_events_unit: 'ед.',
  ///
  integrity_control_lbl_integrity_control: 'Контроль целостности',
  integrity_control_edit_search_object_plh: 'Введите поисковый запрос…',
  integrity_control_btn_full_update: 'Полное обновление БДКЦ',
  integrity_control_lbl_update_in_progress: 'Идёт обновление…',
  integrity_control_hdr_update_in_progress: 'Выполняется полное обновление базы данных контроля целостности…',
  integrity_control_hdr_column_name_object: 'Объект',
  integrity_control_hdr_column_status: 'Состояние',
  integrity_control_hdr_column_service: 'Сервис',
  integrity_control_hdr_column_date_edit: 'Изменен',
  integrity_control_hdr_column_editor: 'Изменил',
  integrity_control_btn_edit_object: 'Изменить',
  integrity_control_btn_update_object: 'Обновить',
  integrity_control_hint_indicator_lamp_hint_integrity_control: 'Контроль целостности',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object: 'Нарушена целостность объектов',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object_details: 'Подробности смотри:',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_object_path: 'Меню > Контроль целостности',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database: 'База данных контроля целостности пустая',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database_details: 'Необходимо провести полное обновление БДКЦ:',
  integrity_control_hint_indicator_lamp_hint_integrity_empty_database_path: 'Меню > Контроль целостности > Полное обновление БДКЦ',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database: 'Нарушена целостность БДКЦ',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database_details: 'Необходимо принять меры согласно внутренним регламентам:',
  integrity_control_hint_indicator_lamp_hint_integrity_broken_database_path: 'Меню > Контроль целостности',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object: 'Некоторые объекты находятся в режиме изменения',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object_details: 'Подробнее:',
  integrity_control_hint_indicator_lamp_hint_integrity_edit_object_path: 'Меню > Контроль целостности',
  integrity_control_lbl_empty_database: 'Нет ни одного объекта под контролем целостности.\nНеобходимо выполнить полное обновление БДКЦ!',
  integrity_control_lbl_empty_filter_data: 'Объекты контроля целостности не найдены. Измените критерии поиска.',
  integrity_control_popup_hdr_full_update: 'Полное обновление базы данных контроля целостности',
  integrity_control_popup_txt_full_update: 'Вы действительно желаете полностью обновить базу данных контроля целостности?',
  integrity_control_popup_btn_full_update_yes: 'Обновить',
  integrity_control_popup_btn_full_update_no: 'Отмена',
  integrity_control_lbl_violation: 'Нарушение',
  integrity_control_lbl_edited: 'Редактируется',
  integrity_control_lbl_checked: 'Объект проверен',
  integrity_control_hint_integrity_control_database_management: 'Управление базой данных контроля целостности',
  ///
  navigation_panel_lbl_cybermir: 'Национальный киберполигон',
  user_authorization_lbl_cybermir: 'Кибермир',
  user_authorization_lbl_solar: 'Солар',
  org_panel_lbl_not_active_user_hint: 'Пользователь не активен, так как его организация заблокирована',
  event_creation_editing_lbl_participants_loading: 'Загрузка...',
  event_creation_editing_lbl_observes_loading: 'Загрузка...',
  event_screen_popup_particip_hdr_id_hint: 'ID скопирован в буфер обмена',
  user_panel_popup_no_block_hdr_block_not_possible: 'Блокировка невозможна',
  user_panel_popup_no_block_txt_last_admin: 'Пользователь является последним активным "администратором"',
  user_panel_popup_no_block_btn_confirm: 'OK',
  org_panel_popup_block_org_hdr_block: 'Блокировка организации',
  org_panel_popup_block_org_txt_warning: 'Пользователи этой организации не смогут войти в систему пока организация заблокирована.\nВы действительно хотите заблокировать организацию?',
  org_panel_popup_block_org_btn_block: 'Блокировать',
  org_panel_popup_block_org_btn_cancel: 'Отменить',
  user_card_hdr_no_records: 'События отсутствуют либо скрыты',
  user_card_popup_edit_btn_reset_photo: 'Сбросить фото',
  auth_popup_invalid_link_hdr_error: 'ОШИБКА',
  auth_popup_invalid_link_txt_warning: 'Мы не можем сбросить ваш пароль!\n\nВозможно, ранее Вы уже воспользовались данной ссылкой для смены пароля.\n\nПовторите процедуру восстановления пароля сначала.',
  auth_popup_invalid_link_btn_back: 'Назад ко входу',
  events_list_edit_search_event_plh: 'Поиск по мероприятиям',
  user_panel_popup_no_access_rights_txt_page_view: 'У вас нет прав для просмотра данной страницы',
  user_panel_popup_no_access_rights_hdr_error: 'ОШИБКА',
  user_panel_popup_no_access_rights_link_back_to_events: 'ВЕРНУТЬСЯ К СПИСКУ МЕРОПРИЯТИЙ',
  org_panel_lbl_loading_org: 'Загрузка организаций',
  event_card_hdr_attack_noise_management: 'Управление атаками и шумами',
  event_card_hdr_attack_noise_no_data: 'Нет данных об атаках или шумах',
  org_panel_popup_create_org_lbl_error_org_name_chrctrs: 'Введенное значение не должно быть более 255 символов',
  org_panel_popup_create_org_lbl_error_org_already_exist: 'Организация с таким названием уже существует',
  event_card_lbl_event_status_starting_soon: 'Скоро начнётся',
  event_screen_popup_map_hdr_map: 'Карта мероприятия',
  event_screen_popup_map_btn_download: 'Скачать',
  event_screen_popup_map_btn_print: 'Распечатать',
  user_card_popup_pwd_hdr_reset: 'СБРОС ПАРОЛЯ',
  user_card_popup_pwd_txt_reset_sent: 'Письмо с инструкциями отправлено на адрес',
  user_card_popup_pwd_btn_confirm: 'ОК',
  user_card_popup_edit_lbl_error_email_exist: 'Пользователь с таким email уже существует',
  user_card_popup_changes_saved_hdr_edit_user: 'Редактирование пользователя',
  user_card_popup_changes_saved_lbl_data: 'Данные пользователя успешно изменены',
  user_card_popup_changes_saved_btn_confirm: 'ОК',
  event_card_lbl_validation_error: 'Ошибка валидации!',
  event_card_lbl_debriefing: 'Подведение итогов',
  event_card_lbl_cleaning_up: 'Завершение',
  event_screen_lbl_error_server: 'Ошибка подключения к серверу!',
  event_screen_lbl_error_server_no_connection: 'Отсутствует подключение к серверу',
  event_creation_editing_edit_error_event_time_overdue: 'Время просрочено. Ближайшее доступное время начала: +1 час к текущему времени',
  attack_vector: 'Вектор атаки',
  event_creation_editing_hdr_infa: 'Инфраструктура',
  event_creation_editing_lst_mission: 'Выберите слот миссии',
  autostart: 'Автоматизация процессов',
  automatic_completion: 'Автоматическое завершение',
  datetime_d: 'д',
  datetime_h: 'ч',
  datetime_m: 'м',
  events_bot_list_name: 'Имя (ID)',
  events_bot_list_type: 'Тип',
  events_bot_list_attacker: 'Атакующий (vmID)',
  events_bot_list_target: 'Цель',
  access_error: 'Ошибка доступа',
  of_mission: 'миссии',
  of_event: 'мероприятия',
  moscow_zone_title: 'Москва, Сочи',
  vladivostok_zone_title: 'Владивосток, Чаморро',
  date_placeholder: 'ДД/ММ/ГГ',
  event_creation_editing_observes_no_records: 'Записей не обнаружено',
  user_profile_assign: 'Назначить',
  events_list_no_events_available: 'Доступные мероприятия отсутствуют',
  infrastructure_no_data_text: 'События не найдены',
  infrastructure_loading_text: 'Загрузка событий',
  toolbar_results_table: 'Таблица результатов',
  teacher_comment_placeholder: 'Комментарий...',
  scoring_report_evaluation_popup_example_hdr_reference_report: 'Эталонный пример отчета',
  entered_value_must_not_exceed: 'Длина строки не должна превышать',
  characters: 'символов',
  event_card_lbl_publish: 'Опубликовано',
  command_sent_to_server: 'Команда отправлена на сервер',
  command_sending_error: 'Ошибка отправки команды',
  enter_value: 'Введите значение...',
  search_lbl: 'Поиск...',
  event_screen_reload_vm: 'Перезагрузить виртуальную машину',
  event_screen_chk_remocon_switcher: 'Полное управление',
  event_screen_chk_remocon_title: 'ИЗМЕНЕНИЕ УПРАВЛЕНИЯ',
  event_screen_chk_remocon_to_guacamole_header: 'Вы уверены, что хотите переключиться в полнофункциональный режим управления рабочим столом?',
  event_screen_chk_remocon_to_guacamole_text: '* Потребуется повторная авторизация на рабочем столе.',
  event_screen_chk_remocon_to_spice_header: 'Вы уверены, что хотите переключиться в ограниченный режим управления рабочим столом?',
  event_screen_chk_remocon_to_spice_text: 'Данный режим используется при наличии технических проблем  с подключением к удаленному рабочему столу.\n\n* Потребуется повторная авторизация на рабочем столе, будут ограничены некоторые функции (буфер обмена между браузером и рабочим столом)',
  event_screen_chk_remocon_btn_confirm: 'Подтвердить',
  event_screen_chk_remocon_btn_cancel: 'Отмена',
  select_object_add_gif_effect: 'Выберите объект, для которого хотите добавить gif-эффект',
  gif_effect_with_this_name_exists: 'Эффект с таким именем уже существует',
  enter_the_name_effect: 'Введите названия эффекта',
  saving: 'Сохранено',
  load_image: 'Загрузите изображение',
  file_input_select_file: 'Выберите файл',
  file_input_select_files: 'Выберите файлы',
  file_input_drag_file: 'или перетащите его сюда',
  file_input_drag_files: 'или перетащите их сюда',
  an_incorrect_name_was_entered_or_it_already_exists: 'введено некорректное имя либо оно уже существует',
  unsaved_data_will_be_lost: 'несохраненные данные будут утеряны, продолжить?',
  incorrect_file_format: 'Некорректный формат файла. Допустимые форматы: *.svg, *.png, *.jpeg',
  event_duration_hint: 'Продолжительность мероприятия',
  mission_duration_hint: 'Продолжительность миссии',
  mission_rounds_hint: 'Количество раундов',
  mission_mode_hint: 'Режим миссии',
  mission_difficulty_hint: 'Сложность миссии',
  number_of_participants_hint: 'Количество участников',
  event_type_hint: 'Тип мероприятия',
  personal_account: {
    lbl: {
      user_info: 'Личная информация',
      user_events: 'Мероприятия',
      user_results: 'Результаты',
      user_status: 'Статус',
      last_time: 'Был {dt} в {tm}',
      registration: 'Дата регистрации',
      password: 'Пароль',
      edited_now: 'Изменен только что',
      cancel_pass: 'Отменить',
      save_pass: 'Сохранить',
      requirements_pass: 'Требования к паролю',
      pass_protection: 'Для защиты ваших данных необходимо придумать безопасный пароль',
      symbols: '10 и более символов',
      uppercase: 'Прописные латинские буквы',
      lowercase: 'Строчные латинские буквы',
      numbers: 'Цифры',
      special_symbols: 'Спецсимволы: (!”$%&’()+,-/:;⇔?@[]_{|}~`)',
      old_pass: 'Старый пароль',
      new_pass: 'Новый пароль',
      repeat_pass: 'Повторите пароль',
      records_detected: 'Обнаружено записей',
      today_events: 'Сегодня',
      coming_events: 'Ближайшие мероприятия',
      no_any_events: 'Вы пока не участвовали ни в одном мероприятии\nи у Вас нет запланированных киберучений',
      visit_site: 'Посетите наш сайт',
      no_events_today: 'На сегодня у вас нет мероприятий',
      no_coming_events: 'В ближайшее время новые мероприятия не запланированы',
      leave_request: 'и оставьте заявку',
      place: 'Ваше место',
      flag: 'Флагов установлено',
      iocs: 'IoC обнаружено',
      server_availability: 'Доступность сервисов',
      server_unavailability: 'Недоступность сервисов ',
      events: 'Статусы по мероприятиям',
      events_planned: 'Запланировано',
      events_process: 'В процессе',
      events_finished: 'Завершены',
      type: 'Участвовал',
      no_scores: 'У вас пока нет результатов,\nтак как вы не участвовали ни в одном мероприятии.',
      no_personal_score: 'Вы ещё не участвовали\nв',
      no_rating_event: 'У вас пока нет результатов,\nтак как вы не участвовали ни в одном',
      by_personal: 'личных',
      by_team: 'командных',
      by_rating: 'рейтинговом',
      by_events: 'мероприятиях',
      by_event: 'мероприятии',
      total: 'Всего',
    },
    btn: {
      edit: 'Изменить',
      forgot_pass: 'Забыли пароль?',
      nearest: 'Ближайшие',
      all: 'Все',
      more: 'Подробнее',
      cal_today: 'Сегодня',
      cal_tomorrow: 'Завтра',
      cal_all: 'За всё время',
      personal: 'Личный',
      team: 'Командный',
    },
    hdr: {
      rating: 'Рейтинг',
      skills: 'Навыки',
      hdr_no_scores: 'Данные о навыках временно отсутствуют',
    },
    link: {
      coming_events: 'Узнать о ближайших мероприятиях',
      visit_site: 'Посетить наш сайт',
    }
  },
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_unfinished_events: 'Имеются незавершенные мероприятия',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_unfinished_event: 'Имеется незавершенное мероприятие',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_event: 'Мероприятие',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_mission: 'Миссия',
  user_panel_popup_no_block_prtcpnt_or_tchr_hdr_column_start_date: 'Дата начала',
  user_panel_popup_no_block_prtcpnt_or_tchr_txt_warnings: 'Пользователь не может быть заблокирован, так как у него имеются незавершенные мероприятия',
  user_panel_popup_no_block_prtcpnt_or_tchr_txt_warning: 'Пользователь не может быть заблокирован, так как у него имеется незавершенное мероприятие',
  user_panel_popup_no_block_prtcpnt_or_tchr_btn_confirm: 'OK',
  ///
  script_control_vm_name: 'Имя виртуальной машины',
  script_control_timeout: 'Тайм-аут',
  script_control_running: 'Скрипт запущен',
  script_control_start: 'Запустить скрипт',
  script_control_result: 'Результат выполнения',
  script_control_status: 'Статус выполнения',
  events_list_item_start: 'Начало',
  events_list_item_today: 'Сегодня',
  events_list_item_near: 'Скоро',
  events_list_item_running: 'Уже идет',
  events_list_item_summing_up: 'Подводятся итоги',
  events_list_item_start_date: 'Дата начала',
  events_list_item_begin: 'Начнется',
  events_list_item_begin_through: 'Начнется через',

  event_creation_editing: {
    popup_prtcpnts: {
      hdr: {
        prtcpnts_mngmnt: 'Управление участниками',
      },
    },
    popup_participants: {
      lbl: {
        infra: 'Слот'
      },
      hdr_column: {
        full_name: 'ФИО',
        email: 'E-mail',
        position: 'Должность'
      },
      btn: {
        cancel: 'Отмена',
        save: 'Сохранить',
      }
    },
    popup_observes: {
      lst: {
        org_plh: 'Организации'
      },
      hdr: {
        observes_mngmnt: 'Управление наблюдателями'
      },
      lbl: {
        observes: 'ФИО',
      },
      hdr_column: {
        email: 'E-mail',
        position: 'Должность',
        organization: 'Организация'
      },
      btn: {
        cancel: 'Отмена',
        save: 'Сохранить',
      }
    },
    edit: {
      mission: 'Миссия', // newIdLoc
      mission_plh: 'Выберите миссию', // newIdLoc
      round_duration: 'Длительность раунда, мин.',
      error_event_time_plus_one_hour: 'Ближайшее доступное время начала: +1 час к текущему времени',
    },
    lbl: {
      error_round_duration_false: 'Введите целое или десятичное число от {min} до {max}',
      mission_duration: 'мин.', // newIdLoc
      playbook: 'Плейбук',
      reserve: 'Резервные VM участников',
      rounds: 'раунд(ов)',
      participants: 'Участники',
      observes: 'Наблюдатели',
      error_event_teams_empty: 'Необходимо добавить не менее двух команд',
    },
    lst: {
      playbook: 'Выберите плейбук участника',
      teacher: 'Преподаватель',
      teacher_plh: 'Выберите преподавателя',
      infa: 'Выберите слот инфраструктуры',
    },
    hdr: {
      infa: 'Слот инфраструктуры', // newIdLoc
    },
    btn: {
      scoring: 'Скоринг',
      participant: {
        add: 'Добавить',
        change: 'Изменить',
      },
      observer: {
        add: 'Добавить',
        change: 'Изменить',
      },
      create_event: 'Создать мероприятие',
      save_changes: 'Сохранить изменения',
    },
  },
  calendar: { // newIdLoc
    lbl: {
      select: 'Выбрать',
      cancel: 'Отмена',
      today: 'Сегодня',
      tomorrow: 'Завтра',
      specify: 'Уточните',
      start_time: 'время начала'
    }
  },
  event_editing: {
    popup_assignment_VM_block: {
      txt: {
        block: 'Выбранная виртуальная машина назначена другому участнику, сбросить назначение и продолжить?'
      },
      btn: {
        reset: 'Сбросить'
      }
    }
  },
  scoring: {
    participant_report: {
      popup_saving: {
        hdr: {
          correct_errors: 'Ошибка сохранения'
        },
        txt: {
          correct_errors: 'Следует исправить все ошибки\nперед сохранением отчета'
        }
      }
    }
  },
  event: {
    popup_event_debriefed: {
      hdr: {
        notification: 'Завершение мероприятия'
      },
      txt: {
        notification: 'Мероприятие завершено.\nИдет подведение итогов.'
      },
      btn: {
        ok: 'ОК'
      }
    }
  },
  change_event_status_popup: {
    pause: {
      hdr: {
        notification: 'Завершение мероприятия'
      },
      txt: {
        warning: 'Мероприятие приостановлено.'
      }
    },
    finish: {
      txt: 'Мероприятие завершено.'
    }
  },

  // NEW LOCS
  event_creation_editing_popup_creation_lbl_successfully_c: 'Мероприятие успешно создано!', // newIdLoc
  event_creation_editing_popup_creation_lbl_successfully_u: 'Мероприятие успешно изменено!', // newIdLoc
  event_creation_editing_edit_team_name: 'Команда #{number}', // newIdLoc
  event_creation_editing_popup_participants_backup_vms: 'Резерв', // newIdLoc
  event_creation_editing_popup_participants_backup_warn: 'Участников больше, чем доступно VM', // newIdLoc

  bulk_user_uploading_popup_users_upload_lbl: 'Список пользователей', // newIdLoc
  bulk_user_uploading_popup_users_files_block_plh: 'Добавьте файл со списком пользователей', // newIdLoc

  user_card_popup_edit_input_surname_plh: 'Введите фамилию', // newIdLoc
  user_card_popup_edit_input_email_plh: 'Введите E-Mail', // newIdLoc
  user_card_popup_edit_input_name_plh: 'Введите имя', // newIdLoc
  user_card_popup_edit_input_middle_name_plh: 'Введите отчество', // newIdLoc
  user_card_popup_edit_input_position_plh: 'Введите должность', // newIdLoc

  user_panel_popup_no_block_not_possible_popup_footer_block_btn: 'Заблокировать', // newIdLoc
  user_panel_popup_no_block_not_possible_popup_footer_cancel_btn: 'Отменить', // newIdLoc
  user_panel_popup_no_block_prtcpnt_for_observer_txt_warning: 'Заблокированный пользователь не сможет войти в систему.\n Вы действительно желаете заблокировать пользователя?', // newIdLoc
  user_panel_popup_no_block_hdr_remove_not_possible: 'Удаление пользователя невозможно', // newIdLoc
  user_panel_popup_no_block_observer_hdr_block_not_possible: 'Блокировка учётной записи', // newIdLoc
  user_panel_popup_block_hdr: 'Блокировка учётной записи', // newIdLoc
  user_panel_popup_block_error_txt: 'Заблокированный пользователь не сможет войти в систему.\n Вы действительно желаете заблокировать пользователя?', // newIdLoc
  user_panel_popup_block_footer_cancel_btn: 'Отменить', // newIdLoc
  user_panel_popup_block_footer_block_btn: 'Заблокировать', // newIdLoc

  user_creation_editing_popup_delete_title_hdr: 'Удаление из мероприятия', // newIdLoc
  user_creation_editing_popup_delete_first_line_txt: 'Пользователь будет удален из всех незавершенных мероприятий', // newIdLoc
  user_creation_editing_popup_delete_second_line_txt: 'Вы уверены, что хотите изменить роль?', // newIdLoc

  file_input_error_lbl: 'Ошибка загрузки', // newIdLoc

  event_card_btn_remove: 'Удалить', // newIdLoc

  events_list_btn_confirm: 'Подтвердить', // newIdLoc
  combobox_txt_no_data: 'Нет данных для', // newIdLoc
  combobox_txt_select_all: 'Выбрать все', // newIdLoc
  combobox_txt_multiple_2_4_items: 'Пункта', // newIdLoc
  combobox_txt_multiple_5_and_more_items: 'Пунктов', // newIdLoc

  scoring_participant_report_popup_saving_hdr_correct_errors: 'Ошибка сохранения',
  scoring_participant_report_popup_saving_txt_correct_errors: 'Следует исправить все ошибки перед сохранением отчета',
  scoring_participant_report_hdr_warning_changes_hint: 'В отчете имеются неотправленные изменения',
  scoring_participant_report_txt_warning_changes_hint: 'Отправляйте отчет по мере заполнения или в соответсвии с рекомендацией организаторов мероприятия',

  scoring_scoreboard_txt_no_events_selected: 'Выберите мероприятия, для которых необходимо отобразить результаты в таблице результатов участников', // newIdLoc
  scoring_scoreboard_hdr_column_event_team: 'Мероприятие/Команда', // newIdLoc
  scoring_scoreboard_hdr_column_full_name: 'ФИО', // newIdLoc
  scoring_popup_settings_btn_save: 'Сохранить', // newIdLoc

  event_popup_summarizing_content_txt: 'Вы действительно желаете подвести итоги?', // newIdLoc
  event_popup_summarizing_btn_cancel: 'Отменить', // newIdLoc
  event_popup_summarizing_btn_summrize: 'Подвести итоги', // newIdLoc

  cf_popup_can_btn_yes: 'Да', // newIdLoc
  cf_popup_can_btn_cancel_no: 'Нет', // newIdLoc

  event_creation_editing_lbl_error: 'Ошибка!', // newIdLoc
  event_creation_editing_lbl_targets_errors: 'Найдено ошибок', // newIdLoc
  event_creation_editing_lbl_targets_errors_ctf: 'Неверно заданы цели (URI):', // newIdLoc
  event_creation_editing_lbl_targets_errors_res: 'Неверно заданы защищаемые сервисы:', // newIdLoc
  event_creation_editing_lbl_targets_errors_ctf_empty: 'Не заполнены цели (URI)', // newIdLoc
  event_creation_editing_lbl_targets_errors_res_empty: 'Не заполнены защищаемые сервисы', // newIdLoc

  mission_cnstrctr_upload_file_error_repeating_name: 'Файл с таким именем уже существует', // newIdLoc
  mission_cnstrctr_upload_file_error_no_more_50mb: 'Выберите файл размером не более 50мб', // newIdLoc
  mission_cnstrctr_upload_file_error_invalid_file_format: 'Неверный формат файла', // newIdLoc

  event_creation_editing_lbl_scoring_off_attacks: 'Скоринг доступен только при включённых атаках', // newIdLoc

  test_animation_sidebar_hdr: 'Тестирование анимации', // newIdLoc
  test_animation_round_title: 'Раунд', // newIdLoc
  test_animation_vector_title: 'Вектор', // newIdLoc
  test_animation_phase_title: 'Фаза', // newIdLoc

  event_creation_editing_popup_title_delete: 'Удаление мероприятия', // newIdLoc

  event_card_popup_reboot_txt_question: 'Вы уверены, что хотите перезагрузить данную машину?', // newIdLoc

  event_scoreboard_table_hdr_team: 'Команда', // newIdLoc
  event_scoreboard_table_hdr_defence: 'Защита', // newIdLoc
  event_scoreboard_table_hdr_total: 'Всего', // newIdLoc
  event_scoreboard_table_hdr_attack: 'Атака', // newIdLoc

  event_creation_editing_popup_delete_event_hdr_warning: 'Внимание', // newIdLoc
  event_creation_editing_popup_delete_event_txt_cancel: 'Вы покидаете страницу и все несохраненные данные будут утеряны. Продолжить?', // newIdLoc
  event_creation_editing_popup_delete_event_btn_cancel: 'Отменить', // newIdLoc
  event_creation_editing_popup_delete_event_btn_continue: 'Продолжить', // newIdLoc
}
