export class SSEService {
  #connection

  subscribeToEvents (url, cb) {
    this.#connection = new EventSource(url)
    this.#connection.onmessage = (msg) => {
      if (msg) {
        cb(msg.data)
      }
    }
    this.#connection.onerror = (err) => console.error('Failed to parse or lost connection:', err)
  }

  unsubscribeFromEvents (url) {
    this.#connection && this.#connection.close()
  }
}
