<template>
  <div class="cbr-textarea" :class="{ small, medium, large }">
    <div v-if="title" class="cbr-textarea-title" :class="{ 'error-state': !isValid }">
      <CbrHint
        v-if="infoHint"
        :title="infoHint.title"
        :sub-title="infoHint.subTitle"
        :description="infoHint.description"
        info
        bottom
        content-class="move-right"
      >
        <CbrIcon class="cbr-textarea-title__icon">{{ titleIcon }}</CbrIcon>
      </CbrHint>
      <CbrIcon v-if="titleIconAction" class="cbr-textarea-title__icon" @click="titleIconAction">{{ titleIcon }}</CbrIcon>
      <span class="cbr-textarea-title__text">{{ title }}
        <span class="cbr-textarea-title__required" v-if="required">*</span>
      </span>
      <slot name="titleRight"/>
    </div>
    <v-textarea
      class="cbr-textarea-container"
      :id="id"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :rows="rows"
      :auto-grow="autoGrow"
      :hide-details="hideDetails"
      :rules="rules"
      :error-messages="errorMessages"
      :maxlength="maxlength"
      :counter="counter"
      :class="inputClasses"
      :height="height"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <template v-slot:message="{ message }">
        <div class="cbr-textarea-error-block">{{ message }}</div>
      </template>
      <template v-slot:append>
        <CbrIcon v-if="reset && !disabled"
          class="reset-icon"
          @click="resetValue"
        >
          $inputReset
        </CbrIcon>
        <CbrHint
          v-if="!isValid && errorHint"
          :title="errorHint.title"
          :sub-title="errorHint.subTitle"
          :description="errorHint.description"
          error
          bottom
          content-class="move-left"
        >
          <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
        </CbrHint>
        <CbrIcon v-if="!isValid && !errorHint" class="error-icon"> mdi-alert-circle </CbrIcon>
        <CbrIcon
          v-if="clearable && value && !disabled"
          class="clearable-icon"
          @click="$emit('input', '')"
        >
          mdi-close
        </CbrIcon>
      </template>
      <template v-slot:counter="{ value, max }">
        <v-counter :value="value" :max="max"></v-counter>
      </template>
    </v-textarea>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  id: String,
  value: String,
  placeholder: String,
  disabled: Boolean,
  readonly: Boolean,
  rows: [Number, String],
  height: [Number, String],
  autoGrow: Boolean,
  hideDetails: Boolean,
  rules: Array,
  errorMessages: {
    type: Array,
    default: () => [],
  },
  counter: [Boolean, Number],
  maxlength: [Number, String],
  title: String,
  titleIcon: {
    type: String,
    default: () => '$information',
  },
  titleIconAction: {
    type: Function
  },
  required: Boolean,
  small: Boolean,
  large: Boolean,
  clearable: {
    type: Boolean,
    default: () => true
  },
  error: Boolean,
  initialValue: String,
  reset: Boolean,
  infoHint: Object,
  errorHint: Object
})
const emit = defineEmits(['input'])

const init = ref('')

const medium = computed(() => !props.small && !props.large)
const isValid = computed(() => !props.error && !props.errorMessages.length && !props.errorHint)
const inputClasses = computed(() => {
  return {
    'failed-input': !isValid.value,
    'valid-input': isValid.value,
    required: props.required,
    disabled: props.disabled
  }
})

const resetValue = () => emit('input', init.value)

onMounted(() => {
  if (props.initialValue) {
    init.value = props.initialValue
    resetValue()
  }
})
</script>

<style lang="scss" scoped>
$borderWidth: 2px;
$cornerMargin: 3px; // отступ от уголка обязательности до границы поля

$cornerSizeSmall: 4px;
$cornerSizeMedium: 5px;
$cornerSizeLarge: 8px;

$fontSizeSmall: 16px;
$fontSizeMedium: 18px;
$fontSizeLarge: 20px;

$backgroundColor: rgba($base-color-dark, 0.35);

@mixin set-state-styles(
  $cornerSize,
  $borderColor,
  $backgroundColor: $backgroundColor,
  $required: false,
  $triangleColor: $base-color
) {
  $clipCornerSize: calc(#{$cornerSize} + #{$cornerMargin});
  $cornerBorderWidth: calc(#{$clipCornerSize} + #{$borderWidth} + #{$borderWidth});

  ::v-deep .v-input__slot {
    background-color: $backgroundColor;
    @include cut-corners($clipCornerSize, true, $clipCornerSize, false, $borderWidth, $borderColor);
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 0;
    &::before {
      border-width: $cornerBorderWidth $cornerBorderWidth 0 0;
      border-color: $borderColor transparent transparent transparent !important;
      border-image: none;
      transition: none;
    }
    &::after {
      border-width: 0 $cornerBorderWidth $cornerBorderWidth 0;
      border-color: transparent $borderColor transparent transparent !important;
      left: auto;
      transition: none;
    }
  }

  ::v-deep .v-input__control {
    position: relative;
    @if ($required) {
      &::before {
        content: '';
        position: absolute;
        border-top: $cornerSize solid $triangleColor;
        border-right: $cornerSize solid transparent;
        width: 0;
        height: 0;
      }
    }
  }
}

@mixin set-size-options ($cornerSize, $fontSize){
  $clipCornerSize: calc(#{$cornerSize} + #{$cornerMargin});
  $cornerBorderWidth: calc(#{$clipCornerSize} + #{$borderWidth} + #{$borderWidth});

  ::v-deep .v-input__slot {
    font-size: $fontSize;
  }

  // valid state classes
  .cbr-textarea-container.valid-input:not(.required):not(.error--text):not(.disabled) {
    &:not(:focus-within) {
      @include set-state-styles($cornerSize, $border-muted);
    }
    &:focus-within {
      @include set-state-styles($cornerSize, $base-color);
    }
  }
  .cbr-textarea-container.valid-input.required:not(.error--text):not(.disabled) {
    &:not(:focus-within) {
      @include set-state-styles($cornerSize, $border-muted, $backgroundColor, true, $base-color);
    }
    &:focus-within {
      @include set-state-styles($cornerSize, $base-color, $backgroundColor, true, $base-color);
    }
  }
  // disabled state class
  .cbr-textarea-container.disabled {
    &.required {
      @include set-state-styles($cornerSize, $border-disabled, $backgroundColor, true, $border-disabled);
    }
    &:not(.required) {
      @include set-state-styles($cornerSize, $border-disabled);
    }
  }
  // error state classes
  .cbr-textarea-container.failed-input:not(.required), .cbr-textarea-container.error--text {
    @include set-state-styles($cornerSize, $base-color-error-text);
    ::v-deep .v-counter {
      color: $base-color-error-text !important;
    }
  }
  .cbr-textarea-container.failed-input.required, .cbr-textarea-container.error--text.required {
    @include set-state-styles($cornerSize, $base-color-error-text, $backgroundColor, true, $base-color-error-text);
    ::v-deep .v-counter {
      color: $base-color-error-text !important;
    }
  }
}

.cbr-textarea {
  &-title {
    display: flex;
    align-items: center;
    color: $base-color;
    margin-bottom: 4px;

    &__icon {
      margin-right: 0.2em;
      min-width: fit-content;
      ::v-deep path {
        fill: $base-color;
        opacity: 0.5;
      }
      &:hover ::v-deep path {
        opacity: 1;
      }
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      padding-right: 9px;
      width: 100%;
      line-height: 1em;
    }
    &__required {
      color: $base-color-error-text;
    }

    &.error-state {
      color: $base-color-error-text;
      .cbr-textarea-title__icon {
        ::v-deep path {
          fill: $base-color-error-text;
        }
      }
    }
  }
  &-container {
    padding-top: 0;
    margin-top: 0;

    ::v-deep .v-input__slot .v-text-field__slot {
      align-items: center;
      textarea {
        padding: 6px calc(1.2em + 4px) 6px 12px;
        color: $base-color-text;
        caret-color: $base-color-text;
        line-height: 1.185em;
        @include scrollbar-small;
        overflow-y: auto;
        border: none;
        resize: none;
        &::placeholder {
          font-family: $primary-font-family;
          color: rgba($base-color-light, 0.3);
        }
      }
    }

    ::v-deep .v-input__append-inner {
      margin: 0 0.3em 0 0;
      position: absolute;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > button, svg, i {
        margin-top: 0.3em;
      }
    }

    ::v-deep .v-text-field__details {
      margin-top: 5px;
      padding: 0 16px 0 13px;
      .v-counter {
        color: rgba($base-color-light, 0.5) !important;
      }
    }
  }

  &-error-block {
    color: $AlarmTextColor;
  }

  &.small {
    @include set-size-options($cornerSizeSmall, $fontSizeSmall);
    ::v-deep .v-icon {
      font-size: $fontSizeSmall;
      height: $fontSizeSmall !important;
    }
    .cbr-textarea-title {
      font-size: $fontSizeSmall;
    }
    ::v-deep textarea {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
  &.medium {
    @include set-size-options($cornerSizeMedium, $fontSizeMedium);
    ::v-deep .v-icon {
      font-size: $fontSizeMedium;
      height: $fontSizeMedium !important;
    }
    .cbr-textarea-title {
      font-size: $fontSizeMedium;
    }
  }
  &.large {
    @include set-size-options($cornerSizeLarge, $fontSizeLarge);
    ::v-deep .v-icon {
      font-size: $fontSizeLarge;
      height: $fontSizeLarge !important;
    }
    .cbr-textarea-title {
      font-size: $fontSizeLarge;
    }
    ::v-deep textarea {
      padding-top: 11px !important;
      padding-bottom: 11px !important;
    }
  }
}

.clearable-icon {
  color: $base-color-muted !important;
  font-size: 1.2em !important;
}
.error-icon {
  color: $base-color-error-text !important;
  font-size: 1.2em !important;
  margin-top: 8px;
  &:hover {
    cursor: auto;
  }
}
.reset-icon {
  color: rgba($base-color-warning, 0.6) !important;
  font-size: 1.2em !important;
  &:hover {
    cursor: pointer;
  }
}
.failed-input, .error--text {
  .clearable-icon {
    color: $base-color-error-text !important;
  }
}
</style>
