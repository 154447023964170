<template>
  <v-avatar v-on="$listeners" :size="size" tile>
    <v-img
      v-if="avatarPath.length"
      :class="isShowOnline && !isOnline ? 'offline-avatar' : ''"
      :src="avatarPath"
      :lazy-src="defaultAvatar"
      @error="onErr()"
    />
    <CbrHint
      v-if="vmIdVisible"
      right
      noIcon
      close-delay="0"
    >
      <div
        v-if="vmIdVisible"
        class="vm-id"
        v-on:mouseenter="activateTooltip"
        v-on:mouseleave="deactivateTooltip"
        @click.stop="copyId"
      >
        ID
      </div>
      <template v-slot:description>
        <div class="text-center">
          <CbrIcon color="rgba(0, 240, 255, 0.6)" class="mr-2" x-small>mdi-content-copy</CbrIcon>
          <span ref="vmSpan">{{ vmId }}</span>
        </div>
        <div v-if="copyTriggered" class="vm-id-tooltip-text mt-2">
          {{ i18n.t('event_screen_popup_particip_hdr_id_hint') }}
        </div>
      </template>
    </CbrHint>
    <div v-if="isShowOnline" class="online-status" :class="{ 'is-online': isOnline }"></div>
  </v-avatar>
</template>
<script setup>
import { i18n } from '@/i18n-setup'
import { ref, computed, watch } from 'vue'
import { useStore } from '@/store'

const store = useStore()
const props = defineProps({
  size: {
    type: Number,
    default: 62
  },
  userId: {
    type: Number,
    default: null
  },
  isShowOnline: {
    type: Boolean,
    default: false
  },
  vmIdVisible: {
    type: Boolean,
    default: false
  },
  vmId: {
    type: String,
    default: ''
  },
  isOnline: Boolean
})

const defaultAvatar = ref(require('@/assets/icons/svg/avatar2.svg'))
const avatarPath = ref('')
const tooltip = ref(false)
const copyTriggered = ref(false)
const noAvatar = ref(true)

const userUpdate = computed(() => store.getters['global/userUpdate'])
const setAvatar = () => avatarPath.value = `/api/user/${props.userId}/image?${Date.now()}`

watch(() => userUpdate.value, (val) => {
  if (!val || val.id === props.userId) {
    setAvatar()
  }
}, { immediate: true })

const onErr = () => {
  avatarPath.value = defaultAvatar.value
  noAvatar.value = true
}
const activateTooltip = () => {
  tooltip.value = true
}
const deactivateTooltip = () => {
  tooltip.value = false
  setTimeout(() => {
    copyTriggered.value = false
  }, 500)
}
const vmSpan = ref()
const copyId = () => {
  copyTriggered.value = true
  if (vmSpan.value) {
    const text = vmSpan.value.textContent
    const $input = document.createElement('input')
    $input.value = text
    vmSpan.value.appendChild($input)
    $input.select()
    navigator.clipboard.writeText(text)
    $input.remove()
  }
}
</script>

<style scoped lang="scss">
.offline-avatar {
  filter: grayscale(100%);
}

.online-status {
  position: absolute;
  bottom: 2.5px;
  right: 2.5px;

  &::before {
    content: '';
    display: flex;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background-color: $ErrorColor;
  }
}

.is-online {
  &::before {
    background-color: $ActionColor;
  }
}

.vm-id {
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  opacity: 0.21;
  width: 16px;
  height: 16px;
  font-size: 12px;
}

.vm-id-tooltip {
  background: #09212C;
  border: 1px solid rgba(0, 240, 255, 0.3);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.vm-id-tooltip-text {
  color: #00F0FF;
  text-align: center;
}

</style>
