export const taskEn = {
  task_card: {
    txt: {
      no_linked_tasks: 'There are no linked tasks', // newIdLoc
      no_missions: 'There are no missions', // newIdLoc
      no_comments: 'There are no comments', // newIdLoc
      add_file: 'Add file' // newIdLoc,
    },
    lbl: {
      bread_crumbs_root: 'Tasks',
      number_task: 'Task',
      assigned: 'Assigned by:',
      updated: 'Updated by:',
      updated_month_jan: 'jan.',
      updated_month_feb: 'feb.',
      updated_month_mar: 'mar.',
      updated_month_apr: 'apr.',
      updated_month_may: 'may',
      updated_month_june: 'june',
      updated_month_july: 'july',
      updated_month_aug: 'aug.',
      updated_month_sept: 'sept.',
      updated_month_oct: 'oct.',
      updated_month_nov: 'nov.',
      updated_month_dec: 'dec.',
      updated_time: {
        min: 'minute(s) ago',
        hour: 'hour(s) ago',
        yesterday: 'yesterday',
      },
      assignee_not_found: 'Addresses not found',
      tags_not_found: 'Tags not found',
      drag_file: 'Drag-and-drop file',
      history_events_month_jan: 'jan.',
      history_events_month_feb: 'feb.',
      history_events_month_mar: 'mar.',
      history_events_month_apr: 'apr.',
      history_events_month_may: 'may',
      history_events_month_june: 'june',
      history_events_month_july: 'july',
      history_events_month_aug: 'aug.',
      history_events_month_sept: 'sept.',
      history_events_month_oct: 'oct.',
      history_events_month_nov: 'nov.',
      history_events_month_dec: 'dec.',
      history: {
        changed_status_from: 'Changed status of task from ',
        changed_status_to: 'to',
        assigned: 'Assigned',
        assigned_assignee: 'as Assignee',
        changed_name_from: 'Changed name of task from ',
        changed_name_to: 'to',
        changed_descr: 'Changed description of task from ',
        added_mission: 'Added mission',
        deleted_mission: 'Deleted mission',
        added_infr: 'Added infrastructure',
        deleted_infr: 'Deleted infrastructure',
        added_file: 'Added file',
        deleted_file: 'Deleted file',
        changed_tags_from: 'Changed list of tags from ',
        changed_tags_to: 'to',
        added_link: 'Added link to task',
        deleted_link: 'Removed link to task',
      },
      write_comment: 'commented',
      comments_month: {
        jan: 'jan.',
        feb: 'feb.',
        mar: 'mar.',
        apr: 'apr.',
        may: 'may',
        june: 'june',
        july: 'july',
        aug: 'aug.',
        sept: 'sept.',
        oct: 'oct.',
        nov: 'nov.',
        dec: 'dec.',
      },
      comments_time: {
        min: 'minute(s) ago',
        hour: 'hour(s) ago',
        yesterday: 'yesterday',
      },
      mission: 'Mission'
    },

    lst: {
      status: 'Status:',
      status_in_progress: 'In progress',
      status_canceled: 'Canceled',
      status_completed: 'Completed',
      status_not_assigned_plh: 'Unassigned',
      status_assigned_plh: 'Assigned',
      priority: 'Priority:',
      priority_high: 'High',
      priority_medium: 'Medium',
      priority_low: 'Low',
      assignee: 'Assignee:',
      assignee_search_plh: 'Search',
      add_mission: 'Add mission',
      add_mission_plh: 'Search…',
      add_infrastructure: 'Add infrastructure',
      add_infrastructure_plh: 'Search',
      linked_tasks_plh: 'Search',

    },

    btn: {
      send: 'Send',
      install: 'Install',
      installed: 'Installed',
      select_file: 'Select file',
      publish: 'Publish',
      link_task: 'Link task' // newIdLoc
    },

    edit: {
      task_name: 'Task',
      task_name_plh: 'Enter task name',
      description: 'Task description',
      description_plh: 'Describe task in detail, indicate goals, means and deadlines ...',
      add_comment_plh: 'Add comment or task report',
      tags: 'Enter tag', // newIdLoc
    },

    hdr: {
      files: 'Files',
      missions_and_infrastructures: 'Missions',
      linked_tasks: 'Linked tasks',
      history_events: 'Change history',
      comments: 'Comments',
      add_comment: 'Add comment',
      tags: 'Tags', // newIdLoc
      management: 'Task management'
    },

    delete_dialog: {
      title: 'Remove task',
      question: 'Are you sure you want to delete the task?',
      cancel_button: 'Cancel',
      submit_button: 'Remove'
    },
    files_delete_dialog: {
      title: 'Remove file',
      question: 'Are you sure you want to delete the file from file list?',
      cancel_button: 'Cancel',
      submit_button: 'Remove'
    },
    mission_delete_dialog: {
      title: 'Delete mission',
      question: 'Do you really want to delete mission?',
      cancel_button: 'No',
      submit_button: 'Yes'
    },

    popup_overwriting: {
      hdr: {
        header: 'Reinstallation mission'
      },
      txt: {
        info: 'This mission is installed!\nWould you like to reinstall mission?'
      },
      btn: {
        cancel: 'Cancel', // newIdLoc
        install: 'Install' // newIdLoc
      }
    },
    popup_set_mission: {
      hdr: {
        header: 'Install mission'
      },
      txt: {
        info: 'Do you wish to install mission?'
      },
      btn: {
        no: 'No',
        yes: 'Yes'
      }
    },
    popup_file_name_error: {
      hdr: {
        header: 'File upload'
      },
      txt: {
        info: 'File with the same name is already attached to task',
      },
      btn: {
        ok: 'OK'
      }
    },
    popup_file_size_error: {
      hdr: {
        header: 'File upload'
      },
      txt: {
        info: 'Maximum file size exceeded',
      },
      btn: {
        close: 'Close' // newIdLoc
      }
    }
  },

  task_list_hdr: {
    all_tasks: 'Tasks list' // newIdLoc
  },
  task_list: {
    lst: {
      all_tasks: 'All tasks',
      my_tasks: 'My tasks',
      created_tasks: 'Created tasks',
      priority: {
        high: 'High',
        medium: 'Medium',
        low: 'Low',
      },
      status: {
        unassigned: 'Unassigned',
        assigned: 'Assigned',
        in_progress: 'In progress',
        canceled: 'Canceled',
        completed: 'Completed',
      },
      unassigned: 'Unassigned'
    },
    lbl: {
      search_not_found_1: 'Tasks are not found. Change search criteria.',
      search_not_found_2: 'Nothing found! Try softening your search criteria.',
      paging: 'Items per page:',
      paging_of: 'of',
      updated_month: {
        jan: 'jan.',
        feb: 'feb.',
        mar: 'mar.',
        apr: 'apr.',
        may: 'may',
        june: 'june',
        july: 'july',
        aug: 'aug.',
        sept: 'sept.',
        oct: 'oct.',
        nov: 'nov.',
        dec: 'dec.',
      },
      segment: 'Segment:'
    },
    edit: {
      search_task_plh: 'Enter search term'
    },
    btn: {
      add_task: 'Create task'
    },
    hdr_column: {
      number_task: '№',
      name_task: 'Task',
      priority: 'Priority',
      status: 'Status',
      assigned_by: 'Assigned by',
      assignee: 'Assignee',
      updated_at: 'Updated at'
    },
    txt: {
      records_found: 'Records found:' // newIdLoc
    }
  },
}
