<template>
  <div class="tools">
    <canvas id="player"></canvas>
    <template v-if="!props.eventId">
      <ToolsPanel
        @addComp="showComponents"
        @addIcon="showComponents"
        @addGifEffect="addGifEffect = true"
      />
      <ComponentsPanel @addComp="showComponents" @addIcon="showComponents" />
      <OptionsPanel v-if="selected" />
      <BaseObjPopup v-show="baseObj" />
      <ToolsTriggersDialog v-if="isTriggersDialogShown" @close="closeTriggersDialogShown" />
      <AddComp v-if="addComp" @showComponents="showComponents" @handleEdit="handleEdit" />
      <AddIcon v-if="addIcon" @showComponents="showComponents" @handleEdit="handleEdit" />
      <AddGifEffect v-if="addGifEffect" @showAddGifEffect="addGifEffect = false" />
      <ContextMenu v-show="contextShowMenu" />
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeMount, onBeforeUnmount, onUnmounted } from 'vue'
import { useStore } from '@/store'
import { tools } from '@/plugins/map/constructor/tools'
import { constructor } from '@/plugins/map/constructor/player'
import { i18n } from '@/i18n-setup'
import eventService, { DEFENCE } from '@/services/event.service'
import ToolsTriggersDialog from './Triggers/ToolsTriggersDialog'
import AddIcon from './tools/AddIcon'
import AddGifEffect from './tools/AddGifEffect'
import AddComp from './tools/AddComp'
import OptionsPanel from './tools/OptionsPanel'
import ToolsPanel from './tools/ToolsPanel'
import ComponentsPanel from './tools/ComponentsPanel'
import BaseObjPopup from './tools/BaseObjPopup'
import ContextMenu from './tools/ContextMenu'

const store = useStore()

const props = defineProps({
  eventId: {
    type: Number,
    required: false
  }
})

const edit = ref(false)
const addComp = ref(false)
const addIcon = ref(false)
const currentMapConfig = ref(null)
const addGifEffect = ref(false)
const intervalForCheckConfig = ref(null)

const currentEvent = computed(() => store.getters['global/currentEvent'])
const selected = computed(() => store.getters.selected)
const contextShowMenu = computed(() => store.getters.showContextMenu)
const selectedPoint = computed(() => store.getters.selectedPoint)
const isTriggersDialogShown = computed(() => store.getters.triggersDialogShown)
const baseObj = computed(() => store.getters.switchBaseObj)
const isAnyPopupShown = computed(() =>
  addComp.value || addIcon.value || addGifEffect.value || isTriggersDialogShown.value)

watch(() => currentMapConfig.value, (val) => {
  if (!baseObj.value) localStorage.setItem('savedConfig', val)
}, { deep: true })

const hotKeys = (e) => {
  if (isTriggersDialogShown.value) return
  if (e.keyCode === 45 && e.ctrlKey === true) {
    tools.CopyTriggerMenu()
  }
  if (e.keyCode === 45 && e.shiftKey === true) {
    tools.PasteTriggerMenu()
  }
  if (e.keyCode === 46 && !['TEXTAREA', 'INPUT'].includes(e.target.nodeName)) {
    selectedPoint.value ? tools.DeletePoint(selectedPoint.value) : tools.DeleteObjects()
  }
  if (e.shiftKey === true && !isAnyPopupShown.value) {
    switch (e.keyCode) {
      case 49:
        switchTool()
        store.commit('selected', constructor.scene)
        tools.Placement()
        break
      case 50:
        switchTool()
        tools.AddLine()
        break
      case 51:
        switchTool()
        tools.Connect()
        break
      case 52:
        switchTool('line')
        tools.AddPointInLine()
        break
      case 53:
        switchTool()
        tools.MakePlane()
        break
      case 54:
        switchTool('plane')
        tools.EditPlane()
        break
      case 55:
        switchTool()
        tools.MakeTextField(e)
        break
      case 56:
        switchTool()
        store.commit('selected', constructor.scene)
        tools.Placement()
        store.commit('setSwitchBaseObj', true)
        break
      case 57:
        switchTool('essence')
        tools.MoveIcon(constructor, this.selected)
        break
      case 48:
        constructor.ScreenShot()
        break
    }
  }
  // Ctrl + A
  if (
    e.keyCode === 65 &&
    e.ctrlKey === true &&
    !isAnyPopupShown.value &&
    !['TEXTAREA', 'INPUT'].includes(e.target.nodeName)
  ) {
    e.preventDefault()
    const selectedAll = {
      essence: constructor.essence,
      lines: constructor.lines,
      planes: constructor.planes,
      textFields: constructor.textFields
    }
    store.commit('setSelectedPoint', null)
    store.commit('setSelectedAll', selectedAll)
    if (store.getters.countOfSelected === 1) {
      store.commit('selected', Object.values(selectedAll).find(item => item.length)[0])
    }
  }
  if (!['TEXTAREA', 'INPUT'].includes(e.target.nodeName)) {
    tools.CopyPasteObjects(e)
  }
}

const switchTool = (type) => {
  if (tools.makePlane) constructor.planes.pop().removePlaneHandler()
  if (tools.makeText) constructor.textFields.pop().removeTextHandler()
  if (!type || ![selected.value?.designation, selected.value?.name].includes(type)) {
    tools.clearSelectionObjects()
  }
}
const handleShowContextMenu = (e) => {
  if (isTriggersDialogShown.value) return
  tools.ContextMenu(e)
}
const preventNav = (event) => {
  if (!edit.value) return
  event.preventDefault()
  event.returnValue = ''
}
const handleEdit = (value) => {
  edit.value = value
}
const confirmation = (value) => {
  if (confirm(i18n.t('user_panel_popup_create_user_txt_unsaved_data'))) {
    if (value.name === 'addComp') {
      addIcon.value = false
      addComp.value = value.isOpen
      edit.value = false
    } else if (value.name === 'addIcon') {
      addComp.value = false
      addIcon.value = value.isOpen
      edit.value = false
    }
  }
}
const showComponents = (value) => {
  if (value.name === 'addComp') {
    if (edit.value) {
      confirmation(value)
    } else {
      addIcon.value = false
      addComp.value = value.isOpen
    }
  } else if (value.name === 'addIcon') {
    if (edit.value) {
      confirmation(value)
    } else {
      addComp.value = false
      addIcon.value = value.isOpen
    }
  }
}
const mouseAction = (e) => {
  if (e.type === 'mousedown' && e.which === 2) tools.HandMove()
  if (e.type === 'mouseup' && e.which === 2) tools.Placement()
  if (e.type === 'click') {
    store.commit('setShowContextMenu', false)
    store.commit('setClickCoords', {
      x:
        (e.pageX + store.state.map.players[0]?.translateX * -1) /
        store.state.map.players[0]?.zoom,
      y:
        (e.pageY + store.state.map.players[0]?.translateY * -1) /
        store.state.map.players[0]?.zoom
    })
  }
}
const getCurrentMapConfig = () => {
  const config = tools.GetConfig()
  currentMapConfig.value = JSON.stringify({
    essence: config.essence,
    lines: config.lines,
    planes: config.planes,
    textFields: config.textFields,
    scene: config.scene
  })
}
const checkSavedMapConfig = () => {
  const savedConfig = localStorage.getItem('savedConfig')
  if (savedConfig) tools.UploadSaved(JSON.parse(savedConfig))
}
const closeTriggersDialogShown = () => {
  store.commit('setTriggersDialogShown', false)
}
const leavePage = (e) => {
  if (!e) e = window.event
  e.cancelBubble = true
  e.returnValue = 'You sure you want to leave?'
}
const getGameState = () => {
  eventService
    .getGameState(props.eventId)
    .then(data => {
      return store.dispatch('base', data)
    })
    .then(() => {
      constructor.Update()
    })
}
const resizeContent = () => constructor.Resize()

onMounted(() => {
  constructor.cvs = document.getElementById('player')
  constructor.ctx = constructor.cvs.getContext('2d')
  constructor.Play()
  constructor.Scroll()
  store.commit('players', constructor)
  store.commit('setDefaultBase')
  window.addEventListener('resize', resizeContent)

  store.dispatch('loadComp').then(() => {
    store.dispatch('loadIcons')
    store.dispatch('loadGifEffects')
    if (props.eventId) {
      if (currentEvent.value.gameType.toLowerCase() === DEFENCE) {
        eventService.getNetworkConfig(props.eventId).then(data => {
          getGameState()
          tools.UploadSaved(data)
          constructor.SetStartAndEndCoordinates(data)
          constructor.ScaleAndCenter()
        })
      }
      tools.HandMove()
      store.commit('setPlayerGame', constructor)
    } else {
      checkSavedMapConfig()
      intervalForCheckConfig.value = setInterval(getCurrentMapConfig, 4000)
      store.dispatch('tools', tools)
      tools.Placement()
      store.commit('selected', constructor.scene)
      document.addEventListener('keydown', hotKeys)
      document.addEventListener('click', mouseAction)
      document.addEventListener('mouseup', mouseAction)
      document.addEventListener('mousedown', mouseAction)
      document.addEventListener('contextmenu', handleShowContextMenu)
      window.addEventListener('beforeunload', leavePage)
    }
  })
})

onBeforeMount(() => {
  window.addEventListener('beforeunload', preventNav)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', hotKeys)
  document.removeEventListener('click', mouseAction)
  document.removeEventListener('mouseup', mouseAction)
  document.removeEventListener('mousedown', mouseAction)
  document.removeEventListener('contextmenu', handleShowContextMenu)
  window.removeEventListener('beforeunload', preventNav)
  window.removeEventListener('resize', resizeContent)
  window.removeEventListener('beforeunload', leavePage)
})

onUnmounted(() => {
  constructor.Stop()
  if (!props.eventId) {
    clearInterval(intervalForCheckConfig.value)
    store.commit('setIsConfigOpened', false)
    constructor.Refresh()
  } else {
    constructor.translateX = 0
    constructor.translateY = 0
    constructor.zoom = 1
  }
  store.commit('removePlayer')
})
</script>

<style scoped>
#player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
</style>
