<template>
  <v-card
      v-if="state.isLoaded"
      class="fill-height run-event-background"
      flat
      tile
      ref="runEventCard"
      tabindex="0"
      autofocus
      :ripple="false"
      @keydown.esc="onKeydownEsc()"
  >
    <MapMaterials
        v-model="popups.isMapMaterialsVisible"
        :id="eventId"
        :name="event.name"
        type="event"
        :game-type="eventGameType"
    />
    <Materials v-if="!isCtfOrResistance" v-model="popups.isMaterialsVisible" :id="eventId" type="event" />
    <Tooltip
        v-model="popups.isTooltipVisible"
        :vector="tooltipData.vector"
        :stage="tooltipData.stage"
        :tooltip-text="tooltipData.text"
    />
    <CbrDialog
        v-model="popups.isFinishVisible"
        max-width="600"
        @input="closeFinishDialog"
    >
      <template #header>
        <CbrHint
          bottom
          :description="event.name"
          no-icon
        >
          <span class="dialog-finish-event_hdr">
            {{ event.name }}
          </span>
        </CbrHint>
      </template>
      <template #content>
        <span class="dialog-finish-event_text">
          {{ $t('change_event_status_popup.finish.txt') }}
        </span>
      </template>
      <template #footer>
        <CbrButton
            id="run-event-game-end-ok-button"
            @click="closeFinishDialog"
            :text="$t('event.popup_event_debriefed.btn.ok')"
        />
      </template>
    </CbrDialog>
    <CbrDialog v-model="popups.isVmReassignVisible" max-width="600"
               @input="closeReassignDialog"
               :title="$t('event_editing_popup_assignment_VM_hdr_new_VM')"
    >
      <template #content>
        <div class="dialog-message-block">
          {{ $t('event_editing_popup_assignment_VM_txt_reload_page') }}
        </div>
      </template>
      <template #footer>
        <CbrButton
            id="run-event-user-vm-reassign-ok-button"
            @click="closeReassignDialog"
            :text="$t('event.popup_event_debriefed.btn.ok')"
        />
      </template>
    </CbrDialog>
    <ConfirmDialog
      v-model="reloadVMConfirmation"
      :title="$t('event_card_popup_reboot_hdr_vm')"
      :first-line-text="$t('event_card_popup_reboot_txt_question')"
      :warning-text="$t('event_card_popup_reboot_txt_question_descr')"
      :yesText="$t('event_card_popup_reboot_btn_confirm')"
      :noText="$t('event_card_popup_reboot_btn_cancel')"
      yesBtnId="reload-vm-ok-button"
      noBtnId="reload-vm-cancel-button"
      max-width="976px"
      @success="reloadVM"
    />
    <div
      v-if="isUser"
      class="right-menu-chevron"
      :class="{ expanded: !rightMenuHidden }"
      @click="rightMenuHidden ? displayRightMenu() : hideRightMenu()"
    >
      <div v-if="!rightMenuHidden" class="right-menu-chevron-background"></div>
      <CbrIcon v-if="!rightMenuHidden" class="right-menu-chevron-borders">$right_chevron_borders</CbrIcon>
      <CbrIcon class="right-menu-chevron-icon">$up</CbrIcon>
    </div>
    <v-row>
      <Tools :event-id="eventId" v-if="!isUser" />
      <v-col>
        <div class="right-menu">
          <div
              class="current-target-container"
              :class="{ 'user-style-right-menu': isUser, hidden: isUser && rightMenuHidden }"
          >
            <div
                class="menu-container"
            >
              <div
                  class="menu-button"
                  :class="[selectedTabMenuItem === item.id ? 'selected-menu-button' : '']"
                  :id="item.id"
                  :key="i"
                  @click="selectTabMenuItem(item.id)"
                  v-for="(item, i) in tabMenuItemsByGameType"
              >
                <CbrHint bottom noTitle :description="$t(item.tooltip)">
                  <div>
                    <div
                        :class="{ triangle: selectedTabMenuItem === item.id }"
                        :style="{ top: `${20 + 52 * i}px` }"
                    ></div>
                    <CbrIcon size="36" :style="{ top: `${8 + 52 * i}px` }">{{ item.icon }}</CbrIcon>
                    <div class="selected-menu-button-rect" v-if="selectedTabMenuItem === item.id"
                    ></div>
                  </div>
                </CbrHint>
              </div>
            </div>
            <div v-if="selectedTabMenuItem" class="data-container"
                 :class="{ 'fullscreen': isFullscreen }">
              <div class="data-container-fr">
                <div class="data-container-title d-flex">
                  <div
                      class="data-container-title-hide pointer"
                      id="show-hide-members-list-button"
                      @click="closeTabMenuItem()"
                  >
                    <CbrIcon>$triangle_right</CbrIcon>
                  </div>
                  <div class="data-container-title-vr"/>
                  <div v-if="selectedTabMenuItem === MENU_ITEM_LOG"
                      class="data-container-title-hide fullscreen pointer"
                      id="show-fullscreen-log-button"
                      @click="isFullscreen = !isFullscreen"
                  >
                    <CbrIcon>
                      {{ isFullscreen ? '$minimize_chat_2' : '$expand_chat_2' }}
                    </CbrIcon>
                  </div>
                  <div v-if="selectedTabMenuItem === MENU_ITEM_LOG" class="data-container-title-vr"/>
                  <div class="data-container-title-text">
                    {{ dataContainerTitle }}
                  </div>
                </div>
                <div class="data-container-content">
                  <div v-if="selectedTabMenuItem === MENU_ITEM_MEMBERS_LIST" class="data-container-content-team-fr">
                    <div class="data-container-content-team-card-list">
                      <div v-if="$route.params.type === DEFENCE" class="data-container-content-team-title">
                        <div class="data-container-content-team-title-fr">
                          <span>{{ $t('event_screen_popup_particip_hdr_defenders') }}</span>
                        </div>
                      </div>
                      <div
                          v-for="user in sortedParticipants"
                          class="data-container-content-team-card"
                          :class="hoveredUserId === user.id ? 'user-card-hovered' : ''"
                          :key="user.id"
                      >
                        <div class="data-container-content-team-card-fr">
                          <div class="data-container-content-team-card-avatar">
                            <Avatar
                                :size="64"
                                class="pointer"
                                :is-show-online="true"
                                :user-id="user.id"
                                :is-online="isOnline(user)"
                                :vm-id-visible="isSystemAdmin"
                                :vm-id="user.vmId"
                                @click="showProfile(user)"
                                @mouseover="setHoveredUser(user)"
                                @mouseout="unhoverUser"
                            />
                          </div>
                          <div class="data-container-content-team-card-title">
                            <div class="data-container-content-team-card-title-name"
                                @mouseover="setHoveredUser(user)"
                                @mouseout="unhoverUser"
                                :class="isOnline(user) ? 'user-name-online' : 'user-name-offline'"
                              >
                              <CbrHint
                                bottom
                                no-icon
                                :title="user.firstName + ' ' + user.middleName + ' ' + user.lastName "
                              >
                                <span
                                  class=" text-ellipsis d-block data-container-content-team-card-title-name-value"
                                  :class="{'offline': !isOnline(user)}"
                                >
                                  {{ user.firstName + ' ' + user.middleName + ' ' + user.lastName }}
                                </span>
                              </CbrHint>
                            </div>
                            <div class="data-container-content-team-card-title-position"
                                @mouseover="setHoveredUser(user)"
                                @mouseout="unhoverUser"
                            >
                              <CbrHint bottom no-icon :title="user.position">
                                <span
                                  class="text-ellipsis d-block data-container-content-team-card-title-position-value"
                                  :class="{'offline': !isOnline(user)}"
                                >
                                  {{ user.position }}
                                </span>
                              </CbrHint>
                            </div>
                          </div>
                          <div class="data-container-content-team-card-view"
                               :class="{'offline': !isOnline(user)}"
                               v-if="isAdmin">
                            <Icon
                              v-if="selectedParticipant && user.id === selectedParticipant.id"
                              class="user-hide-console-btn"
                              icon-name="event/event_disconnect_user_console"
                              width="36px"
                              height="36px"
                              @click.native="selectedParticipant = null"
                            />
                            <CbrButton
                              v-else
                              class="user-show-console-btn"
                              icon="mdi-eye"
                              @click="selectUser(user)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedTabMenuItem === MENU_ITEM_CURRENT_TARGET" class="data-container-content-goal-title">
                    <div class="data-container-content-goal-title-fr">
                      <div class="data-container-content-goal-title-fr-header">
                        <span class="data-container-content-goal-title-fr-header-vector">
                          {{ $t('event_screen_popup_phase_task_lbl_vector') }} {{ targetData.vector }}:
                        </span>
                        <span class="data-container-content-goal-title-fr-header-stage">
                          {{ $t('mission_card_lst_phase') }} {{ targetData.stage }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedTabMenuItem === MENU_ITEM_CURRENT_TARGET" class="data-container-content-goal-card">
                    <div class="data-container-content-goal-card-fr">
                      <div class="data-container-content-goal-card-fr-body">
                        <div class="data-container-content-goal-card-fr-body-fr">
                          <div class="data-container-content-goal-card-fr-body-fr-text">
                            <span>
                              {{ targetData.text }}
                            </span>
                          </div>
                          <div class="data-container-content-goal-card-fr-body-fr-line"/>
                          <div class="data-container-content-goal-card-fr-body-fr-hint">
                            <div
                                @click="getTooltip()"
                                v-if="targetData.hintAvailable"
                                id="get-event-target-tip"
                                class="target-data-text-bottom"
                            >
                              <div>{{ $t('event_screen_popup_phase_task_btn_get_hint') }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedTabMenuItem === MENU_ITEM_LOG" class="data-container-content-log-card">
                    <div class="data-container-content-log-card-fr">
                      <EventLog
                          ref="log"
                          :eventLogData="eventLogArray"
                          :event-id="eventId"
                          :is-observer="isObserver"
                          :started-at="event.startedAt"
                          :is-fullscreen="isFullscreen"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <UserConsole
          v-if="selectedParticipant"
          @close="selectedParticipant = null"
          :event="event"
          :fixed="!isUser"
          :user="selectedParticipant"
          class="user-console-start-container"
          :guacamole-mode="true"
          :isReloadingVM="isReloadingVM"
          ref="userConsole"
          tabindex="1"
          @keydown.esc="onKeydownEsc()"
          @reloading-vm="refreshStateReload($event)"
      />
    </v-row>
  </v-card>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import store from '@/store'
import roles from '@/helpers/roles.helper'
import eventService, { CTF, DEFENCE, RESISTANCE } from '@/services/event.service'
import users from '@/helpers/users.helper'
import events from '@/helpers/events.helper'
import { i18n } from '@/i18n-setup'
import mainHelper from '@/helpers/main.helper'
import { constructor } from '@/plugins/map/constructor/player'
import _ from 'lodash'
import { SaveMessagesModes } from '@/services/tabs-sync'
import UserConsole from '@/components/event/console/UserConsole'
import Avatar from '@/components/user/Avatar'
import Materials from '@/components/map/Materials'
import MapMaterials from '@/components/map/MapMaterials'
import EventLog from '@/views/event/log/EventLog'
import Tooltip from '@/views/event/Tooltip'
import Tools from '@/components/map/Tools'
import { useStateLoading } from '@/composables/useStateLoading'
import { useSSE } from '@/composables/useSSE'
import { useRoute, useRouter } from 'vue-router/composables'

const MENU_ITEM_MEMBERS_LIST = 'members-list'
const MENU_ITEM_CURRENT_TARGET = 'current-target'
const MENU_ITEM_LOG = 'log'

const vueCurrentInstance = getCurrentInstance().proxy
const { state, setStateLoaded } = useStateLoading()
let { eventsSubscribesMap } = useSSE()
const router = useRouter()
const route = useRoute()

const event = ref({})
const selectedParticipant = ref({})
const hoveredUserId = ref(null)
const selectedTabMenuItem = ref(null)
const tabMenuItems = ref([])
const targetData = ref({
  vector: 1,
  stage: 1,
  text: '',
  hintAvailable: false,
  vectorId: '',
  stageId: ''
})
const tooltipData = ref({
  vector: 1,
  stage: 1,
  text: ''
})
const attributes = ref({})
const isFullscreen = ref(false)
const popups = ref({
  isMapMaterialsVisible: false,
  isMaterialsVisible: false,
  isTooltipVisible: false,
  isFinishVisible: false,
  isVmReassignVisible: false
})
const vmIds = ref({})
const eventLogData = ref({})
const eventLogArray = ref([])
const rightMenuHidden = ref(true)
// const guacamoleModeFlag = ref(true)
const mainElement = ref(null)
const reloadVMConfirmation = ref(false)
const isReloadingVM = ref(false)
const debounceGetGameState = ref(null)
const eventId = ref(null)

// beforeRouteLeave (to, from, next) {
//   next()
// },

onMounted(async () => {
  // guacamoleModeFlag.value = sessionStorage.getItem('console_mode') !== `${eventId.value}_false`
  tabMenuItems.value = [
    {
      icon: 'mdi-account-group',
      id: MENU_ITEM_MEMBERS_LIST,
      tooltip: 'event_screen_hint_btn_participants_list'
    },
    {
      icon: '$event_target',
      id: MENU_ITEM_CURRENT_TARGET,
      tooltip: 'event_screen_hint_btn_tasks'
    },
    {
      icon: '$log',
      id: MENU_ITEM_LOG,
      tooltip: 'event_screen_hint_btn_history_log'
    }
  ]

  await loadEvent()
  hideRightMenu()
})

const userRole = computed(() => store.getters['account/userRole'])
const isUser = computed(() => store.getters['account/isUser'])
const isAdmin = computed(() => store.getters['account/isAdmin'])
const isSystemAdmin = computed(() => store.getters['account/isSystemAdmin'])
const currentUserId = computed(() => store.getters['account/currentUserId'])
const isObserver = computed(() => store.getters['account/isObserver'])
const currentEventState = computed(() => store.getters['global/currentEvent']?.eventState)

const showMaterials = computed(() => store.getters['global/showMaterials'])
const showNetworkMap = computed(() => store.getters['global/showNetworkMap'])
const showScoreBoard = computed(() => store.getters['global/showScoreBoard'])
const showEventLog = computed(() => store.getters['global/showEventLog'])
const showReport = computed(() => store.getters['global/showReport'])
const showUserDialog = computed(() => store.getters['global/showUserDialog'])
// const guacamoleMode = computed(() => store.getters['global/guacamoleMode'])

const dataContainerTitle = computed(() => {
  switch (selectedTabMenuItem.value) {
    case MENU_ITEM_MEMBERS_LIST:
      return i18n.t('event_screen_popup_particip_hdr_team')
    case MENU_ITEM_CURRENT_TARGET:
      return i18n.t('event_screen_popup_phase_task_hdr_current_task')
    case MENU_ITEM_LOG:
      return i18n.t('event_screen_popup_history_hdr_event_history')
    default:
      return ''
  }
})
const isCtfOrResistance = computed(() => {
  return [CTF, RESISTANCE].includes(route.params.type.toLowerCase())
})
const tabMenuItemsByGameType = computed(() => {
  if (route.params.type === DEFENCE) {
    return tabMenuItems.value
  } else {
    return tabMenuItems.value.filter(({ id }) => id === MENU_ITEM_MEMBERS_LIST)
  }
})
const isShowTarget = computed(() => {
  return !(isUser.value && event.value.exercise)
})
const sortedParticipants = computed(() => {
  if (isCtfOrResistance.value) {
    return event.value.teams.reduce((participants, team) => {
      return [...participants, ...team.participants]
    }, [])
  }
  return event.value.teams[0].participants.sort((a, b) =>
    comparedString(a).localeCompare(comparedString(b))
  )
})
const eventGameType = computed(() => event.value?.gameType?.toLowerCase())

// watch(() => popups.value.isVmReassignVisible, () => {
//   isReloadingVM.value = true
//   nextTick(() => router.go())
// })
watch(() => showMaterials.value, (val) => {
  if (val) {
    popups.value.isMaterialsVisible = true
    store.commit('global/showMaterials', null)
  }
})
watch(() => showUserDialog.value, (val) => {
  if (val) {
    reloadVMConfirmation.value = true
    store.commit('global/showUserDialog', null)
  }
})
watch(() => showScoreBoard.value, (params) => {
  if (params) {
    mainHelper.processRoute(
      {
        name: 'scoreboard'
      },
      params[1]
    )
    store.commit('global/showScoreBoard', null)
  }
})
watch(() => showEventLog.value, (val) => {
  if (val) {
    const route = router.resolve({
      name: 'EventCard',
      params: { id: eventId.value },
      query: { tab: 'console' }
    })
    const win = window.open(route.href, '_blank')
    win.focus()
    store.commit('global/showEventLog', null)
  }
})
watch(() => showReport.value, (eventId) => {
  if (eventId) {
    sendReport(eventId)
    store.commit('global/showReport', null)
  }
})
watch(() => showNetworkMap.value, (val) => {
  if (val) {
    popups.value.isMapMaterialsVisible = true
    store.commit('global/showNetworkMap', null)
  }
})
// watch(() => guacamoleMode.value, (mode) => {
//   if (mode !== null) {
//     // eslint-disable-next-line no-console
//     console.log(`guacamoleMode ${mode}`)
//     guacamoleModeFlag.value = mode
//     store.commit('global/guacamoleMode', null)
//   }
// })
watch(() => currentEventState.value, (currentStatus, oldStatus) => {
  if (oldStatus !== undefined && [events.FINISHING.key, events.FINISHED.key].includes(currentEventState.value)) {
    popups.value.isFinishVisible = true
  }
})

const refreshStateReload = (action) => {
  isReloadingVM.value = action
}
const initEventsSubscribesMap = () => {
  eventsSubscribesMap.value = {
    [`/api/message/bus/event/${route.params.type}/${eventId.value}`]: {
      handler: (msg) => {
        const parsedMsg = JSON.parse(msg)
        const type = parsedMsg.type
        if (type === 'EVENT_UPDATED' || type === 'EVENT_EDITED') {
          eventService.getEvent(eventId.value, route.params.type).then(event => {
            store.commit('global/setCurrentEvent', event)
          })
        } else if (type === 'VM_REASSIGNED' && parsedMsg.newUserId === currentUserId.value) {
          popups.value.isVmReassignVisible = true
        } else if (type === 'PHASE_CHANGED') {
          getCurrentTarget()
        } else if (type === 'EVENT_DELETED') {
          redirectToList()
        } else if (type === 'USER_ONLINE') {
          const user = event.value.teams[0].participants.find(({ id }) => id === parsedMsg.userId)
          if (user) user.online = 'ONLINE'
        } else if (type === 'USER_OFFLINE') {
          const user = event.value.teams[0].participants.find(({ id }) => id === parsedMsg.userId)
          if (user) user.online = 'OFFLINE'
        }
      }
    }
  }
  if (route.params.type === DEFENCE) {
    eventsSubscribesMap.value[`/api/message/stream/logs/${route.params.type}/${eventId.value}?lang=${i18n.locale}`] = {
      handler: (msgS) => {
        const msg = JSON.parse(msgS)
        eventLogData.value[msg.timestamp] = msg
        eventLogArray.value = Object.values(eventLogData.value)
        const eventLog = vueCurrentInstance.$refs.log
        if (eventLog) eventLog.scrollMessagesToBottom()
        if (constructor.ctx) {
          debounceGetGameState.value()
        }
      },
      saveMessageMode: SaveMessagesModes.ALL
    }
  }
}
const reloadVM = () => {
  eventService.reloadVM(event.value.id, undefined, event.value.gameType).then(() => {
    reloadVMConfirmation.value = false
    isReloadingVM.value = true
  })
}
const showProfile = (user) => {
  users.showProfile(user)
}
const loadEvent = () => {
  eventService
    .getEvent(eventId.value, route.params.type)
    .then(ev => {
      checkRedirect(ev.eventState)
      event.value = ev
      if (userRole.value === roles.USER.key) {
        selectedParticipant.value = { id: currentUserId.value }
      }
      store.commit('global/setCurrentEvent', ev)
    })
    .then(() => {
      if (eventGameType.value === DEFENCE) {
        getAttributes()
      }
    })
    .then(() => {
      if (eventGameType.value === DEFENCE) {
        getCurrentTarget()
      }
    })
    .then(() => {
      if (eventGameType.value === DEFENCE) {
        getVmIds()
      }
    })
    .finally(() => {
      setStateLoaded()
    })
}
const sendReport = (eventId) => {
  if (isUser.value) {
    const route = router.resolve({
      name: 'EventPlayerReport',
      params: { eventId: eventId, type: eventGameType.value ?? DEFENCE }
    })
    window.open(route.href, '_blank').focus()
  }
}
const getAttributes = () => {
  if (userRole.value !== roles.USER.key) {
    eventService.getAttributes(eventId.value).then(res => {
      attributes.value = res
    })
  }
}
const closeFinishDialog = () => {
  popups.value.isFinishVisible = false
  if (currentEventState.value === events.FINISHED.key) {
    redirectToCard()
  }
}
const getCurrentTarget = () => {
  if (isShowTarget.value) {
    eventService.getCurrentGoal(eventId.value).then(res => {
      targetData.value.vector = res.vectorNumber + 1
      targetData.value.stage = res.phaseNumber + 1
      targetData.value.vectorId = res.vectorId
      targetData.value.stageId = res.phaseId
      targetData.value.text = res.description
      targetData.value.hintAvailable = res.hintAvailable
    })
  }
}
const getVmIds = () => {
  if (isAdmin.value) {
    eventService.getUserToVmMapping(eventId.value).then(res => {
      vmIds.value = res
    })
  }
}
const getTooltip = async () => {
  if (isShowTarget.value) {
    const res = await eventService.getHint(
      eventId.value,
      targetData.value.vectorId,
      targetData.value.stageId
    )
    tooltipData.value.text = res.hint
    tooltipData.value.vector = res.vectorNumber + 1
    tooltipData.value.stage = res.phaseNumber + 1
    popups.value.isTooltipVisible = true
  }
}
const isOnline = (user) => {
  return user.online === 'ONLINE' || user.online === 'CONNECTED'
}
const setHoveredUser = (user) => {
  hoveredUserId.value = user.id
}
const unhoverUser = () => {
  hoveredUserId.value = 0
}
const selectUser = (user) => {
  selectedParticipant.value = user
  nextTick(() => vueCurrentInstance.$refs.userConsole.$el.focus())
}
const closeReassignDialog = () => {
  popups.value.isVmReassignVisible = false
}
const selectTabMenuItem = (itemId) => {
  if (selectedTabMenuItem.value === itemId) {
    selectedTabMenuItem.value = null
  } else {
    selectedTabMenuItem.value = itemId
  }
  isFullscreen.value = false
}
const closeTabMenuItem = () => {
  selectedTabMenuItem.value = null
  // hideRightMenu()
}
const displayRightMenu = () => {
  rightMenuHidden.value = false
}
const hideRightMenu = () => {
  if (isUser.value && !selectedTabMenuItem.value) {
    nextTick(() => {
      rightMenuHidden.value = true
    })
  }
}
const checkRedirect = (status) => {
  if (
    status !== events.PREPARATION_PAUSE.key &&
    status !== events.PAUSED.key &&
    status !== events.RUNNING.key &&
    status !== events.FINISHING.key &&
    !isSystemAdmin.value
  ) {
    redirectToCard()
  }
}
const redirectToCard = () => {
  router.push({ name: 'EventCard', params: { eventId: route.params.eventId } })
}
const redirectToList = () => {
  router.push({ name: 'Home' })
}
const onKeydownEsc = () => {
  if (!isUser.value && selectedParticipant.value && selectedParticipant.value.id) {
    selectedParticipant.value = null
    nextTick(() => vueCurrentInstance.$refs.runEventCard.$el.focus())
  } else {
    closeTabMenuItem()
  }
}
const comparedString = (user) => {
  return (isOnline(user) ? 'A' : 'Z') + user.firstName + user.middleName + user.lastName
}

onBeforeUnmount(() => {
  if (isUser.value) {
    mainElement.value.style.paddingTop = '64px'
  }
})

const getGameState = () => {
  eventService
    .getGameState(eventId.value)
    .then(data => {
      return store.dispatch('base', data)
    })
    .then(() => {
      constructor.Update()
    })
}

if (isCtfOrResistance.value && isObserver.value) {
  router.push('/appError')
} else {
  eventId.value = Number(route.params.eventId)
  const timeout = Math.round(Math.random() * 1000 + 200)
  debounceGetGameState.value = _.debounce(getGameState, timeout)
  initEventsSubscribesMap()
  if (isUser.value) {
    mainElement.value = document.querySelector('.v-main')
    mainElement.value.style.padding = 0
  }
}
</script>

<style lang="scss" scoped>
.current-target-container.hidden {
  right: -52px;
  transition: all 0.3s;
}
.current-target-container {
  transition: all 0.3s;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: 52px;
  right: 0;
  top: 144px;
  background: linear-gradient(90deg, rgba($base-black, 0.2) 0%, rgba($base-black, 0) 50%), $PopupDarkBack;
  opacity: 0.8;

  .menu-container {
    width: 52px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    .menu-button {
      width: 52px;
      height: 52px;
      justify-content: center;
      align-items: center;

      .triangle {
        position: absolute;
        left: 2px;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-right: 5px solid $StaticLight;
        border-bottom: 6px solid transparent;
      }
      .v-icon {
        position: absolute;
        left: 8px;
        color: $ActionColor;
        ::v-deep path {
          fill: $ActionColor;
        }
      }
    }
    .menu-button.selected-menu-button {
      background: linear-gradient(90deg, $ActionColor 0%, rgba($ActionColor, 0.00) 100%);
      .v-icon {
        color: $StaticLight;
        ::v-deep path {
          fill: $StaticLight;
        }
      }
    }
    .selected-menu-button-rect {
      width: 2px;
      height: 52px;
      flex-shrink: 0;
      background-color: rgba($StaticLight, 0.70);
      filter: drop-shadow(0px 0px 3px $ActionColor);
      position: absolute;
      right: 0;
    }
  }
  .data-container.fullscreen {
    width: calc(100vw - 52px);
  }
  .data-container {
    position: absolute;
    top: 0px;
    right: 52px;
    display: flex;
    width: 472px;
    height: 80vh;
    max-height: 700px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-shrink: 0;
    &-fr {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: flex-start;
      flex-shrink: 0;
      align-self: stretch;
    }
    &-title {
      display: flex;
      height: 36px;
      align-items: flex-start;
      align-self: stretch;
      background: rgba($StaticLight, 0.15);
      @include cut-corners(5px, true, 5px, false);
      &-hide {
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
      }
      &-hide:not(.fullscreen) {
        ::v-deep svg {
          width: 8px;
          height: 14px;
        }
        ::v-deep path {
          fill: transparent;
          stroke: $ActionColor;
          stroke-width: 2px;
        }
      }
      &-hide.fullscreen {
        ::v-deep svg {
          width: 18px;
          height: 18px;
        }
        ::v-deep path {
          fill: $ActionColor;
        }
      }
      &-vr {
        width: 1.5px;
        height: 36px;
        background-color: rgba($StaticLight, 0.1);
      }
      &-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        align-self: stretch;

        color: rgba($StaticLight, 0.80);
        text-align: center;

        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    &-content {
      @include scrollbar-medium;
      height: calc(100% - 36px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      flex: 1 0 0;
      align-self: stretch;
      background: rgba($PopupDarkBack, 0.25);
      backdrop-filter: blur(12.5px);
      &-team-fr {
        display: flex;
        padding-right: 0px;
        align-items: flex-start;
        gap: 6px;
        flex: 1 0 0;
        align-self: stretch;
        background: $PopupDarkBack;
      }
      &-team-card-list {
        display: flex;
        padding: 10px 14px;
        flex-direction: column;
        align-items: flex-start;
        gap: 11px;
        flex: 1 0 0;
        align-self: stretch;
      }
      &-team-title {
        display: flex;
        align-items: flex-start;
        align-self: stretch;
        &-fr {
          height: 21px;
          display: flex;
          padding: 2px 10px;
          align-items: flex-start;
          gap: 10px;
          flex: 1 0 0;
          align-self: stretch;
          background: linear-gradient(270deg, rgba(0, 255, 240, 0.80) 63.11%, rgba(0, 255, 240, 0.00) 100%);
          span {
            flex: 1 0 0;
            color: var(--StaticDark, #003956);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
          }
        }
      }
      &-team-card {
        display: flex;
        height: 64px;
        padding: 10px 14px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        background: linear-gradient(0deg, rgba(0, 255, 240, 0.10) 0%, rgba(0, 255, 240, 0.10) 100%), rgba(0, 0, 0, 0.10);
        &-fr {
          display: flex;
          align-items: center;
          gap: 10px;
          align-self: stretch;
        }
        &-avatar {
          width: 64px;
          height: 64px;
        }
        &-title {
          max-width: 296px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 10px;
          flex: 1 0 0;
          &-name {
            max-width: 100%;
            color: $ActionColor;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &-value.offline {
              opacity: .35;
            }
          }
          &-position {
            max-width: 100%;
            color: $StaticLight;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            &-value.offline {
              opacity: .35;
            }
          }
        }
        &-view {
          display: flex;
          width: 36px;
          height: 36px;
          justify-content: center;
          align-items: center;
        }
        &-view.offline {
          opacity: 0.35;
        }
      }
      &-goal {
        &-title {
          display: flex;
          padding: 0px 12px;
          align-items: flex-start;
          align-self: stretch;
          &-fr {
            display: flex;
            height: 24px;
            padding: 2px 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            background: linear-gradient(270deg, rgba(0, 255, 240, 0.80) 63.11%, rgba(0, 255, 240, 0.00) 100%);
            &-header {
              display: flex;
              height: 5px;
              justify-content: center;
              align-items: center;
              gap: 12px;
              flex: 1 0 0;
              &-vector {
                flex: 1 0 0;
                color: var(--StaticDark, #003956);
                text-align: right;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
              }
              &-stage {
                flex: 1 0 0;
                color: var(--StaticDark, #003956);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: uppercase;
              }
            }
          }
        }
        &-card {
          display: flex;
          padding: 0px 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 17px;
          align-self: stretch;
          &-fr {
            @include cut-corners(6px, true, 6px, false, 2px, rgba($StaticLight, 0.25));
            background: $PopupDarkBack;
            display: flex;
            height: 70vh;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            &-body {
              height: 100%;
              display: flex;
              align-items: flex-start;
              flex: 1 0 0;
              align-self: stretch;
              &-fr {
                height: 100%;
                display: flex;
                padding: 10px 0px 10px 14px;
                flex-direction: column;
                align-items: flex-end;
                gap: 12px;
                flex: 1 0 0;
                align-self: stretch;
                &-text {
                  max-height: 100%;
                  overflow-y: auto;
                  @include scrollbar();
                  display: flex;
                  justify-content: flex-end;
                  align-items: flex-start;
                  gap: 10px;
                  flex: 1 0 0;
                  align-self: stretch;
                  span {
                    flex: 1 0 0;
                    align-self: stretch;
                    color: rgba(255, 255, 255, 0.70);
                    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.20);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                  }
                }
                &-line {
                  width: 414px;
                  height: 1px;
                  opacity: 0.28;
                  background: linear-gradient(270deg, #00F0FF 0%, rgba(0, 240, 255, 0.00) 100%);
                }
                &-hint {
                  width: 137.794px;
                  color: $ActionColor;
                  text-align: right;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  text-decoration-line: underline;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      &-log {
        &-card {
          display: flex;
          padding: 0px 12px;
          flex-direction: column;
          align-items: flex-start;
          gap: 17px;
          align-self: stretch;
          &-fr {
            @include cut-corners(6px, true, 6px, false, 2px, rgba($StaticLight, 0.25));
            background: $PopupDarkBack;
            display: flex;
            height: 70vh;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
          }
        }
      }
    }
  }
}

.run-event-background {
  height: 100%;
  background-size: cover;
  cursor: default;
}

.user-console-start-container {
  position: absolute;
}

.right-menu {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0px;
  width: 7px;
  height: 100%;

  &-chevron {
    position: absolute;
    width: 52px;
    height: 50px;
    right: -26px;
    top: 361px;

    border: 1px solid rgba($base-color, 0.25);
    border-radius: 4px;
    background: rgba($PopupDarkBack, 0.8) !important;
    z-index: 9;
    opacity: 0.8;

    &-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba($base-color, 0.15) 0%, rgba($base-color, 0) 100%);
      border-radius: 4px;
    }
    &-borders {
      position: absolute;
      width: 56px;
      height: 65px;
      left: -2px;
      bottom: -1px;
    }

    &-icon {
      position: absolute !important;
      top: 21px;
      left: 5px;
      width: 17px;
      height: 6px;
      transform: rotate(-90deg);

      ::v-deep path {
        stroke: $ActionColor;
      }
    }
    transition: all 0.3s;
  }
  &-chevron.expanded {
    right: 0;
    border: none;
    opacity: 1;
    background: linear-gradient(360deg, rgba($base-color, 0.15) 0%, rgba($base-color, 0) 100%) !important;
    .right-menu-chevron-icon {
      left: 18px;
      transform: rotate(90deg);
    }
    transition: all 0.3s;
  }
}

.user-style-right-menu {
  height: 266px;
  border-radius: 0 0 4px 4px;
}
.user-show-console-btn {
  background-color: $WarningColor !important;
}
.user-hide-console-btn {
  opacity: .8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
}
.dialog-finish-event {
  &_name {
    text-align: center;
    font-size: 18px;
    color: $StaticLight;
    width: -webkit-fill-available;
  }
  &_text {
    font-size: 18px;
    color: rgba($StaticLight, 0.6);
  }
  &_hdr {
    @include text-ellipsis;
    max-width: 520px;
    color: rgba($ActionColor, .8);
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
  }
}
</style>
