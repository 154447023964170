<template>
  <div class="cbr-textfield" :class="{ small, medium, large }">
    <div v-if="title" class="cbr-textfield-title" :class="{ 'error-state': !isValid }">
      <CbrHint
        v-if="infoHint"
        :title="infoHint.title"
        :sub-title="infoHint.subTitle"
        :description="infoHint.description"
        info
        bottom
        content-class="move-right"
      >
        <CbrIcon class="cbr-textfield-title__icon"> $information </CbrIcon>
      </CbrHint>
      <span class="cbr-textfield-title__text">{{ title }}
        <span class="cbr-textfield-title__required" v-if="required">*</span>
      </span>
      <slot name="titleRight"/>
    </div>
    <v-text-field
      ref="textFieldTemplateRef"
      class="cbr-textfield-container"
      :id="id"
      :type="getType"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :hide-details="hideDetails"
      :rules="rules"
      :error-messages="errorMessages"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      :counter="counter"
      :class="inputClasses"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keydown="$emit('keydown', $event)"
    >
      <template v-slot:message="{ message }">
        <div class="cbr-textfield-error-block">{{ message }}</div>
      </template>
      <template v-slot:prepend-inner>
        <CbrIcon v-if="search" class="search-icon"> mdi-magnify </CbrIcon>
      </template>
      <template v-slot:append>
        <CbrIcon
          v-if="clearable && value && !disabled"
          class="clearable-icon"
          @click="$emit('input', '')"
        >
          mdi-close
        </CbrIcon>
        <CbrIcon v-if="reset && !disabled"
          class="reset-icon"
          @click="resetValue"
        >
          $inputReset
        </CbrIcon>
        <CbrIcon
          v-if="password && !disabled"
          class="pass-icon"
          @click="onPasswordIconClick"
        >
          {{ passIcon }}
        </CbrIcon>
        <CbrHint
            v-if="!isValid && errorHint"
            :title="errorHint.title"
            :sub-title="errorHint.subTitle"
            :description="errorHint.description"
            error
            bottom
            content-class="move-left"
        >
          <CbrIcon class="error-icon"> mdi-alert-circle </CbrIcon>
        </CbrHint>
        <CbrHint
          v-if="!isValid && !errorHint && hideDetails"
          :description="errorMessages.join(', ')"
          no-title
          error
          bottom
          content-class="move-left"
        >
          <CbrIcon class="error-icon"> mdi-alert-circle</CbrIcon>
        </CbrHint>
        <CbrIcon v-if="!isValid && !errorHint && !hideDetails" class="error-icon"> mdi-alert-circle </CbrIcon>
      </template>
    </v-text-field>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const props = defineProps({
  id: String,
  value: [String, Number],
  placeholder: String,
  disabled: Boolean,
  hideDetails: Boolean,
  rules: Array,
  errorMessages: {
    type: Array,
    default: () => [],
  },
  counter: [Boolean, Number],
  maxlength: [Number, String],
  title: String,
  search: Boolean,
  password: Boolean,
  required: Boolean,
  small: Boolean,
  large: Boolean,
  clearable: {
    type: Boolean,
    default: () => true
  },
  error: Boolean,
  initialValue: String,
  reset: Boolean,
  infoHint: Object,
  errorHint: Object,
  type: {
    type: String,
    default: null
  },
  min: Number,
  max: Number
})
const emit = defineEmits(['input'])

const isPasswordHidden = ref(true)
const init = ref('')

const medium = computed(() => !props.small && !props.large)
const passIcon = computed(() => isPasswordHidden.value ? 'visibility_off' : 'visibility')
const getType = computed(() => {
  if (props.type) {
    return props.type
  }
  return props.password && isPasswordHidden.value ? 'password' : 'text'
})
const isValid = computed(() => !props.error && !props.errorMessages?.length && !props.errorHint)
const inputClasses = computed(() => {
  return {
    'failed-input': !isValid.value,
    'valid-input': isValid.value,
    required: props.required,
    disabled: props.disabled,
    search: props.search
  }
})

const textFieldTemplateRef = ref()
function onPasswordIconClick () {
  isPasswordHidden.value = !isPasswordHidden.value

  setTimeout(() => setCursorPositionToTheEnd())
}
function setCursorPositionToTheEnd () {
  const input = textFieldTemplateRef.value.$el.querySelector('input')
  const length = input.value.length

  if (input.setSelectionRange) {
    input.focus()
    input.setSelectionRange(length, length)
  } else if (input.createTextRange) {
    let t = input.createTextRange()
    t.collapse(true)
    t.moveEnd('character', length)
    t.moveStart('character', length)
    t.select()
  }
}

const resetValue = () => emit('input', init.value)

onMounted(() => {
  if (props.initialValue) {
    init.value = props.initialValue
    resetValue()
  }
})
</script>

<style lang="scss" scoped>
$borderWidth: 2px;
$cornerMargin: 3px; // отступ от уголка обязательности до границы поля

$cornerSizeSmall: 5px;
$cornerSizeMedium: 5px;
$cornerSizeLarge: 8px;

$fontSizeSmall: 16px;
$fontSizeMedium: 18px;
$fontSizeLarge: 20px;

$fieldHeightSmall: 24px;
$fieldHeightMedium: 36px;
$fieldHeightLarge: 48px;

$backgroundColor: rgba($base-color-dark, 0.35);

@mixin set-state-styles(
  $cornerSize,
  $borderColor,
  $backgroundColor: $backgroundColor,
  $required: false,
  $triangleColor: $base-color
) {
  $clipCornerSize: calc(#{$cornerSize} + #{$cornerMargin});
  $cornerBorderWidth: calc(#{$clipCornerSize} + #{$borderWidth} + #{$borderWidth});

  ::v-deep .v-input__slot {
    background-color: $backgroundColor;
    @include cut-corners($clipCornerSize, true, $clipCornerSize, false, $borderWidth, $borderColor);
    display: flex;
    padding: 0;
    margin-bottom: 0;
    &::before {
      border-width: $cornerBorderWidth $cornerBorderWidth 0 0;
      border-color: $borderColor transparent transparent transparent !important;
      border-image: none;
      transition: none;
    }
    &::after {
      border-width: 0 $cornerBorderWidth $cornerBorderWidth 0;
      border-color: transparent $borderColor transparent transparent !important;
      left: auto;
      transition: none;
    }
  }

  ::v-deep .v-input__control {
    position: relative;
    @if ($required) {
      &::before {
        content: '';
        position: absolute;
        border-top: $cornerSize solid $triangleColor;
        border-right: $cornerSize solid transparent;
        width: 0;
        height: 0;
      }
    }
  }
}

@mixin set-size-options ($cornerSize, $fontSize, $fieldHeight){
  $clipCornerSize: calc(#{$cornerSize} + #{$cornerMargin});
  $cornerBorderWidth: calc(#{$clipCornerSize} + #{$borderWidth} + #{$borderWidth});

  ::v-deep .v-input__slot {
    font-size: $fontSize;
    height: $fieldHeight;
  }

  // valid state classes
  .cbr-textfield-container.valid-input:not(.required):not(.error--text):not(.disabled) {
    &:not(:focus-within) {
      @include set-state-styles($cornerSize, $border-muted);
    }
    &:focus-within {
      @include set-state-styles($cornerSize, $base-color);
    }
  }
  .cbr-textfield-container.valid-input.required:not(.error--text):not(.disabled) {
    &:not(:focus-within) {
      @include set-state-styles($cornerSize, $border-muted, $backgroundColor, true, $base-color);
    }
    &:focus-within {
      @include set-state-styles($cornerSize, $base-color, $backgroundColor, true, $base-color);
    }
  }
  // disabled state class
  .cbr-textfield-container.disabled {
    &.required {
      @include set-state-styles($cornerSize, $border-disabled, $backgroundColor, true, $border-disabled);
    }
    &:not(.required) {
      @include set-state-styles($cornerSize, $border-disabled);
    }
  }
  // error state classes
  .cbr-textfield-container.failed-input:not(.required), .cbr-textfield-container.error--text {
    @include set-state-styles($cornerSize, $base-color-error-text);
    ::v-deep .v-counter {
      color: $base-color-error-text !important;
    }
  }
  .cbr-textfield-container.failed-input.required, .cbr-textfield-container.error--text.required {
    @include set-state-styles($cornerSize, $base-color-error-text, $backgroundColor, true, $base-color-error-text);
    ::v-deep .v-counter {
      color: $base-color-error-text !important;
    }
  }
  // search field classes
  .cbr-textfield-container.search {
    &:focus-within {
      ::v-deep .search-icon {
        display: none;
      }
    }
  }
  .cbr-textfield-container.search.v-input--is-dirty {
    ::v-deep .search-icon {
      display: none;
    }
  }
}

.cbr-textfield {
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  ::v-deep input[type=number] {
    -moz-appearance: textfield;
  }

  &-title {
    display: flex;
    align-items: center;
    color: $base-color;
    margin-bottom: 4px;

    &__icon {
      margin-right: 0.2em;
      min-width: fit-content;
      ::v-deep path {
        fill: $base-color;
        opacity: 0.5;
      }
      &:hover ::v-deep path {
        opacity: 1;
      }
    }

    &__text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      padding-right: 9px;
      width: 100%;
      line-height: 1em;
    }
    &__required {
      color: $base-color-error-text;
    }

    &.error-state {
      color: $base-color-error-text;
      .cbr-textfield-title__icon {
        ::v-deep path {
          fill: $base-color-error-text;
        }
      }
    }
  }
  &-container {
    padding-top: 0;
    margin-top: 0;

    ::v-deep .v-input__slot .v-text-field__slot {
      align-items: center;
      input {
        padding: 0 0 0 12px;
        color: $base-color-text;
        caret-color: $base-color-text;
        &::placeholder {
          font-family: $primary-font-family;
          color: rgba($base-color-light, 0.3);
        }
      }
    }

    ::v-deep .v-input__append-inner {
      margin: 0;
      padding: 0 0.5em;
      align-self: center;
    }

    ::v-deep .v-input__prepend-inner {
      margin: 0;
      padding: 0 !important;
      align-self: center;
    }

    ::v-deep .v-text-field__details {
      margin-top: 5px;
      padding: 0 16px 0 13px;
      .v-counter {
        color: rgba($base-color-light, 0.5) !important;
      }
    }
  }

  &-error-block {
    color: $AlarmTextColor;
  }

  &.small {
    @include set-size-options($cornerSizeSmall, $fontSizeSmall, $fieldHeightSmall);
    ::v-deep .v-icon {
      font-size: $fontSizeSmall;
      height: $fontSizeSmall !important;
    }
    .cbr-textfield-title {
      font-size: $fontSizeSmall;
    }
  }
  &.medium {
    @include set-size-options($cornerSizeMedium, $fontSizeMedium, $fieldHeightMedium);
    ::v-deep .v-icon {
      font-size: $fontSizeMedium;
      height: $fontSizeMedium !important;
    }
    .cbr-textfield-title {
      font-size: $fontSizeMedium;
    }
  }
  &.large {
    @include set-size-options($cornerSizeLarge, $fontSizeLarge, $fieldHeightLarge);
    ::v-deep .v-icon {
      font-size: $fontSizeLarge;
      height: $fontSizeLarge !important;
    }
    .cbr-textfield-title {
      font-size: $fontSizeLarge;
    }
  }
}

.search-icon {
  padding-left: 12px;
  color: $base-color-muted !important;
  font-size: 1.3em !important;
}
.clearable-icon {
  color: $base-color-muted !important;
  font-size: 1.4em !important;
}
.error-icon {
  color: $base-color-error-text !important;
  font-size: 1.2em !important;
  &:hover {
    cursor: auto;
  }
}
.reset-icon {
  color: rgba($base-color-warning, 0.6) !important;
  font-size: 1.2em !important;
  &:hover {
    cursor: pointer;
  }
}
.pass-icon {
  color: $base-color-muted !important;
  font-size: 1.2em !important;
  &:hover {
    cursor: auto;
  }
}
.failed-input, .error--text {
  .clearable-icon, .pass-icon {
    color: $base-color-error-text !important;
  }
}
</style>
