export const momentLocale = {
  monthsShort: [
    'jan.', 'feb.', 'mar.', 'apr.', 'may', 'june', 'july', 'aug.', 'sept.', 'oct.', 'nov.', 'dec.'
  ]
}

export const commonEn = {
  // org type
  common_org_type_internal: 'Operator',
  common_org_type_external: '',
  // event type name
  common_event_type_defence: 'Defence',
  common_event_type_ctf: 'Capture-the-Flag',
  common_event_type_rvb: 'Red vs Blue',
  // event type description
  common_event_type_desc_defence: 'Practical and control lessons on detecting, investigating and protecting against cyber attacks. The system automatically attacks the infrastructure according to the event scenario.',
  common_event_type_desc_ctf: 'A competition during which teams must penetrate the opponents infrastructure and place a flag while protecting their own infrastructure from penetration.',
  common_event_type_desc_rvb: 'Teams must protect their infrastructure and attack the infrastructure of opponents to cause a denial of service for critical services.',
  // switch on/off
  common_switch_on: 'On',
  common_switch_off: 'Off',
  // error hint title
  common_error_title: 'Error!'
}
